import Api from './const';
import fetch from './commonFetch'


const { getAll, get, add, update, remove, activate, deactivate } = Api.testimonials;

export const getTestimonials = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'testimonials');
}

export const getTestimonial = id => {
    const { url, method, headers } = get;

    return fetch(url(id), { method, headers }, "testimonial")
}

export const addTestimonial = testimonial => {
    const { url, method, headers } = add;
    const body = JSON.stringify(testimonial);

    return fetch(url(), { method, headers, body }, "testimonial")
}

export const updateTestimonial = (docId, testimonial) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(testimonial);

    return fetch(url(docId), { method, headers, body }, "testimonial")
}

export const deleteTestimonial = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'testimonial')
}

export const activateTestimonial = id => {
    const { url, method, headers } = activate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "testimonial")
}

export const deactivateTestimonial = id => {
    const { url, method, headers } = deactivate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "testimonial")
}
