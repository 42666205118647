import * as fromApi from '../api/banks';

export const FETCH_BANKS = 'FETCH_BANKS';

export const fetchBanks = () => dispatch => {
    return fromApi.fetchBanks().then(response => {
      dispatch({ type: FETCH_BANKS, banks: response.banks || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}