import { combineReducers } from 'redux';
import { FETCH_COURSE_LEVELS, FETCH_COURSE_LEVEL, DELETE_COURSE_LEVEL } from "../actions/courseLevels";

export const byId = (state = {}, action) => {
  let newState = {};

  switch (action.type) {
    case FETCH_COURSE_LEVELS:
      action.courseLevels.forEach(courseLevel => newState[courseLevel._id] = courseLevel);
      return newState;
    case FETCH_COURSE_LEVEL:
      const { courseLevel } = action;
      return { ...state, [courseLevel._id]: courseLevel };
    case DELETE_COURSE_LEVEL:
      delete state[action.courseLevel._id]
      return state
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_COURSE_LEVELS:
      return action.courseLevels.map(courseLevel => courseLevel._id);
    case FETCH_COURSE_LEVEL:
      if (state.includes(action.courseLevel._id)) {
        return state
      } else{
        return [ ...state, action.courseLevel._id ];
      }
    case DELETE_COURSE_LEVEL:
      return state.filter(stateCourseLevel => stateCourseLevel !== action.courseLevel._id);
    default:
      return state;
  }
}

const courseLevels = combineReducers({
  byId,
  ids,
});

export const getCourseLevels = state => {
  const courseLevels = state.ids.map(id => state.byId[id]);
  const sortedCourseLevels = courseLevels.sort(function(a, b){
    if(a.levelname < b.levelname) { return -1; }
    if(a.levelname > b.levelname) { return 1; }
    return 0;
  })
  return sortedCourseLevels || [];
};

export const getCourseLevelByDocId = (state, docId) => {
  const courseLevel = state.byId[docId];
  return courseLevel || {};
}

export default courseLevels;