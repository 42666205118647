import { combineReducers } from 'redux';
import { ADD_WAVE_OFF, FETCH_WAVE_OFF_DATA, FETCH_WAVE_OFF_DATA_BY_CUSTOMER, FETCH_WAVE_OFF_DATA_LIST } from '../actions/waveoff';

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_WAVE_OFF_DATA_LIST:
	      const newState = {};
	      action.waveOffDataList.forEach(waveOffData => newState[waveOffData._id] = waveOffData);
	      return newState;
	  case FETCH_WAVE_OFF_DATA: 
		  const { waveOffList } = action;
		  const newWaveOffData = {};
	      waveOffList.forEach(waveOffData => newWaveOffData[waveOffData._id] = waveOffData);
	      return {...state, ...newWaveOffData};
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_WAVE_OFF_DATA_LIST:
		return action.waveOffDataList.map(waveOffData => waveOffData._id);
	  case FETCH_WAVE_OFF_DATA:
		const { waveOffList } = action;
		let newIds = [];
		newIds = waveOffList.map(waveOffData => waveOffData._id);
		return {...state, ...newIds};
	  default:
		return state;

    }
}

export const byCustomerId = (state = [], action) => {
    switch (action.type) {
	  case FETCH_WAVE_OFF_DATA_BY_CUSTOMER:
	      const newState = {};
		action.waveOffList.forEach(waveOffData => {
		    let array = newState[waveOffData.customerid] || []
		    newState[waveOffData.customerid] = [ ...array, waveOffData ];
		});
	      return newState;
	  default:
		return state;
    }
}


const waveoffList = combineReducers({
    byId,
    ids,
    byCustomerId
});

export default waveoffList;

export const getWaveOffList = state => state && state.ids.length > 0 && state.ids.map(id => state.byId[id]);

export const getWaveOffListByIds = (state, ids = []) => ids.map(id => state.byId[id]).filter(waveoff => !!waveoff);
export const getWaveOffListByCustomerId = (state, customerId) => state.byCustomerId[customerId];
