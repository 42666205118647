import Api from './const';
import fetch from './commonFetch'

const {add, getAll, advanceSearch, getWaveOffById, getWaveOffsByCustomerId} = Api.waveoff;

export const getWaveOffInvoices = query => {
    const {url, method, headers} = getAll;
    return fetch(url(), {method, headers}, 'waveOffList');
}

export const waveOffAdvanceSearch = query => {
    const {url, method, headers} = advanceSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'waveOffList')
}

export const addWaveOff = waveOffInvoiceData => {
    const {url, method, headers} = add;
    const body = JSON.stringify(waveOffInvoiceData)

    return fetch(url(), {method, headers, body}, 'waveOffInvoiceResponse');
}

export const getWaveOffReceiptsById = waveOffId => {
    const {url, method, headers} = getWaveOffById;
    return fetch(url(waveOffId), {method, headers}, 'waveOffDetails');
}

export const getWaveOffReceiptsByCustomerId = customerId => {
    const {url, method, headers} = getWaveOffsByCustomerId;
    return fetch(url(customerId), {method, headers}, 'waveOffDetails')
}



