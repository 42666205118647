import Api from './const';
import fetch from './commonFetch'

const { getAll, add, update, remove } = Api.courseLevels;

export const getCourseLevels = () => {
  const { url, method, headers } = getAll;
  return fetch(url(), { method, headers }, "courseLevels")
}

export const addCourseLevel = courseLevel => {
  const { url, method, headers } = add;
  const body = JSON.stringify(courseLevel);

  return fetch(url(), { method, headers, body }, "courseLevel")
}

export const updateCourseLevel = (docId, courseLevel) => {
  const { url, method, headers } = update;
  const body = JSON.stringify(courseLevel);

  return fetch(url(docId), { method, headers, body }, "courseLevel")
}

export const deleteCourseLevel = (docId, deleteRequest) => {
  const { url, method, headers } = remove;
  const body = JSON.stringify(deleteRequest);
  return fetch(url(docId), {method, headers, body}, 'courseLevel')
}