import * as fromApi from '../api/users';
import {getAuthenticity} from "../reducers";

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const fetchUsers = () => dispatch => {
    return fromApi.getUsers().then(response => {
        const { error } = response
        if (error) return error;

	  dispatch({type: FETCH_USERS, users: response.users || []});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const fetchUser = userId => dispatch => {
    return fromApi.getUser(userId).then(response => {
	  dispatch({type: FETCH_USER, user: response.user});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const addUser = user => dispatch => {
    return fromApi.addUser(user).then(user => {
	  dispatch({type: ADD_USER, user});
	  return user;
    }).catch(error => {
	  return {error};
    });
}

export const updateUser = user => dispatch => {
    return fromApi.updateUser(user).then(user => {
        dispatch({type: UPDATE_USER, user});;
        return user;
    }).catch(error => {
        return {error}
    })
}

export const changePassword = passwordChangeRequest => (_,getState) => {
    const userId = getAuthenticity(getState()).userId;
    return fromApi.changePassword(userId, passwordChangeRequest).then(response => {
	  return response;
    }).catch(error => {
	  return { error };
    });
}