import Api from './const';
import fetch from './commonFetch'


const { getAll, add, update, delete: deleteAccount } = Api.bankAccounts;

export const fetchBankAccounts = () => {
    console.log("API CALLING")
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'bankAccounts');
}

export const addBankAccount = bankAccount => {
    const { url, method, headers } = add;
    const body = JSON.stringify(bankAccount);
    return fetch(url(), {method, headers, body}, 'bankAccount')
}

export const updateBankAccount = (docId, request) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(request);

    return fetch(url(docId), { method, headers, body }, "bankAccount")
}

export const deleteBankAccount = (docId, request) => {
    const { url, method, headers } = deleteAccount;
    const body = JSON.stringify(request);

    return fetch(url(docId), { method, headers, body }, "bankAccount")
}