export default class Api {}

const { hostname } = window.location;
const host = hostname === 'localhost' ? 'localhost:9000' : window.location.host;
Api.server = window.location.protocol + '//' + host + '/api';

Api.endpointStudents = Api.server + '/students';
Api.endpointBranchCourseDetails = Api.server + '/branchcoursedetails';
Api.endpointBatches = Api.server + '/batches';
Api.endpointInvoices = Api.server + '/invoices';
Api.endpointUsers = Api.server + '/users';
Api.endpointBranches = Api.server + '/branches';
Api.endpointCompanies = Api.server + '/companies';
Api.endpointReceipts = Api.server + '/receipts';
Api.endpointConfigPaymentAmounts = Api.server + '/configPaymentAmounts';
Api.endpointWaveOff = Api.server + '/waveoff';
Api.endpointDashboard = Api.server + '/dashboard';
Api.endpointAuth = Api.server + '/auth';
Api.endpointActions = Api.server + '/actions';
Api.endpointRoles = Api.server + '/roles';
Api.endpointPayableItems = Api.server + '/payableItems';
Api.endpointBankDeposit = Api.server + '/bankDeposits';
Api.endpointBankAccounts = Api.server + '/bankAccounts';
Api.endpointBanks = Api.server + '/banks';
Api.endpointMaterialOrder = Api.server + '/materialOrder';
Api.endpointBonusItems = Api.server + '/bonusItems';
Api.endpointCourseLevels = Api.server + '/courseLevels';
Api.endpointFaculties = Api.server + '/faculties';
Api.endpointDepartments = Api.server + '/departments';
Api.endpointCourses = Api.server + '/courses';
Api.endpointCourseTypes = Api.server + '/courseTypes';
Api.endpointPartners = Api.server + '/partners';
Api.endpointTestimonials = Api.server + '/testimonials';
Api.endpointAchievements = Api.server + '/achievements';
Api.endpointAnnouncement = Api.server + '/announcements';
Api.endpointInquiries = Api.server + '/inquiries';

const methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
}

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

Api.students = {
    getAll: {
        url: () => Api.endpointStudents + '/advancedSearch',
        method: methods.POST,
        headers: HEADERS
    },
    get: {
        url: (studentid) => Api.endpointStudents + '/byDocId/' + studentid,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointStudents,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (id) => Api.endpointStudents + '/' +id,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (id) => Api.endpointStudents + '/' +id,
        method: methods.DELETE,
        headers: HEADERS
    },
    assignBatch: {
        url: (studentid, batchid) => Api.endpointStudents + "/" + studentid + "/batches/" +batchid,
        method: methods.POST,
        headers: HEADERS
    },
    unassignBatch: {
        url: () => Api.endpointStudents + "/" + "removeBatch",
        method: methods.POST,
        headers: HEADERS
    },
    addStudentCard: {
        url: () => Api.endpointStudents + "/cards",
        method: methods.POST,
        headers: HEADERS
    },
    getCardsByStudentId:  {
        url: studentid => Api.endpointStudents + "/" + studentid + "/cards",
        method: methods.GET,
        headers: HEADERS
    },
    updateStudentCardStatus:  {
        url: (studentid, cardid) => Api.endpointStudents + "/" + studentid + "/cards/"+cardid,
        method: methods.PUT,
        headers: HEADERS
    },
    deleteStudentCard:  {
        url: (studentid, cardid) => Api.endpointStudents + "/" + studentid + "/cards/"+cardid,
        method: methods.DELETE,
        headers: HEADERS
    },
}

Api.branchCourseDetails = {
    getAll: {
        url: (page, pageSize) => Api.endpointBranchCourseDetails + '?page='+page+'&pageSize='+pageSize,
        method: methods.GET,
        headers: HEADERS
    },
    setFeaturedBranchCourse: {
        url: () => Api.endpointBranchCourseDetails + '/setFeaturedBranchCourse',
        method: methods.POST,
        headers: HEADERS
    },
}

Api.batches = {
    getAll: {
        url: (page, pageSize) => Api.endpointBatches + '?page='+page+'&pageSize='+pageSize,
        method: methods.GET,
        headers: HEADERS
    },
    advanceSearch: {
        url: () => Api.endpointBatches + '/advanceSearch',
        method: methods.POST,
        headers: HEADERS
    },
    get: {
        url: docid => Api.endpointBatches + '/' + docid,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointBatches,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: docid => Api.endpointBatches + '/' + docid,
        method: methods.PUT,
        headers: HEADERS
    },
    getDetailBatch: {
        url: docid => Api.endpointBatches + '/completeBatchDetails/' + docid,
        method: methods.GET,
        headers: HEADERS
    },
    deleteByIds: {
        url: () => Api.endpointBatches + '/deleteByIds',
        method: methods.POST,
        headers: HEADERS
    },
    activeBatchData: {
        url: () => Api.endpointBatches + '/activeBatchData',
        method: methods.GET,
        headers: HEADERS
    },
    updateBatchStatus: {
        url: () => Api.endpointBatches + '/updateStatus',
        method: methods.PUT,
        headers: HEADERS
    }
}

Api.invoices = {
    add: {
        url: () => Api.endpointInvoices,
        method: methods.POST,
        headers: HEADERS
    },
    getAll: {
        url: () => Api.endpointInvoices,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: invoiceId => Api.endpointInvoices + "/" +invoiceId,
        method: methods.GET,
        headers: HEADERS 
    },
    getInvoicesByCustomerId: {
        url: customerId => Api.endpointInvoices + '/byCustomer/' + customerId,
        method: methods.GET,
        headers: HEADERS
    },
    getInvoicesByCustomerDocumentId: {
        url: customerDocId => Api.endpointInvoices + '/byCustomerDocId/' + customerDocId,
        method: methods.GET,
        headers: HEADERS
    },
    advanceSearch: {
        url: () => Api.endpointInvoices + '/advanceSearch',
        method: methods.POST,
        headers: HEADERS
    },
    deleteByIds: {
        url: () => Api.endpointInvoices + '/deleteByIds',
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: invoiceId => Api.endpointInvoices + "/" + invoiceId,
        method: methods.PUT,
        headers: HEADERS
    },
    getInvoicesForCreateMaterialOrder: {
        url: branchId => Api.endpointInvoices + "/forCreateOrder/" + branchId ,
        method: methods.GET,
        headers: HEADERS
    },
    getInvoicesByReceiptId: {
        url: receiptId => Api.endpointInvoices + '/getInvoicesByReceiptId/' + receiptId,
        method: methods.GET,
        headers: HEADERS
    },
}

Api.users = {
    getAll: {
        url: (page, pageSize) => Api.endpointUsers + '?page='+page+'&pageSize='+pageSize,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (userId) => Api.endpointUsers + '/byDocId/' + userId,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointUsers,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (id) => Api.endpointUsers + '/' +id,
        method: methods.PUT,
        headers: HEADERS
    },
    updatePassword: {
        url: (id) => Api.endpointUsers + '/' + id + '/password',
        method: methods.PUT,
        headers: HEADERS
    },
    forgotPassword: {
        url: () => Api.endpointUsers + '/forgotPassword',
        method: methods.PUT,
        headers: HEADERS
    },
    cancelForgotPassword: {
        url: () => Api.endpointUsers + '/resetForgotPassword',
        method: methods.PUT,
        headers: HEADERS
    }
}

Api.branches = {
    getAll: {
        url: () => Api.endpointBranches,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointBranches,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointBranches + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointBranches + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.partners = {
    getAll: {
        url: () => Api.endpointPartners,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (id) => Api.endpointPartners + "/" + id,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointPartners,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointPartners + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointPartners + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    activate: {
        url: (docId) => Api.endpointPartners + '/' + docId + '/lifecycle/activate',
        method: methods.PATCH,
        headers: HEADERS
    },
    deactivate: {
        url: (docId) => Api.endpointPartners + '/' + docId + '/lifecycle/deactivate',
        method: methods.PATCH,
        headers: HEADERS
    }
}

Api.testimonials = {
    getAll: {
        url: () => Api.endpointTestimonials,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (id) => Api.endpointTestimonials + "/" + id,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointTestimonials,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointTestimonials + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointTestimonials + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    activate: {
        url: (docId) => Api.endpointTestimonials + '/' + docId + '/lifecycle/activate',
        method: methods.PATCH,
        headers: HEADERS
    },
    deactivate: {
        url: (docId) => Api.endpointTestimonials + '/' + docId + '/lifecycle/deactivate',
        method: methods.PATCH,
        headers: HEADERS
    }
}

Api.achievements = {
    getAll: {
        url: () => Api.endpointAchievements,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (id) => Api.endpointAchievements + "/" + id,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointAchievements,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointAchievements + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointAchievements + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    activate: {
        url: (docId) => Api.endpointAchievements + '/' + docId + '/lifecycle/activate',
        method: methods.PATCH,
        headers: HEADERS
    },
    deactivate: {
        url: (docId) => Api.endpointAchievements + '/' + docId + '/lifecycle/deactivate',
        method: methods.PATCH,
        headers: HEADERS
    }
}

Api.announcements = {
    getAll: {
        url: () => Api.endpointAnnouncement,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (id) => Api.endpointAnnouncement + "/" + id,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointAnnouncement,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointAnnouncement + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointAnnouncement + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    activate: {
        url: (docId) => Api.endpointAnnouncement + '/' + docId + '/lifecycle/activate',
        method: methods.PATCH,
        headers: HEADERS
    },
    deactivate: {
        url: (docId) => Api.endpointAnnouncement + '/' + docId + '/lifecycle/deactivate',
        method: methods.PATCH,
        headers: HEADERS
    }
}

Api.companies = {
    getAll: {
        url: () => Api.endpointCompanies,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointCompanies,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointCompanies + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointCompanies + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.receipts = {
    getAll: {
        url: () => Api.endpointReceipts,
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (userId) => Api.endpointReceipts + '/byDocId/' + userId,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointReceipts,
        method: methods.POST,
        headers: HEADERS
    },
    getReceiptsByCustomerId: {
        url: studentid => Api.endpointReceipts + '/byCustomer/' + studentid,
        method: methods.GET,
        headers: HEADERS
    },
    getPrintReceiptById: {
        url: receiptid => Api.endpointReceipts + '/printreceipt/' + receiptid,
        method: methods.GET,
        headers: HEADERS
    },
    advanceSearch: {
        url: () => Api.endpointReceipts + '/advanceSearch',
        method: methods.POST,
        headers: HEADERS
    },
    remove: {
        url: () => Api.endpointReceipts + '/cancel',
        method: methods.POST,
        headers: HEADERS
    },
    getReceiptsByBranchId: {
        url: branchId => Api.endpointReceipts + '/byBranch/' + branchId,
        method: methods.GET,
        headers: HEADERS
    },
    getReceiptsByIds: {
        url: () => Api.endpointReceipts + '/byIds',
        method: methods.POST,
        headers: HEADERS
    },
    notBankedReceiptsByBranchId: {
        url: branchId => Api.endpointReceipts + '/notBankedByBranchId/' + branchId,
        method: methods.GET,
        headers: HEADERS
    },
}

Api.waveoff = {
    getAll: {
        url: () => Api.endpointWaveOff,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointWaveOff,
        method: methods.POST,
        headers: HEADERS
    },
    advanceSearch: {
        url: () => Api.endpointWaveOff + '/advanceSearch',
        method: methods.POST,
        headers: HEADERS
    },
    getWaveOffById: {
        url: waveOffId => Api.endpointWaveOff + '/' + waveOffId,
        method: methods.GET,
        headers: HEADERS
    },
    getWaveOffsByCustomerId: {
        url: customerId => Api.endpointWaveOff + '/byCustomer/' + customerId,
        method: methods.GET,
        headers: HEADERS
    }
}

Api.configPaymentAmounts = {
    getAll: {
        url: branchid => Api.endpointConfigPaymentAmounts + '/' +branchid,
        method: methods.GET,
        headers: HEADERS
    }
}

Api.dashboard = {
    getTransactionSummery: {
        url: () => Api.endpointDashboard + '/transactionSummeryTotals',
        method: methods.POST,
        headers: HEADERS
    },
    getTransactionUserSummery: {
        url: () => Api.endpointDashboard + '/userSummery',
        method: methods.POST,
        headers: HEADERS
    },
}

Api.payableItems = {
    advancedSearch: {
        url: () => Api.endpointPayableItems + '/advancedSearch',
        method: methods.POST,
        headers: HEADERS
    },
    getAll: {
        url: () => Api.endpointPayableItems,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointPayableItems,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointPayableItems + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointPayableItems + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.checkInternetConnection = () => {
    if (!window.navigator.onLine) return 5500;
}

Api.auth = {
    authenticate: {
        url: () => Api.endpointAuth,
        method: methods.POST,
        headers: HEADERS
    },
    logout: {
        url: () => Api.endpointAuth + '/logout',
        method: methods.POST,
        headers: HEADERS
    }
}

Api.actions = {
    add: {
        url: () => Api.endpointActions,
        method: methods.POST,
        headers: HEADERS
    },
    getAll: {
        url: (page, pageSize) => Api.endpointActions + '?page='+page+'&pageSize='+pageSize,
        method: methods.GET,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointActions + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointActions + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.roles = {
    add: {
        url: () => Api.endpointRoles,
        method: methods.POST,
        headers: HEADERS
    },
    getAll: {
        url: (page, pageSize) => Api.endpointRoles + '?page='+page+'&pageSize='+pageSize,
        method: methods.GET,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointRoles + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointRoles + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    }
}

Api.bankDeposits = {
    advanceSearch: {
        url: () => Api.endpointBankDeposit + '/advancedSearch',
        method: methods.POST,
        headers: HEADERS
    },
    get: {
        url: (docId) => Api.endpointBankDeposit + '/' + docId,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointBankDeposit,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: depositId => Api.endpointBankDeposit + "/" +depositId,
        method: methods.PUT,
        headers: HEADERS
    },
    complete: {
        url: (docId) => Api.endpointBankDeposit + '/completeDeposit/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    reconcile: {
        url: (docId) => Api.endpointBankDeposit + '/reconcileDeposit/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointBankDeposit + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.bankAccounts = {
    getAll: {
        url: () => Api.endpointBankAccounts,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointBankAccounts,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: docId => Api.endpointBankAccounts + "/" + docId,
        method: methods.PUT,
        headers: HEADERS
    },
    delete: {
        url: docId => Api.endpointBankAccounts + "/" + docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.banks = {
    getAll: {
        url: () => Api.endpointBanks,
        method: methods.GET,
        headers: HEADERS
    }
}

Api.materialOrder = {
    advanceSearch: {
        url: () => Api.endpointMaterialOrder + '/advanceSearch',
        method: methods.POST,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointMaterialOrder + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    getByOrderId: {
        url: orderid => Api.endpointMaterialOrder + '/byOrderId/' + orderid,
        method: methods.GET,
        headers: HEADERS
    },
    getToBeOrderMaterialItemList: {
        url: () => Api.endpointMaterialOrder + '/getToBeOrderMaterialItemList',
        method: methods.POST,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointMaterialOrder,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointMaterialOrder+ '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    sendOrder: {
        url: (docId) => Api.endpointMaterialOrder+ '/sendOrder/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    deliverOrder: {
        url: (docId) => Api.endpointMaterialOrder+ '/deliverOrder/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    completeOrder: {
        url: (docId) => Api.endpointMaterialOrder+ '/completeOrder/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    materialAssignListSearch: {
        url: () => Api.endpointMaterialOrder + '/advanceSearchForMaterialAssignList',
        method: methods.POST,
        headers: HEADERS
    },
    materialAssignUpdateStatus: {
        url: () => Api.endpointMaterialOrder + '/materialAssign',
        method: methods.POST,
        headers: HEADERS
    },
    materialAssignConfirmStatus: {
        url: () => Api.endpointMaterialOrder + '/confirmMaterialAssignedItems',
        method: methods.POST,
        headers: HEADERS
    },
}

Api.bonusItems = {
    getByBranch: {
        url: branchId => Api.endpointBonusItems + '/byBranch/' +branchId,
        method: methods.GET,
        headers: HEADERS
    }
}
Api.courseLevels = {
    getAll: {
        url: () => Api.endpointCourseLevels,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointCourseLevels,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointCourseLevels + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointCourseLevels + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.faculties = {
    getAll: {
        url: () => Api.endpointFaculties,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointFaculties,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointFaculties + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointFaculties + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}

Api.departments = {
    getAll: {
        url: () => Api.endpointDepartments,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointDepartments,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointDepartments + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointDepartments + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    }
}


Api.courses = {
    getPublishCourses: {
        url: branchId => Api.endpointCourses + "/" + branchId + "/publishCourses",
        method: methods.GET,
        headers: HEADERS
    },
    getAll: {
        url: () => Api.endpointCourses + '/getAll',
        method: methods.GET,
        headers: HEADERS
    },
    get: {
        url: (docId) => Api.endpointCourses + docId,
        method: methods.GET,
        headers: HEADERS
    },
    add: {
        url: () => Api.endpointCourses,
        method: methods.POST,
        headers: HEADERS
    },
    update: {
        url: (docId) => Api.endpointCourses + '/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    remove: {
        url: (docId) => Api.endpointCourses + '/' +docId,
        method: methods.DELETE,
        headers: HEADERS
    },
    publishCourses: {
        url: () => Api.endpointCourses + "/publish",
        method: methods.POST,
        headers: HEADERS
    },
    unpublishCourses: {
        url: () => Api.endpointCourses + "/unpublish",
        method: methods.PATCH,
        headers: HEADERS
    },
    updateType: {
        url: (docId) => Api.endpointCourses + '/updateCourseType/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    unAssignItem: {
        url: (docId) => Api.endpointCourses + '/unAssignCourseItem/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    assignType: {
        url: (docId) => Api.endpointCourses + '/assignCourseItem/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
    setFeatured: {
        url: (docId) => Api.endpointCourses + '/setFeaturedCourse/' +docId,
        method: methods.PUT,
        headers: HEADERS
    },
}

Api.coursetypes = {
    getAll: {
        url: () => Api.endpointCourseTypes + '/getAll',
        method: methods.GET,
        headers: HEADERS
    }
}

Api.inquiries = {
    getAll: {
        url: () => Api.endpointInquiries,
        method: methods.GET,
        headers: HEADERS
    }
}
