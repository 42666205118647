import { combineReducers } from 'redux';
import { FETCH_ACHIEVEMENTS, DELETE_ACHIEVEMENT, FETCH_ACHIEVEMENT } from '../actions/achievements';
import {FETCH_PAYABLE_ITEM} from "../actions/payableItems";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_ACHIEVEMENTS:
			const newState = {};
			action.achievements.forEach(achievement => newState[achievement._id] = achievement);
			return newState;
		case FETCH_ACHIEVEMENT:
			return { ...state, [action.achievement._id]: action.achievement };
		case DELETE_ACHIEVEMENT:
			const { achievement } = action;
			delete state[achievement._id]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_ACHIEVEMENTS:
			return action.achievements.map(achievement => achievement._id);
		case FETCH_ACHIEVEMENT:
			if (state.includes(action.achievement._id)) {
				return state
			} else{
				return [ ...state, action.achievement._id ];
			}
		case DELETE_ACHIEVEMENT:
			const { achievement } = action
			return state.filter(stateAchievement => stateAchievement !== achievement._id);
	  default:
		return state;
    }
}

const achievements = combineReducers({
    byId,
    ids
});

export default achievements;

export const getAchievements = state => state.ids.map(id => state.byId[id]);

export const getAchievementById = (state, _id) => state.byId[_id];

export const getAchievementByDocId = (state, docId) => state.ids.map(id => state.byId[id]).find(achievement => achievement._id === docId);