import * as fromApi from '../api/branches';

export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const FETCH_BRANCH = 'FETCH_BRANCH';
export const DELETE_BRANCH = 'DELETE_BRANCH';

export const fetchBranches = () => dispatch => {
    return fromApi.getBranches().then(response => {
      dispatch({ type: FETCH_BRANCHES, branches: response.branches || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const addBranch = branch => dispatch => {
  return fromApi.addBranch(branch).then(response => {
    const {error, branch} = response;
    if (error) return {error};

    dispatch({type: FETCH_BRANCH, branch: branch || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateBranch = (docId, branch) => dispatch => {
  return fromApi.updateBranch(docId, branch).then(response => {
    const { error, branch } = response;
    if(error) return {error};

    dispatch({ type: FETCH_BRANCH, branch: branch || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteBranch = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteBranch(docId, deleteRequest).then(response => {
    const {error, branch} = response;
    if (error) return {error};
    dispatch({type: DELETE_BRANCH, branch: branch || []});
    return response;
  }).catch(error => {
    return {error};
  });
}