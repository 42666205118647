import { combineReducers } from 'redux';
import { FETCH_INQUIRIES } from '../actions/inquiries';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INQUIRIES:
      const newState = {};
      action.inquiries.forEach(inquiry => newState[inquiry._id] = inquiry);
      return newState;
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_INQUIRIES:
      return action.inquiries.map(inquiry => inquiry._id);
    default:
      return state;
  }
}

const inquiries = combineReducers({
  byId,
  ids
});

export const getInquiries = state => state.ids.map(id => state.byId[id]);

export default inquiries;