import { combineReducers } from 'redux';
import { FETCH_BONUS_ITEMS } from '../actions/bonusItems'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_BONUS_ITEMS:
		const newState = {};
		action.bonusItems.forEach(bonusItem => newState[bonusItem._id] = bonusItem);
		return newState;
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BONUS_ITEMS:
		return action.bonusItems.map(bonusItem => bonusItem._id);
	  default:
		return state;
    }
}

export const byBranchId = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BONUS_ITEMS:
		const newState = {};
		action.bonusItems.forEach(bonusItem => {
			const idsList = newState[bonusItem.branchId] ? newState[bonusItem.branchId] : []
			newState[bonusItem.branchId] = [ ...idsList, bonusItem._id ]
		});
		return newState;
	  default:
		return state;
    }
}

const bonusItems = combineReducers({
    byId,
	ids,
	byBranchId
});

export default bonusItems;

export const getBonusItems = state => state.ids.map(id => state.byId[id]);

export const getBonusItemsById = (state, itemId) => state.byId[itemId];

export const getBonusItemsByBranchId = (state, branchId) => {
	return state.byBranchId[branchId] && state.byBranchId[branchId].length > 0 && state.byBranchId[branchId].map(id => state.byId[id]);
}