import * as fromApi from '../api/announcements';

export const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS';
export const FETCH_ANNOUNCEMENT = 'FETCH_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

export const fetchAnnouncements = () => dispatch => {
    return fromApi.getAnnouncements().then(response => {
      dispatch({ type: FETCH_ANNOUNCEMENTS, announcements: response.announcements || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchAnnouncement = (id) => dispatch => {
  return fromApi.getAnnouncement(id).then(response => {
    dispatch({ type: FETCH_ANNOUNCEMENT, announcement: response.announcement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const addAnnouncement = announcement => dispatch => {
  return fromApi.addAnnouncement(announcement).then(response => {
    const {error, announcement} = response;
    if (error) return {error};

    dispatch({type: FETCH_ANNOUNCEMENT, announcement: announcement || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateAnnouncement = (docId, announcement) => dispatch => {
  return fromApi.updateAnnouncement(docId, announcement).then(response => {
    const { error, announcement } = response;
    if(error) return {error};

    dispatch({ type: FETCH_ANNOUNCEMENT, announcement: announcement || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteAnnouncement = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteAnnouncement(docId, deleteRequest).then(response => {
    const {error, announcement} = response;
    if (error) return {error};
    dispatch({type: DELETE_ANNOUNCEMENT, announcement: announcement || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

export const activateAnnouncement = (id) => dispatch => {
  return fromApi.activateAnnouncement(id).then(response => {
    dispatch({ type: FETCH_ANNOUNCEMENT, announcement: response.announcement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const deactivateAnnouncement = (id) => dispatch => {
  return fromApi.deactivateAnnouncement(id).then(response => {
    dispatch({ type: FETCH_ANNOUNCEMENT, announcement: response.announcement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}