import * as fromApi from '../api/courseLevels';
import {DELETE_PAYABLE_ITEM, FETCH_PAYABLE_ITEM} from "./payableItems";

export const FETCH_COURSE_LEVELS = 'FETCH_COURSE_LEVELS';
export const FETCH_COURSE_LEVEL = 'FETCH_COURSE_LEVEL';
export const DELETE_COURSE_LEVEL = 'DELETE_COURSE_LEVEL';

export const fetchCourseLevels = () => dispatch => {
  return fromApi.getCourseLevels().then(response => {
    dispatch({ type: FETCH_COURSE_LEVELS, courseLevels: response.courseLevels || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addCourseLevel = courseLevel => dispatch => {
  return fromApi.addCourseLevel(courseLevel).then(response => {
    const { error, courseLevel } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE_LEVEL, courseLevel: courseLevel || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateCourseLevel = (docId, courseLevel) => dispatch => {
  return fromApi.updateCourseLevel(docId, courseLevel).then(response => {
    const { error, courseLevel } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE_LEVEL, courseLevel: courseLevel || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteCourseLevel = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteCourseLevel(docId, deleteRequest).then(response => {
    const { error, courseLevel } = response;
    if(error) return {error};

    dispatch({type: DELETE_COURSE_LEVEL, courseLevel: courseLevel || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

