import Api from './const';
import fetch from './commonFetch'


const { getAll, get, add, update, remove, activate, deactivate } = Api.announcements;

export const getAnnouncements = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'announcements');
}

export const getAnnouncement = id => {
    const { url, method, headers } = get;

    return fetch(url(id), { method, headers }, "announcement")
}

export const addAnnouncement = announcement => {
    const { url, method, headers } = add;
    const body = JSON.stringify(announcement);

    return fetch(url(), { method, headers, body }, "announcement")
}

export const updateAnnouncement = (docId, announcement) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(announcement);

    return fetch(url(docId), { method, headers, body }, "announcement")
}

export const deleteAnnouncement = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'announcement')
}

export const activateAnnouncement = id => {
    const { url, method, headers } = activate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "announcement")
}

export const deactivateAnnouncement = id => {
    const { url, method, headers } = deactivate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "announcement")
}
