import * as fromApi from '../api/auth'

export const authenticate = user => dispatch => {
  return fromApi.auth(user).then(response => {
    dispatch({ type: 'SIGN_IN', authenticity: response.authenticity });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const logout = authenticity => dispatch => {
  return fromApi.updateLogout(authenticity).then(response => {
    dispatch({ type: 'SIGN_OUT' })
  })
};