import Api from './const';
import fetch from './commonFetch'

const { 
    getAll, 
    get, 
    add, 
    update, 
    remove, 
    assignBatch, 
    unassignBatch, 
    addStudentCard, 
    getCardsByStudentId, 
    updateStudentCardStatus,
    deleteStudentCard } = Api.students;

export const getStudents = (query) => {
    const { url, method, headers } = getAll;
    const body = JSON.stringify(query);
    return fetch(url(), { method, headers, body }, "students")
}

export const getStudent = studentid => {
    const { url, method, headers } = get;

    return fetch(url(studentid), { method, headers }, "student")
}

export const addStudent = (student) => {
    const { url, method, headers } = add;
    const body = JSON.stringify(student);

    return fetch(url(), { method, headers, body }, "student")
}

export const updateStudent = student => {
    const { url, method, headers } = update;
    const body = JSON.stringify(student);
    return fetch(url(student.studentdocid), { method, headers, body }, "student")
}

export const assignBatchToStudent = (studentid, batchid, assignBatchData) => {
    const { url, method, headers } = assignBatch;
    const body = JSON.stringify(assignBatchData);

    return fetch(url(studentid, batchid), { method, headers, body }, "course")
}

export const deleteStudent = id => {
    const { url, method, headers } = remove;
  
    return fetch(url(id), { method, headers }, "student")
  }

  export const removeBatchFromStudent = request => {
    const {url, method, headers } = unassignBatch;
    const body = JSON.stringify(request);

    return fetch(url(), {method, headers, body}, "deleteCourseResponse")
}

export const addCard = studentCardData => {
    const { url, method, headers } = addStudentCard;
    const body = JSON.stringify(studentCardData);
    return fetch(url(), { method, headers, body }, "addCardResponse")
}

export const fetchCardsByStudentId = studentid => {
    const { url, method, headers } = getCardsByStudentId;

    return fetch(url(studentid), { method, headers }, "cards")
}

export const updateStatus = (studentid, cardid, request) => {
    const { url, method, headers } = updateStudentCardStatus;
    const body = JSON.stringify(request);

    return fetch(url(studentid, cardid), { method, headers, body }, "addCardResponse")
}

export const deleteCard = (studentid, cardid, request) => {
    const { url, method, headers } = deleteStudentCard;
    const body = JSON.stringify(request);

    return fetch(url(studentid, cardid), { method, headers, body }, "deleteCardResponse")
}