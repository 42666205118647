import { FETCH_APP_DATA } from '../actions/appData';

export const appData = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_APP_DATA:
	
		return { ...action.appData };
	  default:
		return state;
    }
};

export default appData;

export const getAppData = state => state