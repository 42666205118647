import Api from './const';
import fetch from './commonFetch'

const { getAll, add, update, remove, advancedSearch } = Api.payableItems;

export const getPayableItems = branchId => {
    const { url, method, headers } = advancedSearch;
    const query = {branchId: branchId};
    const body = JSON.stringify(query);
    return fetch(url(), { method, headers, body }, "payableItems")
}

export const addPayableItem = payableItem => {
    const { url, method, headers } = add;
    const body = JSON.stringify(payableItem);

    return fetch(url(), { method, headers, body }, "payableItem")
}

export const updatePayableItem = (docId, payableItem) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(payableItem);

    return fetch(url(docId), { method, headers, body }, "payableItem")
}

export const deletePayableItem = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'payableItem')
}