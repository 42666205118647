import { store } from '../redux/store';
import { getAuthenticity } from '../reducers';

const commonFetch = (url, data, responseType) => {
  const authData = getAuthenticity(store.getState());

  const headers = data.headers || {};
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';
  headers['Auth-Token'] = authData.authToken;
  data.headers = headers;
  return fetch(url, data)
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        if (responseType) {
          return { [responseType]: responseJson.body };
        } else {
          return { response: responseJson.body };
        }
      } else {
        return { error: responseJson.body };
      }
    }).catch(error => {
      return { error };
    });
};

export default commonFetch;