import * as fromApi from '../api/batches';

export const FETCH_BATCHES = 'FETCH_BATCHES';
export const FETCH_BATCHES_BY_QUERY = 'FETCH_BATCHES_BY_QUERY';
export const FETCH_BATCH = 'FETCH_BATCH';
export const FETCH_DETAIL_BATCH = 'FETCH_DETAIL_BATCH';
export const DELETE_BATCHES_BY_BATCH_ID = 'DELETE_BATCHES_BY_BATCH_ID';

export const fetchBatches = (page, pageSize) => dispatch => {
    return fromApi.getBatches(page, pageSize).then(response => {
	  dispatch({ type: FETCH_BATCHES, batches: response.batches || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const advanceSearch = query => dispatch => {
    return fromApi.batchAdvanceSearch(query).then(response => {
        const key = query.branchid
	  dispatch({ type: FETCH_BATCHES_BY_QUERY, result: { key, batches: response.batches || [] } });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchBatch = batchid => dispatch => {
    return fromApi.getBatch(batchid).then(response => {
      dispatch({ type: FETCH_BATCH, batch: response.batch });
      
      return response;
    }).catch(error => {
      return { error };
    });
  }

  export const addBatch = batch => dispatch => {
    return fromApi.addBatch(batch).then(response => {
      if(response.error) return {error: response.error};
  
      dispatch({ type: FETCH_BATCH, batch: response.batch });
      return response;
    }).catch(error => {
      return { error };
    });
  }
  
  export const updateBatch = (docId, batch) => dispatch => {
    return fromApi.updateBatch(docId, batch).then(response => {
      if(response.error) return {error: response.error};
      
      dispatch({ type: FETCH_BATCH, batch: response.batch });
      return response;
    }).catch(error => {
      return { error };
    });
  }

  export const fetchDetailBatch = docId => dispatch => {
    return fromApi.getDetailBatchData(docId).then(response => {
      if(response.error) return {error: response.error};
      
      dispatch({ type: FETCH_DETAIL_BATCH, detailBatchData: response.detailBatchData });
      
      return response;
    }).catch(error => {
      return { error };
    });
  }

  export const deleteBatchesByIds = deleteRequest => dispatch => {
    return fromApi.deleteBatchesByIds(deleteRequest).then(response => {
    const { deletedResponse } = response;
    const { deletedBatchIds, failedToDeleteIds } = deletedResponse && deletedResponse

	  if (deletedBatchIds.length > 0){
		  dispatch({ type: DELETE_BATCHES_BY_BATCH_ID, deletedBatchesResponse: { deletedBatchIds } || [] });
	  }
    return { deletedBatchIds, failedToDeleteIds };
    }).catch(error => {
        return { error };
    })
}

export const changeBatchStatus = (batches) => dispatch => {
  return fromApi.changeBatchStatus(batches).then(response => {
    if(response.error) return {error: response.error};
    
    dispatch({ type: FETCH_DETAIL_BATCH, detailBatchData: response.updateBatchStatusResponse.success[0] });
    dispatch({ type: FETCH_BATCH, batch: response.updateBatchStatusResponse.success[0] });
    return response;
  }).catch(error => {
    return { error };
  });
}