import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';
import { Permission, isActionPermitted } from '../../helpers/Permission'

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Dashboard/UserDashboard/Container.js')),
    action: Permission.TEMPLATE.DASHBOARD.USERDASHBOARD.USERDASHBOARD
  },
  {
    path: 'permissionDenied',
    component: asyncComponent(() => import('../Page/PermissionDenied')),
  },
  {
    path: 'students',
    component: asyncComponent(() => import('../StudentList/index.js')),
    action: Permission.TEMPLATE.STUDENTS.STUDENT_REGISTRATION.STUDENT_REGISTRATION
  },
  {
    path: 'systemParameters',
    component: asyncComponent(() => import('../SystemParameters/index.js')),
    action: Permission.TEMPLATE.CONFIGURATIONS.SYSTEM_PARAMETERES.SYSTEM_PARAMETERES
  },
  {
    path: 'payableItemList',
    component: asyncComponent(() => import('../PayableItems/index.js')),
    action: Permission.TEMPLATE.CONFIGURATIONS.SYSTEM_PARAMETERES.SYSTEM_PARAMETERES
  },
  {
    path: 'payableItemAdd',
    component: asyncComponent(() => import('../PayableItemAdd/index.js')),
  },
  {
    path: 'payableItemEdit/:itemId',
    component: asyncComponent(() => import('../PayableItemAdd/index.js')),
  },
  {
    path: 'courseLevelList',
    component: asyncComponent(() => import('../CourseLevelList/index.js')),
  },
  {
    path: 'courseLevelAdd',
    component: asyncComponent(() => import('../CourseLevelAdd/index.js')),
  },
  {
    path: 'courseLevelEdit/:courseLevelDocId',
    component: asyncComponent(() => import('../CourseLevelAdd/index.js')),
  },
  {
    path: 'institute',
    component: asyncComponent(() => import('../Institute/index.js')),
  },
  {
    path: 'instituteAdd',
    component: asyncComponent(() => import('../InstituteAdd/index.js')),
  },
  {
    path: 'instituteEdit/:instituteDocId',
    component: asyncComponent(() => import('../InstituteAdd/index.js')),
  },
  {
    path: 'branchAdd/:companyDocId',
    component: asyncComponent(() => import('../BranchAdd/index.js')),
  },
  {
    path: 'branchEdit/:branchDocId',
    component: asyncComponent(() => import('../BranchAdd/index.js')),
  },
  {
    path: 'faculty',
    component: asyncComponent(() => import('../Faculty/index.js')),
  },
  {
    path: 'facultySelected/:facultyId',
    component: asyncComponent(() => import('../Faculty/index.js')),
  },
  {
    path: 'facultyAdd',
    component: asyncComponent(() => import('../FacultyAdd/index.js')),
  },
  {
    path: 'facultyEdit/:docId',
    component: asyncComponent(() => import('../FacultyAdd/index.js')),
  },
  {
    path: 'departmentAdd/:facultyId',
    component: asyncComponent(() => import('../DepartmentAdd/index.js')),
  },
  {
    path: 'departmentEdit/:docId',
    component: asyncComponent(() => import('../DepartmentAdd/index.js')),
  },
  {
    path: 'courses',
    component: asyncComponent(() => import('../CourseList/index.js')),
    action: Permission.TEMPLATE.CONFIGURATIONS.COURSES.COURSES
  },
  {
    path: 'courseAdd',
    component: asyncComponent(() => import('../CourseAdd/index.js')),
  },
  {
    path: 'CourseEdit/:docId',
    component: asyncComponent(() => import('../CourseAdd/index.js')),
  },
  {
    path: 'CourseTypeEdit/:courseDocId/:typeId',
    component: asyncComponent(() => import('../CourseAdd/CourseTypeEdit/index.js')),
  },
  {
    path: 'courseType/payableItemAdd/:courseDocId/:courseTypeId',
    component: asyncComponent(() => import('../PayableItemAdd/index.js')),
  },
  {
    path: 'invoices',
    component: asyncComponent(() => import('../Payments/InvoicesContainer.js')),
    action: Permission.TEMPLATE.PAYMENTS.INVOICES.INVOICES
  },
  {
    path: 'invoice',
    component: asyncComponent(() => import('../InvoiceComponent/InvoiceContainer.js')),
  },
  {
    path: 'invoice/:invoiceId',
    component: asyncComponent(() => import('../InvoiceComponent/InvoiceContainer.js')),
  },
  {
    path: 'waveoff',
    component: asyncComponent(() => import('../WaveOff/InvoiceWaveOffContainer.js')),
    action: Permission.TEMPLATE.PAYMENTS.WAVEOFF.WAVEOFF
  },
  {
    path: 'studentRegistration',
    component: asyncComponent(() => import('../RegisterStudent/index.js')),
  },
  {
    path: 'editStudent/:studentdocid',
    component: asyncComponent(() => import('../EditStudent/index.js')),
  },
  {
    path: 'viewStudent/:studentdocid',
    component: asyncComponent(() => import('../ViewStudent/index.js')),
  },
  {
    path: 'settleInvoices/:studentdocid',
    component: asyncComponent(() => import('../StudentPayments/index.js')),
  },
  {
    path: 'printReceipt/:receiptId',
    component: asyncComponent(() => import('../ReceiptView/receiptTemplate.js')),
  },
  {
    path: 'receiptSummary',
    component: asyncComponent(() => import('../ReceiptsSummary/ReceiptsContainer.js')),
    action: Permission.TEMPLATE.PAYMENTS.RECEIPTS.RECEIPTS
  },
  {
    adminRoute: true,
    path: 'waveOffSummary',
    component: asyncComponent(() => import('../WaveOffSummaryView/index.js')),
    action: Permission.TEMPLATE.PAYMENTS.WAVEOFF.WAVEOFF
  },
  {
    path: 'printWaveOff/:waveoffId',
    component: asyncComponent(() => import('../WaveOffPrintView/waveOffTemplate.js')),
  },
  {
    path: 'userDashboard',
    component: asyncComponent(() => import('../Dashboard/UserDashboard/Container.js')),
    action: Permission.TEMPLATE.DASHBOARD.USERDASHBOARD.USERDASHBOARD
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../UserList/index')),
    action: Permission.TEMPLATE.ADMINISTRATION.USERS.USERS,
  },
  {
    path: 'addAction',
    component: asyncComponent(() => import('../RegisterAction/index')),
  },
  {
    path: 'actions',
    component: asyncComponent(() => import('../ActionList/index')),
    action: Permission.TEMPLATE.ADMINISTRATION.ACTIONS.ACTIONS,
  },
  {
    path: 'addRole',
    component: asyncComponent(() => import('../RegisterRole/index')),
  },
  {
    path: 'roles',
    component: asyncComponent(() => import('../RoleList/index')),
    action: Permission.TEMPLATE.ADMINISTRATION.ROLES.ROLES,
  },
  {
    path: 'editRole/:roleDocId',
    component: asyncComponent(() => import('../EditRole/index')),
  },
  {
    path: 'editAction/:actionDocId',
    component: asyncComponent(() => import('../EditAction/index')),
  },
  {
    path: 'userRegistration',
    component: asyncComponent(() => import('../RegisterUser/index')),
  },
  {
    path: 'editUser/:userDocId',
    component: asyncComponent(() => import('../EditUser/index')),
  },
  {
    path: 'bankDepositSummary',
    component: asyncComponent(() => import('../BankDepositSummary/index')),
    action: Permission.TEMPLATE.PAYMENTS.BANK_DEPOSITS.BANK_DEPOSITS
  },
  {
    path: 'editBankDeposit/:depositDocId',
    component: asyncComponent(() => import('../BankDepositInfoForm/index')),
  },
  {
    path: 'viewBankDeposit/:depositDocId/:view',
    component: asyncComponent(() => import('../BankDepositInfoForm/index')),
  },
  {
    path: 'newBankDeposit',
    component: asyncComponent(() => import('../BankDepositInfoForm/index')),
  },
  {
    path: 'printBankDeposit/:depositDocId',
    component: asyncComponent(() => import('../BankDepositPrint/index')),
  },
  {
    path: 'studentMaterialOrderList',
    component: asyncComponent(() => import('../StudentMaterialOrderList/index')),
  },
  {
    path: 'printMaterialOrderList/:materialOrderId',
    component: asyncComponent(() => import('../StudentMaterialOrder/OrderListPrintView/MaterialOrderPrintTemplate.js')),
  },
  {
    path: 'printMaterialStudentAssignList/:materialOrderId',
    component: asyncComponent(() => import('../StudentMaterialOrder/StudentAssignListPrintView/index'))
  },
  {
    path: 'studentMaterialOrderAssignList',
    component: asyncComponent(() => import('../MaterialOrderAssignList/index')),
  },
  {
    path: 'branchWiseCourseMaterialOrder',
    component: asyncComponent(() => import('../BranchWiseCourseMaterialOrder/index')),
  },
  {
    path: 'studentMaterialOrder/:orderId',
    component: asyncComponent(() => import('../StudentMaterialOrder/index')),
  },
  {
    path: 'resetPassword',
    component: asyncComponent(() => import('../ResetPassword/index')),
    action: Permission.TEMPLATE.USER_PROFILE.RESET_PASSWORD,
  },
  {
    path: 'forgotPassword',
    component: asyncComponent(() => import('../Page/forgotPassword'))
  },
  {
    path: 'batches',
    component: asyncComponent(() => import('../BatchSummaryView/BatchSummaryContainer.js')),
    action: Permission.TEMPLATE.CONFIGURATIONS.BATCHES.BATCHES,
  },
  {
    path: 'batch',
    component: asyncComponent(() => import('../Batches/BatchFormContainer.js')),
  },
  {
    path: 'batch/:batchId',
    component: asyncComponent(() => import('../Batches/BatchFormContainer.js')),
  },
  {
    path: 'batch/view/:batchId',
    component: asyncComponent(() => import('../Batches/BatchViewContainer.js')),
  },
  {
    path: 'publishCourses',
    component: asyncComponent(() => import('../PublishCourses/PublishCoursesContainer.js')),
    action: Permission.TEMPLATE.CONFIGURATIONS.PUBLISH_COURSES.PUBLISH_COURSES
  },
  {
    path: 'partners',
    component: asyncComponent(() => import('../WebApp/Partners/List'))
  },
  {
    path: 'partners/:partnerId',
    component: asyncComponent(() => import('../WebApp/Partners/Form/Container'))
  },
  {
    path: 'testimonials',
    component: asyncComponent(() => import('../WebApp/Testimonials/List'))
  },
  {
    path: 'testimonials/:testimonialId',
    component: asyncComponent(() => import('../WebApp/Testimonials/Form/Container'))
  },
  {
    path: 'achievements',
    component: asyncComponent(() => import('../WebApp/Achievements/List'))
  },
  {
    path: 'achievements/:achievementId',
    component: asyncComponent(() => import('../WebApp/Achievements/Form/Container'))
  },
  {
    path: 'announcements',
    component: asyncComponent(() => import('../WebApp/Announcements/List'))
  },
  {
    path: 'inquiries',
    component: asyncComponent(() => import('../Inquiries'))
  },
  {
    path: 'announcements/:announcementId',
    component: asyncComponent(() => import('../WebApp/Announcements/Form/Container'))
  },
  {
    path: 'table_ant',
    component: asyncComponent(() => import('../Tables/antTables')),
  },
  {
    path: 'allFormComponent',
    component: asyncComponent(() => import('../Forms/allComponents/')),
  },
  {
    path: 'InputField',
    component: asyncComponent(() => import('../Forms/Input')),
  },
  {
    path: 'editor',
    component: asyncComponent(() => import('../Forms/editor/')),
  },
  {
    path: 'stepperForms',
    component: asyncComponent(() => import('../Forms/StepperForms/')),
  },
  {
    path: 'FormsWithValidation',
    component: asyncComponent(() => import('../Forms/FormsWithValidation')),
  },
  {
    path: 'progress',
    component: asyncComponent(() => import('../Forms/Progress')),
  },
  {
    path: 'button',
    component: asyncComponent(() => import('../Forms/Button')),
  },
  {
    path: 'tab',
    component: asyncComponent(() => import('../Forms/Tab')),
  },
  {
    path: 'autocomplete',
    component: asyncComponent(() => import('../Forms/AutoComplete')),
  },
  {
    path: 'checkbox',
    component: asyncComponent(() => import('../Forms/Checkbox')),
  },
  {
    path: 'radiobox',
    component: asyncComponent(() => import('../Forms/Radiobox/')),
  },
  {
    path: 'selectbox',
    component: asyncComponent(() => import('../Forms/Select/')),
  },
  {
    path: 'transfer',
    component: asyncComponent(() => import('../Forms/Transfer/')),
  },
  {
    path: 'gridLayout',
    component: asyncComponent(() => import('../Box/GridLayout')),
  },
  {
    path: 'notes',
    component: asyncComponent(() => import('../Notes')),
  },
  {
    path: 'todo',
    component: asyncComponent(() => import('../Todo')),
  },
  {
    path: 'articles',
    component: asyncComponent(() => import('../FirestoreCRUD/Article')),
  },
  {
    path: 'investors',
    component: asyncComponent(() => import('../FirestoreCRUD/Investor')),
  },
  {
    path: 'contacts',
    component: asyncComponent(() => import('../Contacts')),
  },
  {
    path: 'alert',
    component: asyncComponent(() => import('../Feedback/Alert')),
  },
  {
    path: 'modal',
    component: asyncComponent(() => import('../Feedback/Modal/')),
  },
  {
    path: 'message',
    component: asyncComponent(() => import('../Feedback/Message')),
  },
  {
    path: 'notification',
    component: asyncComponent(() => import('../Feedback/Notification')),
  },
  {
    path: 'Popconfirm',
    component: asyncComponent(() => import('../Feedback/Popconfirm')),
  },
  {
    path: 'spin',
    component: asyncComponent(() => import('../Feedback/Spin')),
  },
  {
    path: 'shuffle',
    component: asyncComponent(() => import('../Shuffle')),
  },
  {
    path: 'affix',
    component: asyncComponent(() => import('../Navigation/affix')),
  },
  {
    path: 'breadcrumb',
    component: asyncComponent(() =>
      import('../Uielements/Breadcrumb/breadcrumb')
    ),
  },
  {
    path: 'backToTop',
    component: asyncComponent(() => import('../Navigation/backToTop')),
  },
  {
    path: 'dropdown',
    component: asyncComponent(() => import('../Uielements/Dropdown/dropdown')),
  },
  {
    path: 'op_badge',
    component: asyncComponent(() => import('../Uielements/Badge')),
  },
  {
    path: 'op_card',
    component: asyncComponent(() => import('../Uielements/Card')),
  },
  {
    path: 'op_carousel',
    component: asyncComponent(() => import('../Uielements/Carousel')),
  },
  {
    path: 'op_collapse',
    component: asyncComponent(() => import('../Uielements/Collapse')),
  },
  {
    path: 'op_tooltip',
    component: asyncComponent(() => import('../Uielements/Tooltip/')),
  },
  {
    path: 'rating',
    component: asyncComponent(() => import('../Uielements/rating/')),
  },
  {
    path: 'tree',
    component: asyncComponent(() => import('../Uielements/Tree/')),
  },
  {
    path: 'op_tag',
    component: asyncComponent(() => import('../Uielements/Tag')),
  },
  {
    path: 'op_timeline',
    component: asyncComponent(() => import('../Uielements/Timeline')),
  },
  {
    path: 'op_popover',
    component: asyncComponent(() => import('../Uielements/Popover')),
  },
  {
    path: 'googleChart',
    component: asyncComponent(() => import('../Charts/googleChart')),
  },
  {
    path: 'reecharts',
    component: asyncComponent(() => import('../Charts/recharts')),
  },
  {
    path: 'menu',
    component: asyncComponent(() => import('../Navigation/menu')),
  },
  {
    path: 'ReactChart2',
    component: asyncComponent(() => import('../Charts/reactChart2')),
  },
  {
    path: 'pagination',
    component: asyncComponent(() =>
      import('../Uielements/Pagination/pagination')
    ),
  },
  {
    path: 'card',
    component: asyncComponent(() => import('../Ecommerce/card')),
  },
  {
    path: 'cart',
    component: asyncComponent(() => import('../Ecommerce/cart')),
  },
  {
    path: 'checkout',
    component: asyncComponent(() => import('../Ecommerce/checkout')),
  },
  {
    path: 'shop',
    component: asyncComponent(() =>
      import('../Ecommerce/algolia/instantSearch')
    ),
  },
  {
    path: 'reactDates',
    component: asyncComponent(() =>
      import('../AdvancedUI/ReactDates/reactDates')
    ),
  },
  {
    path: 'codeMirror',
    component: asyncComponent(() => import('../AdvancedUI/codeMirror')),
  },
  {
    path: 'uppy',
    component: asyncComponent(() => import('../AdvancedUI/uppy')),
  },
  {
    path: 'dropzone',
    component: asyncComponent(() => import('../AdvancedUI/dropzone')),
  },
  {
    path: 'youtubeSearch',
    component: asyncComponent(() => import('../YoutubeSearch')),
  },
  {
    path: 'frappeChart',
    component: asyncComponent(() => import('../Charts/frappeChart')),
  },
  {
    path: 'chat',
    component: asyncComponent(() => import('../Chat')),
  },
  ...customRoutes,
];

const forbidden = {
  component: asyncComponent(() => import('../Page/PermissionDenied'))
}

class AppRouter extends Component {

  filterAvailableRoutes = () => this.props.isAdmin ? routes : routes.filter(route => !route.adminRoute);

  render() {
    const { url, style } = this.props;

    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, action, component, ...otherProps } = singleRoute;
          const errorComponent = !action ? component : isActionPermitted(action) ? component : forbidden.component
          otherProps.component = errorComponent

          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
