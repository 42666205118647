import Api from './const';
import fetch from './commonFetch'


const { getAll, get, add, update, remove, activate, deactivate } = Api.achievements;

export const getAchievements = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'achievements');
}

export const getAchievement = id => {
    const { url, method, headers } = get;

    return fetch(url(id), { method, headers }, "achievement")
}

export const addAchievement = achievement => {
    const { url, method, headers } = add;
    const body = JSON.stringify(achievement);

    return fetch(url(), { method, headers, body }, "achievement")
}

export const updateAchievement = (docId, achievement) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(achievement);

    return fetch(url(docId), { method, headers, body }, "achievement")
}

export const deleteAchievement = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'achievement')
}

export const activateAchievement = id => {
    const { url, method, headers } = activate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "achievement")
}

export const deactivateAchievement = id => {
    const { url, method, headers } = deactivate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "achievement")
}
