import * as fromApi from '../api/receipts';
import {UPDATE_INVOICES, FETCH_UPDATED_INVOICES_BY_STUDENT_ID} from './invoices'

export const FETCH_RECEIPTS = 'FETCH_RECEIPTS';
export const FETCH_RECEIPT = 'FETCH_RECEIPT';
export const ADD_RECEIPT = 'ADD_RECEIPT';
export const MAKE_RECEIPTS_BY_STUDENT_ID = 'MAKE_RECEIPTS_BY_STUDENT_ID';
export const PRINT_RECEIPTS_BY_ID = 'PRINT_RECEIPTS_BY_ID';
export const DELETE_RECEIPTS = 'DELETE_RECEIPTS';
export const FETCH_RECEIPTS_NOT_BANKED = 'FETCH_RECEIPTS_NOT_BANKED';

export const fetchReceipts = query => dispatch => {
    return fromApi.receiptAdvanceSearch(query).then(response => {
        dispatch({ type: FETCH_RECEIPTS, receipts: response.receipts || [] });
        return response;
    }).catch(error => {
        return { error };
    });
}

export const fetchReceipt = receiptId => dispatch => {
    return fromApi.getReceipt(receiptId).then(response => {
	  dispatch({ type: FETCH_RECEIPT, receipt: response.receipt || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const addReceipt = settleInvoiceData => dispatch => {
    return fromApi.addReceipt(settleInvoiceData).then(response => {
      const { invoices, receipt } = response.settleInvoiceResponse;
      dispatch({ type: ADD_RECEIPT, receipt: receipt || [] });
      dispatch({ type: FETCH_UPDATED_INVOICES_BY_STUDENT_ID, invoices: invoices || [] });
      dispatch({ type: UPDATE_INVOICES, invoices: invoices || [] });
      return response;
    }).catch(error => {
      return { error };
    });
  }

  export const fetchReceiptsByCustomerId = studentId => dispatch => {
    return fromApi.getReceiptsByCustomer(studentId).then(response => {
    dispatch({ type: MAKE_RECEIPTS_BY_STUDENT_ID, receipts: response.receipts || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchPrintReceiptsById = receiptId => dispatch => {
  return fromApi.getPrintReceiptsById(receiptId).then(response => {
  dispatch({ type: PRINT_RECEIPTS_BY_ID, receipts: response.receipts || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const deleteReceipts = receiptIds => dispatch => {
  return fromApi.deleteReceipts(receiptIds).then(response => {
    if (response.error) return response;

    const { success } = response.deleteReceiptResponse;
    const { invoices, receipts } = success;

    dispatch({ type: DELETE_RECEIPTS, receipts: receipts || [] });
    dispatch({ type: FETCH_UPDATED_INVOICES_BY_STUDENT_ID, invoices: invoices || [] });
    dispatch({ type: UPDATE_INVOICES, invoices: invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchReceiptsByBranchId = branchId => dispatch => {
  return fromApi.getReceiptsByBranch(branchId).then(response => {
  dispatch({ type: FETCH_RECEIPTS, receipts: response.receipts || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const fetchReceiptsByIds = query => dispatch => {
  return fromApi.getReceiptsByIdList(query).then(response => {
      dispatch({ type: FETCH_RECEIPTS, receipts: response.receipts || [] });
      return response;
  }).catch(error => {
      return { error };
  });
}

export const fetchNotBankedReceiptsByBranchId = branchId => dispatch => {
  return fromApi.getNotBankedReceiptsByBranchId(branchId).then(response => {
      dispatch({ type: FETCH_RECEIPTS_NOT_BANKED, receipts: response.receipts || [] });
      return response;
  }).catch(error => {
      return { error };
  });
}