import * as fromApi from '../api/materialOrders';

export const FETCH_MATERIAL_ORDERS = 'FETCH_MATERIAL_ORDERS';
export const DELETE_MATERIAL_ORDER = 'DELETE_MATERIAL_ORDER';
export const TO_BE_ORDER_LIST = 'TO_BE_ORDER_LIST';
export const FETCH_MATERIAL_ORDER = 'FETCH_MATERIAL_ORDER';
export const ADD_MATERIAL_ORDER = 'ADD_MATERIAL_ORDER';
export const FETCH_MATERIAL_ORDER_STUDENTS = 'FETCH_MATERIAL_ORDER_STUDENTS';

export const fetchMaterialOrders = query => dispatch => {
    return fromApi.ordersAdvanceSearch(query).then(response => {
        dispatch({ type: FETCH_MATERIAL_ORDERS, materialOrders: response.materialOrders || [] });
        return response;
    }).catch(error => {
        return { error };
    });
}

export const deleteMaterialOrder = (docId, body) => dispatch => {
    return fromApi.deleteMaterialOrder(docId, body).then(response => {
      if (response.error) {
          return { error: response.error }
      }

	  dispatch({type: DELETE_MATERIAL_ORDER, materialOrder: response.materialOrder});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const fetchOrderByOrderId = orderId => dispatch => {
  return fromApi.getOrderByOrderId(orderId).then(response => {
    const { materialOrderResponse, error } = response;
      if(error) return response;

      const { materialOrder, students } = materialOrderResponse;
  dispatch({ type: ADD_MATERIAL_ORDER, materialOrder: { ...materialOrder } });
  dispatch({ type: FETCH_MATERIAL_ORDER_STUDENTS, materialOrderStudentList: { orderId: materialOrder.materialorderid, students } });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const addMaterialOrder = orderData => dispatch => {
    return fromApi.addMaterialOrder(orderData).then(response => {
      const { materialOrderResponse, error } = response;
      if(error) return response;

      const { materialOrder } = materialOrderResponse;
      dispatch({ type: ADD_MATERIAL_ORDER, materialOrder: { ...materialOrder } });
      return response;
    }).catch(error => {
      return { error };
    });
  }

export const fetchToBeOrderMaterialOrderList = request => dispatch => {
    return fromApi.getToBeOrderMaterialOrders(request).then(response => {
    const { materialOrdersItems, error } = response;
    if(error) return response;

	  dispatch({ type: TO_BE_ORDER_LIST, materialOrdersItemsByBranch: { materialOrdersItems, branchId: request.branchId} || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const updateMaterialOrder = (docId, orderData) => dispatch => {
  return fromApi.updateMaterialOrder(docId, orderData).then(response => {
    const { materialOrderResponse, error } = response;
    if(error) return response;
    
    const { materialOrder } = materialOrderResponse;
    dispatch({ type: FETCH_MATERIAL_ORDER, materialOrder: { ...materialOrder } });
    
    return response;
  }).catch(error => {
    return { error };
  });
}

export const sendMaterialOrder = (docId, orderData) => dispatch => {
  return fromApi.sendMaterialOrder(docId, orderData).then(response => {
    const { materialOrderResponse, error } = response;
    if(error) return response;
    
    const { materialOrder } = materialOrderResponse;
    dispatch({ type: FETCH_MATERIAL_ORDER, materialOrder:  { ...materialOrder } });
    
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deliverMaterialOrder = (docId, orderData) => dispatch => {
  return fromApi.deliverMaterialOrder(docId, orderData).then(response => {
    const { materialOrderResponse, error } = response;
    if(error) return response;
    
    const { materialOrder } = materialOrderResponse;
    dispatch({ type: FETCH_MATERIAL_ORDER, materialOrder:  { ...materialOrder } });
    
    return response;
  }).catch(error => {
    return { error };
  });
}

export const completeMaterialOrder = (docId, orderData) => dispatch => {
  return fromApi.completeMaterialOrder(docId, orderData).then(response => {
    const { materialOrderResponse, error } = response;
    if(error) return response;
    
    const { materialOrder } = materialOrderResponse;
    dispatch({ type: FETCH_MATERIAL_ORDER, materialOrder:  { ...materialOrder } });
    
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchOrdersAssignList = query => dispatch => {
    return fromApi.orderAssignListSearch(query).then(response => {
        dispatch({ type: FETCH_MATERIAL_ORDERS, materialOrders: response.materialOrders || [] });
        return response;
    }).catch(error => {
        return { error };
    });
}

export const assignOrderItems = data => dispatch => {
    return fromApi.assignOrderItems(data).then(response => {
        return response;
    }).catch(error => {
        return { error };
    });
}

export const confirmOrderAssignItemsStatus = data => dispatch => {
    return fromApi.confirmOrderAssignItemsStatus(data).then(response => {
        return response;
    }).catch(error => {
        return { error };
    });
}