import * as fromApi from '../api/bankAccount';

export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';

export const fetchBankAccounts = () => dispatch => {
    return fromApi.fetchBankAccounts().then(response => {
      dispatch({ type: FETCH_BANK_ACCOUNTS, bankAccounts: response.bankAccounts || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const addBankAccount = bankAccount => dispatch => {
    return fromApi.addBankAccount(bankAccount).then(response => {
        const { error } = response;
        if(error) return response;
        
	  dispatch({ type: ADD_BANK_ACCOUNT, bankAccount: response.bankAccount || [] });
	  return response;
    }).catch(error => {
	  return error;
    })
}

export const updateBankAccount = (docId, request) => dispatch => {
    return fromApi.updateBankAccount(docId, request).then(response => {
      const { error } = response;
      if(error) return response;
  
      dispatch({ type: UPDATE_BANK_ACCOUNT, bankAccount: response.bankAccount || [] });
      return response;
    }).catch(error => {
      return { error };
    });
  }
  
  export const deleteBankAccount = (docId, request) => dispatch => {
    return fromApi.deleteBankAccount(docId, request).then(response => {
        const { error } = response;
        if(error) return response;
  
      dispatch({ type: DELETE_BANK_ACCOUNT, bankAccount: response.bankAccount });
      return response;
    }).catch(error => {
      return { error };
    });
  }