import Api from './const';
import fetch from './commonFetch'

const { getAll, add, update, remove } = Api.companies;

export const getCompanies = () => {
    const { url, method, headers } = getAll;

    return fetch(url(), { method, headers }, "companies")
}

export const addCompany = company => {
    const { url, method, headers } = add;
    const body = JSON.stringify(company);

    return fetch(url(), { method, headers, body }, "company")
}

export const updateCompany = (docId, company) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(company);

    return fetch(url(docId), { method, headers, body }, "company")
}

export const deleteCompany = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'company')
}