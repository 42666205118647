import Api from './const';
import fetch from './commonFetch'


const { getAll, get, add, update, remove, activate, deactivate } = Api.partners;

export const getPartners = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'partners');
}

export const getPartner = id => {
    const { url, method, headers } = get;

    return fetch(url(id), { method, headers }, "partner")
}

export const addPartner = partner => {
    const { url, method, headers } = add;
    const body = JSON.stringify(partner);

    return fetch(url(), { method, headers, body }, "partner")
}

export const updatePartner = (docId, partner) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(partner);

    return fetch(url(docId), { method, headers, body }, "partner")
}

export const deletePartner = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'partner')
}

export const activatePartner = id => {
    const { url, method, headers } = activate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "partner")
}

export const deactivatePartner = id => {
    const { url, method, headers } = deactivate;
    const body = JSON.stringify({});
    return fetch(url(id), { method, headers, body }, "partner")
}
