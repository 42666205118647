import Api from './const';
import fetch from './commonFetch'

const { getPublishCourses, getAll, add, update, remove, publishCourses, unpublishCourses, updateType, unAssignItem, assignType, setFeatured, setFeaturedBranchCourse} = Api.courses;

export const getPublishCourseList = branchId => {
  const { url, method, headers } = getPublishCourses;
  return fetch(url(branchId), { method, headers }, "publishCourses")
}

export const getCourses = () => {
  const { url, method, headers } = getAll;
  return fetch(url(), { method, headers }, "courses")
}

export const addCourse = course => {
  const { url, method, headers } = add;
  const body = JSON.stringify(course);

  return fetch(url(), { method, headers, body }, "course")
}

export const updateCourse = (docId, course) => {
  const { url, method, headers } = update;
  const body = JSON.stringify(course);

  return fetch(url(docId), { method, headers, body }, "course")
}

export const deleteCourse = (docId, deleteRequest) => {
  const { url, method, headers } = remove;
  const body = JSON.stringify(deleteRequest);
  return fetch(url(docId), {method, headers, body}, 'course')
}

export const publish = publishCoursesData => {
  const { url, method, headers } = publishCourses;
  const body = JSON.stringify(publishCoursesData);

  return fetch(url(), { method, headers, body }, "publishCoursesResponse")
}

export const unpublish = unpublishCoursesData => {
  const { url, method, headers } = unpublishCourses;
  const body = JSON.stringify(unpublishCoursesData);

  return fetch(url(), { method, headers, body }, "unpublishCoursesResponse")
}

export const updateCourseType = (docId, courseType) => {
  const { url, method, headers } = updateType;
  const body = JSON.stringify(courseType);

  return fetch(url(docId), { method, headers, body }, "course")
}

export const unAssignCourseItem = (docId, courseItem) => {
  const { url, method, headers } = unAssignItem;
  const body = JSON.stringify(courseItem);

  return fetch(url(docId), { method, headers, body }, "course")
}

export const assignCourseItem = (docId, courseItem) => {
  const { url, method, headers } = assignType;
  const body = JSON.stringify(courseItem);

  return fetch(url(docId), { method, headers, body }, "course")
}

export const setCourseFeatured = (docId, course) => {
  const { url, method, headers } = setFeatured;
  const body = JSON.stringify(course);

  return fetch(url(docId), { method, headers, body }, "course")
}

