import { combineReducers } from 'redux';
import { FETCH_PAYABLE_ITEMS, FETCH_PAYABLE_ITEM, DELETE_PAYABLE_ITEM } from '../actions/payableItems'
import courseLevels from "./courseLevels";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_PAYABLE_ITEMS:
			const newState = {};
			action.payableItems.forEach(payableItem => newState[payableItem._id] = payableItem);
			return newState;
		case FETCH_PAYABLE_ITEM: {
			const { payableItem } = action;
			return { ...state, [payableItem._id]: payableItem };
		}
		case DELETE_PAYABLE_ITEM:
			const { payableItem } = action;
			delete state[payableItem._id]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_PAYABLE_ITEMS:
			return action.payableItems.map(payableItem => payableItem._id);
		case FETCH_PAYABLE_ITEM:
				if (state.includes(action.payableItem._id)) {
					return state
				} else{
					return [ ...state, action.payableItem._id ];
				}
		case DELETE_PAYABLE_ITEM:
			const { payableItem } = action
				return state.filter(statePayableItem => statePayableItem !== payableItem._id);
	  default:
		return state;
    }
}

const payableItems = combineReducers({
    byId,
    ids
});

export default payableItems;

export const getPayableItems = state => {
	const payableItems = state.ids.map(id => state.byId[id]);
	const sortedPayableItems = payableItems.sort(function(a, b){
		if(a.itemname < b.itemname) { return -1; }
		if(a.itemname > b.itemname) { return 1; }
		return 0;
	})
	return sortedPayableItems || [];
};

export const getPayableItemById = (state, itemId) => state.byId[itemId];

export const getPayableItemsByBranchId = (state, branchId) => {
	const payableItems = state.ids.map(id => state.byId[id]);
	return payableItems && payableItems.length > 0 ? payableItems.filter(item => item.branchid === branchId) : []
}

export const getPayableItemByDocId = (state, docId) => {
	const payableItem = state.byId[docId];
	return payableItem || {};
}