import * as fromApi from '../api/courseTypes';

export const FETCH_COURSE_TYPES = 'FETCH_COURSE_TYPES';

export const fetchCourseTypes = () => dispatch => {
  return fromApi.fetchCourseTypes().then(response => {
    dispatch({type: FETCH_COURSE_TYPES, courseTypes: response.courseTypes || []});
    return response;
  }).catch(error => {
    return {error};
  });
}