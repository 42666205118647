import { combineReducers } from 'redux';
import { FETCH_ANNOUNCEMENTS, DELETE_ANNOUNCEMENT, FETCH_ANNOUNCEMENT } from '../actions/announcements';
import {FETCH_PAYABLE_ITEM} from "../actions/payableItems";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_ANNOUNCEMENTS:
			const newState = {};
			action.announcements.forEach(announcement => newState[announcement._id] = announcement);
			return newState;
		case FETCH_ANNOUNCEMENT:
			return { ...state, [action.announcement._id]: action.announcement };
		case DELETE_ANNOUNCEMENT:
			const { announcement } = action;
			delete state[announcement._id]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_ANNOUNCEMENTS:
			return action.announcements.map(announcement => announcement._id);
		case FETCH_ANNOUNCEMENT:
			if (state.includes(action.announcement._id)) {
				return state
			} else{
				return [ ...state, action.announcement._id ];
			}
		case DELETE_ANNOUNCEMENT:
			const { announcement } = action
			return state.filter(stateAnnouncement => stateAnnouncement !== announcement._id);
	  default:
		return state;
    }
}

const announcements = combineReducers({
    byId,
    ids
});

export default announcements;

export const getAnnouncements = state => state.ids.map(id => state.byId[id]);

export const getAnnouncementById = (state, _id) => state.byId[_id];

export const getAnnouncementByDocId = (state, docId) => state.ids.map(id => state.byId[id]).find(announcement => announcement._id === docId);