import Api from './const';
import fetch from './commonFetch'

const { add, getAll, update, remove } = Api.actions;

export const addAction = roleAction => {
    const { url, method, headers } = add;
    const body = JSON.stringify(roleAction);
    return fetch(url(), {method, headers, body}, 'action')
}

export const updateAction = (docId, roleAction) => {
    const { url, method, headers} = update;
    const body = JSON.stringify(roleAction);
    return fetch(url(docId), {method, headers, body}, 'action')
}

export const fetchActions = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'actions')
}

export const deleteAction = docId => {
    const { url, method, headers } = remove;
    return fetch(url(docId), {method, headers}, 'action')
}