import * as fromApi from '../api/departments';

export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS';
export const FETCH_DEPARTMENT = 'FETCH_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';

export const fetchDepartments = () => dispatch => {
  return fromApi.getDepartments().then(response => {
    dispatch({ type: FETCH_DEPARTMENTS, departments: response.departments || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addDepartment = department => dispatch => {
  return fromApi.addDepartment(department).then(response => {
    const { error, department } = response;
    if(error) return {error};

    dispatch({ type: FETCH_DEPARTMENT, department: department || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateDepartment = (docId, department) => dispatch => {
  return fromApi.updateDepartment(docId, department).then(response => {
    const { error, department } = response;
    if(error) return {error};

    dispatch({ type: FETCH_DEPARTMENT, department: department || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteDepartment = (docId, department) => dispatch => {
  return fromApi.deleteDepartment(docId, department).then(response => {
    const {error, department} = response;
    if (error) return {error};
    dispatch({type: DELETE_DEPARTMENT, department: department || []});
    return response;
  }).catch(error => {
    return {error};
  });
}
