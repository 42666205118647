import * as fromApi from '../api/achievements';

export const FETCH_ACHIEVEMENTS = 'FETCH_ACHIEVEMENTS';
export const FETCH_ACHIEVEMENT = 'FETCH_ACHIEVEMENT';
export const DELETE_ACHIEVEMENT = 'DELETE_ACHIEVEMENT';

export const fetchAchievements = () => dispatch => {
    return fromApi.getAchievements().then(response => {
      dispatch({ type: FETCH_ACHIEVEMENTS, achievements: response.achievements || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchAchievement = (id) => dispatch => {
  return fromApi.getAchievement(id).then(response => {
    dispatch({ type: FETCH_ACHIEVEMENT, achievement: response.achievement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const addAchievement = achievement => dispatch => {
  return fromApi.addAchievement(achievement).then(response => {
    const {error, achievement} = response;
    if (error) return {error};

    dispatch({type: FETCH_ACHIEVEMENT, achievement: achievement || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateAchievement = (docId, achievement) => dispatch => {
  return fromApi.updateAchievement(docId, achievement).then(response => {
    const { error, achievement } = response;
    if(error) return {error};

    dispatch({ type: FETCH_ACHIEVEMENT, achievement: achievement || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteAchievement = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteAchievement(docId, deleteRequest).then(response => {
    const {error, achievement} = response;
    if (error) return {error};
    dispatch({type: DELETE_ACHIEVEMENT, achievement: achievement || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

export const activateAchievement = (id) => dispatch => {
  return fromApi.activateAchievement(id).then(response => {
    dispatch({ type: FETCH_ACHIEVEMENT, achievement: response.achievement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const deactivateAchievement = (id) => dispatch => {
  return fromApi.deactivateAchievement(id).then(response => {
    dispatch({ type: FETCH_ACHIEVEMENT, achievement: response.achievement || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}