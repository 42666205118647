import * as fromApi from '../api/waveoff';
import {UPDATE_INVOICES, FETCH_UPDATED_INVOICES_BY_STUDENT_ID, FETCH_INVOICES} from './invoices'
import { UPDATE_STUDENTS } from './students'

export const ADD_WAVE_OFF = 'ADD_WAVE_OFF';
export const FETCH_WAVE_OFF_DATA_LIST = 'FETCH_WAVE_OFF_DATA_LIST';
export const FETCH_WAVE_OFF_DATA = 'FETCH_WAVE_OFF_DATA';
export const FETCH_WAVE_OFF_DATA_BY_CUSTOMER = 'FETCH_WAVE_OFF_DATA_BY_CUSTOMER';

export const fetchWaveOffInvoices = query => dispatch => {
    return fromApi.waveOffAdvanceSearch(query).then(response => {
        dispatch({ type: FETCH_WAVE_OFF_DATA_LIST, waveOffDataList: response.waveOffList || [] });
        return response;
    }).catch(error => {
        return { error };
    });
}

export const addWaveOff = waveOffInvoiceData => dispatch => {
    return fromApi.addWaveOff(waveOffInvoiceData).then(response => {
      if (response.error) return response;
      
      const { invoices, students, waveOffDetails } = response.waveOffInvoiceResponse;
      dispatch({ type: FETCH_WAVE_OFF_DATA, waveOffList: waveOffDetails || [] });
      dispatch({ type: FETCH_UPDATED_INVOICES_BY_STUDENT_ID, invoices: invoices || [] });
      dispatch({ type: UPDATE_INVOICES, invoices: invoices || [] });
      dispatch({ type: UPDATE_STUDENTS, students: students || [] });
      return response;
    }).catch(error => {
      return { error };
    });
  }

  export const fetchWaveOffReceiptsById = waveOffId => dispatch => {
    return fromApi.getWaveOffReceiptsById(waveOffId).then(response => {
    dispatch({ type: FETCH_WAVE_OFF_DATA, waveOffList: response.waveOffDetails || [] });
    return response;
    }).catch(error => {
    return { error };
    });
  }

export const fetchWaveOffReceiptsByCustomerId = customerId => dispatch => {
    return fromApi.getWaveOffReceiptsByCustomerId(customerId).then(response => {
        dispatch({ type: FETCH_WAVE_OFF_DATA_BY_CUSTOMER, waveOffList: response.waveOffDetails || [] });
        return response;
    }).catch(error => {
        return { error };
    });
}