import { combineReducers } from 'redux';
import {
  FETCH_INVOICES,
  UPDATE_INVOICES,
  FETCH_INVOICES_BY_STUDENT_ID,
  FETCH_UPDATED_INVOICES_BY_STUDENT_ID,
  DELETE_INVOICES_BY_INVOICE_ID,
  FETCH_DATA_FOR_CREATE_ORDER,
  FETCH_INVOICES_BY_STUDENT_DOC_ID,
  FETCH_INVOICES_BY_RECEIPT_ID
} from '../actions/invoices';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES: {
      const newState = {};
      action.invoices.forEach(invoice => newState[invoice.invoiceid] = invoice);
      return newState;
    }
    case UPDATE_INVOICES:
      const { invoices } = action;
      const updatedInvoices = {}

      invoices.forEach(invoice => updatedInvoices[invoice.invoiceid] = invoice);
      return { ...state, ...updatedInvoices }
    case DELETE_INVOICES_BY_INVOICE_ID:
      const { deletedInvoiceIds } = action.deletedInvoiceResponse.deletedInvoiceIds;
      deletedInvoiceIds.forEach(invoiceId => delete state[invoiceId])
      return state;
    case FETCH_INVOICES_BY_STUDENT_DOC_ID:
      action.invoices.forEach(invoice => state[invoice.invoiceid] = invoice);
      return state
    case FETCH_INVOICES_BY_RECEIPT_ID: {
      const newState = { ...state }
      action.invoices.forEach(invoice => newState[invoice.invoiceid] = invoice);
      return newState
    }
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return action.invoices.map(invoice => invoice.invoiceid);
    case UPDATE_INVOICES:
      return [...state, ...action.invoices.map(invoice => invoice.invoiceid)];
    case DELETE_INVOICES_BY_INVOICE_ID:
      const { deletedInvoiceIds } = action.deletedInvoiceResponse.deletedInvoiceIds;
      const currentinvoices = state;
      const filteredInvoices = currentinvoices.filter(invoiceid => !deletedInvoiceIds.includes(invoiceid));
      return filteredInvoices;
    case FETCH_INVOICES_BY_RECEIPT_ID: {
      const newState = [ ...state ]
      action.invoices.forEach(invoice => {
        if (!newState.includes(invoice.invoiceid)) {
          newState.push(invoice.invoiceid);
        }
      });
      
      return newState;
    }
    default:
      return state;

  }
}

export const invoicesByBranch = (state = [], action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      const newState = {};
      action.invoices.map(invoice => {
        const invoiceIds = newState[invoice.branchid] || [];
        invoiceIds.push(invoice.invoiceid);
        newState[invoice.branchid] = invoiceIds;
      }
      )
      return newState
    default:
      return state;
  }
}

export const invoiceListByCustomer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES_BY_STUDENT_ID:
      const newState = {};
      action.invoices.forEach(invoice => {
        let array = newState[invoice.customerid] || []
        newState[invoice.customerid] = [...array, invoice];
      });
      return newState;
    case FETCH_UPDATED_INVOICES_BY_STUDENT_ID:
      const updatedInvoices = { ...state };
      action.invoices.forEach(invoice => {
        let array = updatedInvoices[invoice.customerid] &&
          updatedInvoices[invoice.customerid].map(invoiceData => {
            if (invoiceData.invoiceid == invoice.invoiceid) return invoice
            else return invoiceData
          }) || []
        updatedInvoices[invoice.customerid] = [...array]
      });
      return { ...updatedInvoices };
    case DELETE_INVOICES_BY_INVOICE_ID:
      const { deletedInvoiceIds } = action.deletedInvoiceResponse.deletedInvoiceIds;
      const customers = { ...state };
      Object.keys(customers).forEach(customerId => customers[customerId] = customers[customerId].filter(invoice => !deletedInvoiceIds.includes(invoice.invoiceid)))
      return customers;
    default:
      return state;
  }
}

export const invoiceIdsByCustomerDocIds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES_BY_STUDENT_DOC_ID:
      const newState = {};
      action.invoices.forEach(invoice => {
        let array = newState[invoice.customerDocumentId] || []
        newState[invoice.customerDocumentId] = [...array, invoice.invoiceid];
      });
      return newState;
    case DELETE_INVOICES_BY_INVOICE_ID:
      const { deletedInvoiceIds } = action.deletedInvoiceResponse.deletedInvoiceIds;
      const customers = { ...state };
      Object.keys(customers).forEach(customerId => customers[customerId] = customers[customerId].filter(id => !deletedInvoiceIds.includes(id)))
      return customers;
    default:
      return state;
  }
}

export const invoiceListByBranchForCreateOrder = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_FOR_CREATE_ORDER:
      const newState = {...state, ...action.data};
      return newState;
    default:
      return state;
  }
}

export const byReceiptIds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES_BY_RECEIPT_ID: {
      const newState = { ...state };
      action.invoices.forEach(invoice => {
        let array = newState[action.receiptId] || []
        newState[action.receiptId] = [...array, invoice.invoiceid];
      });
      return newState;
    }
    case DELETE_INVOICES_BY_INVOICE_ID:
      const { deletedInvoiceIds } = action.deletedInvoiceResponse.deletedInvoiceIds;
      const currentState = { ...state };
      Object.keys(currentState).forEach(receiptId => currentState[receiptId] = currentState[receiptId].filter(id => !deletedInvoiceIds.includes(id)))
      return currentState;
    default:
      return state;
  }
}

const invoices = combineReducers({
  byId,
  ids,
  invoiceListByCustomer,
  invoiceListByBranchForCreateOrder,
  invoicesByBranch,
  invoiceIdsByCustomerDocIds,
  byReceiptIds
});

export default invoices;

export const getInvoices = state => {
  return sortListByInvoiceDateAndId(state.ids.map(id => state.byId[id]));
}

export const getInvoicesByCustomer = (state, studentid) => {
  const { invoiceListByCustomer } = state;

  return sortListByInvoiceDateAndId(invoiceListByCustomer[studentid]);
}

const sortListByInvoiceId = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (i1, i2) {
    let invoiceid_i1 = parseInt(i1.invoiceid);
    let invoiceid_i2 = parseInt(i2.invoiceid);

    if (invoiceid_i1 < invoiceid_i2) return 1;
    if (invoiceid_i1 > invoiceid_i2) return -1
    return 0
  }) : []
  return sortedList;
}

export const getInvoicesByIds = (state, ids) => ids.map(id => state.byId[id]).filter(invoice => !!invoice)

export const isItemAvailableForCreateOrder = (state, branchId, itemType) => {
  const { invoiceListByBranchForCreateOrder } = state;
  const item = invoiceListByBranchForCreateOrder[branchId];

  if (itemType === 'studentCard') return item && item.studentCardCount && item.studentCardCount > 0;
  if (itemType === 'courseMaterial') return item && item.courseMaterialCount && item.courseMaterialCount > 0; 
}

export const getBranchWiseCourseMaterialCardDataList = state => {
  const { invoiceListByBranchForCreateOrder } = state;
  
  return Object.values(invoiceListByBranchForCreateOrder);
}

export const getInvoicesByBranchId = (state, branchId) => {
  const { invoicesByBranch } = state;
  const invoices = [];
  invoicesByBranch && invoicesByBranch[branchId] && invoicesByBranch[branchId].map(id => {
    invoices.push(state.byId[id]);
  })
  return sortListByInvoiceDateAndId(invoices);
}

const sortListByInvoiceDateAndId = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (invoice1, invoice2) {

    let invoice1Id = invoice1.invoiceid;
    let invoice2Id = invoice2.invoiceid;

    if (invoice1Id < invoice2Id) return 1;
    if (invoice1Id > invoice2Id) return -1
    return 0
  }).sort(function (invoice1, invoice2) {
    let invoice1_invoicedate = parseInt(invoice1.invoicedate);
    let invoice2_invoicedate = parseInt(invoice2.invoicedate);

    if (invoice1_invoicedate < invoice2_invoicedate) return 1;
    if (invoice1_invoicedate > invoice2_invoicedate) return -1
    return 0
  }) : []
  return sortedList;
}

export const getInvoicesByCustomerDocId = (state, studentDocId) => {
  const { invoiceIdsByCustomerDocIds, byId } = state;
  const invoiceIdsByCustomer = invoiceIdsByCustomerDocIds[studentDocId]

  const invoiceList = invoiceIdsByCustomer && invoiceIdsByCustomer.length > 0 ? invoiceIdsByCustomerDocIds[studentDocId].map(id => byId[id]).filter(invoice => !!invoice) : []
  return sortListByInvoiceDateAndId(invoiceList);
}

export const getInvoicesByReceiptId = (state, receiptId) => {
  const { byReceiptIds, byId } = state;
  const receiptIds = byReceiptIds[receiptId]
  
  const invoiceList = receiptIds && receiptIds.length > 0 ? receiptIds.map(id => byId[id]).filter(invoice => !!invoice) : []
  return sortListByInvoiceDateAndId(invoiceList);
}

