import { combineReducers } from 'redux';
import {ADD_BANK_ACCOUNT, FETCH_BANK_ACCOUNTS, DELETE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT} from '../actions/bankAccount'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_BANK_ACCOUNTS:
		const newState = {};
		action.bankAccounts.forEach(account => newState[account._id] = account);
		return newState;
	  case ADD_BANK_ACCOUNT:
		return { ...state, [action.bankAccount._id]: action.bankAccount };
	  case UPDATE_BANK_ACCOUNT:
		return { ...state, [action.bankAccount._id]: action.bankAccount };
	  case DELETE_BANK_ACCOUNT:
	      delete state[action.bankAccount._id]
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BANK_ACCOUNTS:
		return action.bankAccounts.map(account => account._id);
	  case ADD_BANK_ACCOUNT:
		return [ ...state, action.bankAccount._id ];
	  case DELETE_BANK_ACCOUNT:
		return state.filter(id => id != action.bankAccount._id);
	  default:
		return state;
    }
}

const bankAccounts = combineReducers({
    byId,
    ids
});

export default bankAccounts;

export const getBankAccounts = state => state.ids.map(id => state.byId[id]);

export const getBankAccountsByBranchId = (state, branchid) => {
	const accounts = state.ids.map(id => state.byId[id]);
	return accounts && accounts.length > 0 && accounts.filter(account => account.branchid === branchid)
}