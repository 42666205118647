import * as fromApi from '../api/students';

export const FETCH_STUDENTS = 'FETCH_STUDENTS';
export const FETCH_STUDENT = 'FETCH_STUDENT';
export const UPDATE_STUDENT_TRIGGER = 'UPDATE_STUDENT_TRIGGER';
export const UPDATE_STUDENT_COMPLETE = 'UPDATE_STUDENT_COMPLETE';
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS';
export const LOAD_STUDENT = 'LOAD_STUDENT';
export const ASSIGN_BATCH = 'ASSIGN_BATCH';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const REMOVE_BATCH = 'REMOVE_BATCH';
export const ADD_STUDENT_CARD = 'ADD_STUDENT_CARD';
export const FETCH_STUDENT_CARDS_BY_STUDENT_ID = 'FETCH_STUDENT_CARDS_BY_STUDENT_ID';
export const UPDATE_STUDENT_CARD = 'UPDATE_STUDENT_CARD';
export const DELETE_STUDENT_CARD = 'DELETE_STUDENT_CARD';

export const fetchStudents = (query) => dispatch => {
  return fromApi.getStudents(query).then(response => {
    const { students, error } = response;
    if (error) return { error }

    dispatch({ type: FETCH_STUDENTS, students: students || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchStudent = studentid => dispatch => {
  return fromApi.getStudent(studentid).then(response => {
    const { student, error } = response;
    if (error) return error

    dispatch({ type: FETCH_STUDENT, student });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addStudent = student => dispatch => {
  return fromApi.addStudent(student).then(response => {
    if(response.error) return {error: response.error};

    dispatch({ type: FETCH_STUDENT, student: response.student || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateStudent = student => dispatch => {
  dispatch({ type: UPDATE_STUDENT_TRIGGER, id: student.studentid });
  return fromApi.updateStudent(student).then(response => {
    if(response.error) return response;

    dispatch({ type: UPDATE_STUDENT_COMPLETE, success: true, student: response.student });
    return response;
  }).catch(error => {
    dispatch({ type: UPDATE_STUDENT_COMPLETE, success: false, student });
    return { error };
  });
}

export const assignBatchToStudent = (studentid, batchid, assignBatchData) => dispatch => {
  return fromApi.assignBatchToStudent(studentid, batchid, assignBatchData).then(response => {
    const courseData = { course: response.course, studentid }
    if(response.course) dispatch({ type: ASSIGN_BATCH, course: courseData || {} });
   
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deletStudent = student => dispatch => {
  return fromApi.deleteStudent(student.studentid).then(response => {
    const { student, error} = response;
    if (error) return response

    dispatch({ type: REMOVE_STUDENT, id: student._id });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const removeBatchFromStudent = request => dispatch => {
  return fromApi.removeBatchFromStudent(request).then(response => {
    const { deleteCourseResponse, error} = response;
    if (deleteCourseResponse){
      dispatch({ type: REMOVE_BATCH, deleteCourseResponse});
    }
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addStudentCard = cardData => dispatch => {
  return fromApi.addCard(cardData).then(response => {
    const { addCardResponse, error } = response;
    if(error) return response;
   
    dispatch({ type: ADD_STUDENT_CARD, card: addCardResponse.card });
    dispatch({ type: FETCH_STUDENT, student: addCardResponse.student });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchStudentCardsByStudentId = studentId => dispatch => {
  return fromApi.fetchCardsByStudentId(studentId).then(response => {
    const { cards, error } = response;
    if(error) return response;

    dispatch({ type: FETCH_STUDENT_CARDS_BY_STUDENT_ID, cards: cards });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateCardStatus = (studentid, cardid, request) => dispatch => {
  return fromApi.updateStatus(studentid, cardid, request).then(response => {
    const { addCardResponse, error } = response;
    if(error) return response;

    dispatch({ type: UPDATE_STUDENT_CARD, card: addCardResponse.card });
    dispatch({ type: FETCH_STUDENT, student: addCardResponse.student });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteCard = (studentid, cardid, request) => dispatch => {
  return fromApi.deleteCard(studentid, cardid, request).then(response => {
    const { deleteCardResponse, error } = response;
    if(error) return response;

    dispatch({ type: DELETE_STUDENT_CARD, card: deleteCardResponse.card });
    dispatch({ type: FETCH_STUDENT, student: deleteCardResponse.student });
    return response;
  }).catch(error => {
    return { error };
  });
}