import Api from './const';
import fetch from './commonFetch'

const { getAll, add, update, remove } = Api.departments;

export const getDepartments = () => {
  const { url, method, headers } = getAll;
  return fetch(url(), { method, headers }, "departments")
}

export const addDepartment = department => {
  const { url, method, headers } = add;
  const body = JSON.stringify(department);

  return fetch(url(), { method, headers, body }, "department")
}

export const updateDepartment = (docId, department) => {
  const { url, method, headers } = update;
  const body = JSON.stringify(department);

  return fetch(url(docId), { method, headers, body }, "department")
}

export const deleteDepartment = (docId, department) => {
  const { url, method, headers } = remove;
  const body = JSON.stringify(department);
  return fetch(url(docId), {method, headers, body}, 'department')
}

