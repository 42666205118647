import * as fromApi from '../api/branchCourseDetails';
import {FETCH_COURSE} from "./courses";

export const FETCH_BRANCH_COURSE_DETAILS = 'FETCH_BRANCH_COURSE_DETAILS';

export const fetchBranchCourseDetails = (page, pageSize) => dispatch => {
    return fromApi.getBranchCourseDetails(page, pageSize).then(response => {
	  dispatch({ type: FETCH_BRANCH_COURSE_DETAILS, branchCourseDetails: response.branchCourseDetails || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const updateBranchCourseFeatured = (request) => dispatch => {
  return fromApi.setBranchCourseFeatured(request).then(response => {
    const { error, course } = response;
    if(error) return {error};

    return response;
  }).catch(error => {
    return { error };
  });
}