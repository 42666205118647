import Api from './const';
import fetch from './commonFetch'

const { getTransactionSummery, getTransactionUserSummery } = Api.dashboard;

export const getDashboardTransactionSummery = query => {
	const { url, method, headers } = getTransactionSummery;
	const body = JSON.stringify(query);

	return fetch(url(), { method, headers, body }, "transactionSummery")
}

export const getDashboardTransactionUserSummery = query => {
	const { url, method, headers } = getTransactionUserSummery;
	const body = JSON.stringify(query);

	return fetch(url(), { method, headers, body }, "transactionUserSummery")
}