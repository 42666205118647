import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/user3.png';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {logout} from "../../actions/auth";
import { getAuthenticity } from "../../reducers";

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
      const {user} = this.props
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {/*<a className="isoDropdownLink" href="# ">*/}
        {/*  <IntlMessages id="themeSwitcher.settings" />*/}
        {/*</a>*/}
        {/*<a className="isoDropdownLink" href="# ">*/}
        {/*  <IntlMessages id="sidebar.feedback" />*/}
        {/*</a>*/}
        {/*<a className="isoDropdownLink" href="# ">*/}
        {/*  <IntlMessages id="topbar.help" />*/}
        {/*</a>*/}
        <a className="isoDropdownLink" onClick={() => this.props.logout(this.props.authenticity)} href={"/"}>
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper" style={{width: user ? 160 : 40, justifyContent: 'space-between'}}>
            {user && user.profile.firstName + ' ' + user.profile.lastName}
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}

const mapStateToProps = state => {
  return { authenticity: getAuthenticity(state) }
}

export default connect(
  mapStateToProps,
  { logout }
)(TopbarUser);
