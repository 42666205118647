import Api from './const';
import authenticFetch from './commonFetch';

const {authenticate, logout} = Api.auth

export const auth = user => {
    const { url, method, headers } = authenticate;
    const body = JSON.stringify(user);

    return fetch(url(), { method, headers, body })
	  .then(response => response.json())
	  .then(responseJson => {
		if (responseJson.success) {
		    return { authenticity: responseJson.body || [] };
		} else {
		    throw responseJson.body;
		}
	  });
}

export const updateLogout = authenticity => {
	const { url, method, headers } = logout;
	const body = JSON.stringify(authenticity);

	return authenticFetch(url(), {method, headers, body}, 'logoutStatus')
}