import * as fromApi from '../api/partners';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_PARTNER = 'FETCH_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';

export const fetchPartners = () => dispatch => {
    return fromApi.getPartners().then(response => {
      dispatch({ type: FETCH_PARTNERS, partners: response.partners || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchPartner = (id) => dispatch => {
  return fromApi.getPartner(id).then(response => {
    dispatch({ type: FETCH_PARTNER, partner: response.partner || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const addPartner = partner => dispatch => {
  return fromApi.addPartner(partner).then(response => {
    const {error, partner} = response;
    if (error) return {error};

    dispatch({type: FETCH_PARTNER, partner: partner || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updatePartner = (docId, partner) => dispatch => {
  return fromApi.updatePartner(docId, partner).then(response => {
    const { error, partner } = response;
    if(error) return {error};

    dispatch({ type: FETCH_PARTNER, partner: partner || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deletePartner = (docId, deleteRequest) => dispatch => {
  return fromApi.deletePartner(docId, deleteRequest).then(response => {
    const {error, partner} = response;
    if (error) return {error};
    dispatch({type: DELETE_PARTNER, partner: partner || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

export const activatePartner = (id) => dispatch => {
  return fromApi.activatePartner(id).then(response => {
    dispatch({ type: FETCH_PARTNER, partner: response.partner || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const deactivatePartner = (id) => dispatch => {
  return fromApi.deactivatePartner(id).then(response => {
    dispatch({ type: FETCH_PARTNER, partner: response.partner || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}