import { combineReducers } from 'redux';
import { FETCH_RECEIPTS, FETCH_RECEIPT, MAKE_RECEIPTS_BY_STUDENT_ID, PRINT_RECEIPTS_BY_ID, DELETE_RECEIPTS, FETCH_RECEIPTS_NOT_BANKED } from '../actions/receipts';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECEIPTS:
      const newState = {};
      action.receipts.forEach(receipt => newState[receipt._id] = { ...receipt, key: receipt._id });
      return newState;
    case FETCH_RECEIPT:
      const { receipt } = action;
      return { ...state, [receipt._id]: { ...receipt, key: receipt._id } }
    case DELETE_RECEIPTS:
      const { receipts } = action;
      receipts.forEach(receipt => delete state[receipt._id]);
      return { ...state }
    case FETCH_RECEIPTS_NOT_BANKED:
      action.receipts.forEach(receipt => state[receipt._id] = { ...receipt, key: receipt._id });
      return state
    case 'FETCH_DEPOSIT_RECEIPTS': {
      const newState = { ...state };
      action.receipts.forEach(receipt => newState[receipt._id] = { ...receipt, key: receipt._id });
      return newState;
    }
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_RECEIPTS:
      return action.receipts.map(receipt => receipt._id);
    case FETCH_RECEIPT:
      return [...state, action.receipt._id];
    case DELETE_RECEIPTS:
      const { receipts } = action;
      const deletedIds = receipts.map(receipt => receipt._id);
      return state.filter(id => !deletedIds.includes(id))
    case 'FETCH_DEPOSIT_RECEIPTS': {
      const newState = [...state];
      action.receipts.forEach(receipt => {
        if (!newState.includes(receipt._id)) {
          newState.push(receipt._id)

          return newState;
        }
      })

      return state;
    }
    default:
      return state;

  }
}

export const byReceiptId = (state = [], action) => {
  switch (action.type) {
    case FETCH_RECEIPTS: {
      const newState = {};
      action.receipts.forEach(receipt => newState[receipt.receiptid] = { ...receipt, key: receipt.receiptid });
      return newState;
    }
    case FETCH_RECEIPT:
      const { receipt } = action;
      return { ...state, [receipt.receiptid]: { ...receipt, key: receipt.receiptid } }
    case DELETE_RECEIPTS:
      const { receipts } = action;
      receipts.forEach(receipt => delete state[receipt.receiptid]);
      return { ...state }
    case 'FETCH_DEPOSIT_RECEIPTS': {
      const newState = { ...state };
      action.receipts.forEach(receipt => newState[receipt.receiptid] = { ...receipt, key: receipt.receiptid });
      return newState;
    }
    default:
      return state;
  }
}

export const byBranchId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECEIPTS: {
      const newState = {};
      action.receipts.forEach(receipt => {
        const ids = newState[receipt.branchid] || [];
        ids.push(receipt._id);
        newState[receipt.branchid] = ids;
      });
      return newState;
    }
    case FETCH_RECEIPT:
      const { _id, branchid } = action.receipt;
      Object.keys(state).forEach(key => {
        state[key] = state[key].filter(id => id !== _id);
      });

      const ids = state[branchid] || [];
      ids.push(_id);
      state[branchid] = ids;

      return { ...state };

    case DELETE_RECEIPTS:
      const { receipts } = action;
      receipts.forEach(receipt => {
        state[receipt.branchid] = state[receipt.branchid].filter(id => id !== receipt._id);
      });
      return { ...state };
    case 'FETCH_DEPOSIT_RECEIPTS': {
      const newState = { ...state };
      action.receipts.forEach(receipt => {
        const ids = newState[receipt.branchid] || [];
        ids.push(receipt._id);
        newState[receipt.branchid] = ids;
      });
      return newState;
    }
    default:
      return state;
  }
}

export const receiptsListByCustomer = (state = [], action) => {
  switch (action.type) {
    case MAKE_RECEIPTS_BY_STUDENT_ID:
      const newState = {};
      action.receipts.forEach(receipt => {
        let array = newState[receipt.customerid] || []
        let invoices = receipt && receipt.invoices;
        newState[receipt.customerid] = [...array, { ...receipt, invoices }];
      });
      return newState;
    case DELETE_RECEIPTS:
      const { receipts } = action;
      let updatedState = {}
      receipts.forEach(receipt => {
        const customerReceipts = state[receipt.customerid];
        const filteredRemoveReceipts = customerReceipts && customerReceipts.filter(customerReceipt => customerReceipt._id != receipt._id)
        updatedState = { ...state, [receipt.customerid]: filteredRemoveReceipts }
      });
      return updatedState;
    default:
      return state;
  }
}

export const printReceiptsListById = (state = [], action) => {
  switch (action.type) {
    case PRINT_RECEIPTS_BY_ID:
      const newState = {};
      action.receipts.forEach(receipt => {
        let invoices = receipt && receipt.invoices.map(invoice => {
          const invoiceId = invoice.invoiceid;
          const invoiceType = invoice.invoicetype;
          const details = invoice.details;
          return { invoiceId, invoiceType, details };
        })
        newState[receipt.receiptid] = [{ ...receipt, invoices }];
      });
      return newState;
    case DELETE_RECEIPTS:
      const { receipts } = action;
      receipts.forEach(receipt => delete state[receipt.receiptid]);
      return { ...state }
    default:
      return state;
  }
}

export const notBankedIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_RECEIPTS_NOT_BANKED:
      return action.receipts.map(receipt => receipt._id);
    case FETCH_RECEIPT:
      if (action.receipt && action.receipt.receiptstatus !== 'banked')
        return [...state, action.receipt._id];
    case DELETE_RECEIPTS:
      const { receipts } = action;
      const deletedIds = receipts.map(receipt => receipt._id);
      return state.filter(id => !deletedIds.includes(id))
    default:
      return state;
  }
}


const receipts = combineReducers({
  byId,
  ids,
  receiptsListByCustomer,
  printReceiptsListById,
  byReceiptId,
  byBranchId,
  notBankedIds
});

export default receipts;

export const getReceipts = state => sortListByReceiptDate(state.ids.map(id => state.byId[id]));

export const getReceiptsByCustomer = (state, studentid) => {
  const { receiptsListByCustomer } = state;

  return sortListByReceiptDate(receiptsListByCustomer[studentid]);
}

export const getPrintReceiptsById = (state, receiptId) => {
  const { printReceiptsListById } = state;

  return printReceiptsListById[receiptId];
}

const sortListByReceiptDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (receipt1, receipt2) {
    let receipt1_receiptdate = parseInt(receipt1.receiptdate);
    let receipt2_receiptdate = parseInt(receipt2.receiptdate);

    if (receipt1_receiptdate < receipt2_receiptdate) return 1;
    if (receipt1_receiptdate > receipt2_receiptdate) return -1
    return 0
  }) : []
  return sortedList;
}

export const getReceiptsByIds = (state, receiptIds) => {
  const { byReceiptId } = state;
  return receiptIds && receiptIds.length > 0 && receiptIds.map(receiptId => byReceiptId[receiptId]).filter(receipt => !!receipt)
}

export const getNotBankedReceiptsByStatus = (state, status) => {
  const notBankedReceipts = state.notBankedIds.map(id => state.byId[id]).filter(receipt => !!receipt).filter(receipt => receipt.receiptstatus === status).filter(receipt => !!receipt)

  return notBankedReceipts;
}

export const getReceiptsByStatus = (state, branchid, status) => {
  const receipts = state.ids.map(id => state.byId[id]).filter(receipt => !!receipt);
  return receipts && receipts.length > 0 && receipts.filter(receipt => receipt.branchid === branchid && receipt.receiptstatus === status)
}

export const getByBranchId = (state, branchId) => {
  return sortListByReceiptDate((state.byBranchId[branchId] || []).map(id => state.byId[id]));
}