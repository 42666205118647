import Api from './const';
import fetch from './commonFetch'

const { getAll, advanceSearch, get, add, update, getDetailBatch, deleteByIds, activeBatchData, updateBatchStatus } = Api.batches;

export const getBatches = (page, pageSize) => {
    const { url, method, headers } = getAll;

    return fetch(url(page, pageSize), { method, headers }, "batches")
}

export const batchAdvanceSearch = query => {
	const { url, method, headers } = advanceSearch;
	const body = JSON.stringify(query);

	return fetch(url(), { method, headers, body }, "batches")
}

export const getBatch = batchid => {
    const { url, method, headers } = get;

    return fetch(url(batchid), { method, headers }, "batch")
}

export const addBatch = batch => {
    const { url, method, headers } = add;
    const body = JSON.stringify(batch);

    return fetch(url(), { method, headers, body }, "batch")
}

export const updateBatch = (docId, batch) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(batch);
    return fetch(url(docId), { method, headers, body }, "batch")
}

export const getDetailBatchData = docId => {
    const { url, method, headers } = getDetailBatch;

    return fetch(url(docId), { method, headers }, "detailBatchData")
}

export const deleteBatchesByIds = deleteRequest => {
    const { url, method, headers } = deleteByIds;
    const body = JSON.stringify(deleteRequest);

    return fetch(url(), {method, headers, body}, "deletedResponse")
}

export const getActiveBatchData = () => {
    const { url, method, headers } = activeBatchData;

    return fetch(url(), {method, headers}, "activeBatchData")
}

export const changeBatchStatus = (batches) => {
    const { url, method, headers } = updateBatchStatus;
    const body = JSON.stringify(batches);
    return fetch(url(), { method, headers, body }, "updateBatchStatusResponse")
}
