import { combineReducers } from 'redux';
import { FETCH_BRANCHES, DELETE_BRANCH, FETCH_BRANCH } from '../actions/branches';
import {FETCH_PAYABLE_ITEM} from "../actions/payableItems";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_BRANCHES:
			const newState = {};
			action.branches.forEach(branch => newState[branch.branchid] = branch);
			return newState;
		case FETCH_BRANCH:
			return { ...state, [action.branch.branchid]: action.branch };
		case DELETE_BRANCH:
			const { branch } = action;
			delete state[branch.branchid]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BRANCHES:
			return action.branches.map(branch => branch.branchid);
		case FETCH_BRANCH:
			if (state.includes(action.branch.branchid)) {
				return state
			} else{
				return [ ...state, action.branch.branchid ];
			}
		case DELETE_BRANCH:
			const { branch } = action
			return state.filter(stateBranch => stateBranch !== branch.branchid);
	  default:
		return state;
    }
}

const branches = combineReducers({
    byId,
    ids
});

export default branches;

export const getBranches = state => state.ids.map(id => state.byId[id]);

export const getBranchById = (state, branchid) => state.byId[branchid];

export const getBranchByDocId = (state, docId) => state.ids.map(id => state.byId[id]).find(branch => branch._id === docId);

export const getBranchesByCompanyDocId = (state, companyDocId) => {
	const branchesByCompany = state.ids && state.ids.map(id => state.byId[id]).filter(branch => branch.companyid === companyDocId);
	return branchesByCompany;
}

export const getHeadOfficeBranch = state => {
	const branches = state.ids.map(id => state.byId[id])
	
	return branches && branches.length > 0 && branches.find(branch => branch.isheadoffice)
}