import { combineReducers } from 'redux';
import { FETCH_TESTIMONIALS, DELETE_TESTIMONIAL, FETCH_TESTIMONIAL } from '../actions/testimonials';
import {FETCH_PAYABLE_ITEM} from "../actions/payableItems";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_TESTIMONIALS:
			const newState = {};
			action.testimonials.forEach(testimonial => newState[testimonial._id] = testimonial);
			return newState;
		case FETCH_TESTIMONIAL:
			return { ...state, [action.testimonial._id]: action.testimonial };
		case DELETE_TESTIMONIAL:
			const { testimonial } = action;
			delete state[testimonial._id]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_TESTIMONIALS:
			return action.testimonials.map(testimonial => testimonial._id);
		case FETCH_TESTIMONIAL:
			if (state.includes(action.testimonial._id)) {
				return state
			} else{
				return [ ...state, action.testimonial._id ];
			}
		case DELETE_TESTIMONIAL:
			const { testimonial } = action
			return state.filter(stateTestimonial => stateTestimonial !== testimonial._id);
	  default:
		return state;
    }
}

const testimonials = combineReducers({
    byId,
    ids
});

export default testimonials;

export const getTestimonials = state => state.ids.map(id => state.byId[id]);

export const getTestimonialById = (state, _id) => state.byId[_id];

export const getTestimonialByDocId = (state, docId) => state.ids.map(id => state.byId[id]).find(testimonial => testimonial._id === docId);