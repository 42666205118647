import Api from './const';
import fetch from './commonFetch'

const { advanceSearch, get, add, update, complete, reconcile, remove } = Api.bankDeposits;

export const bankDepositAdvanceSearch = query => {
    const {url, method, headers} = advanceSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'bankDeposits');
}

export const getBankDeposit = documentId => {
    const { url, method, headers } = get;
    return fetch(url(documentId), { method, headers }, "bankDepositData")
}

export const addBankDeposit = (bankDeposit) => {
    const { url, method, headers } = add;
    const body = JSON.stringify(bankDeposit);

    return fetch(url(), { method, headers, body }, "bankDeposit")
}

export const updateBankDeposit = (depositId, bankDeposit) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(bankDeposit);

    return fetch(url(depositId), { method, headers, body }, "bankDeposit")
}

export const completeBankDeposit = (documentId, request) => {
    const { url, method, headers } = complete;
    const body = JSON.stringify(request);
    return fetch(url(documentId), { method, headers, body }, "completeResponse")
}

export const reconcileBankDeposit = (documentId, request) => {
    const { url, method, headers } = reconcile;
    const body = JSON.stringify(request);
    return fetch(url(documentId), { method, headers, body }, "reconcileResponse")
}

export const deleteBankDeposit = (documentId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(documentId), { method, headers, body }, "deleteResponse")
}


