import Api from './const';
import fetch from './commonFetch'

const {getAll, get, add, update, remove, assignBatch, getReceiptsByCustomerId, getPrintReceiptById, advanceSearch, getReceiptsByBranchId, getReceiptsByIds, notBankedReceiptsByBranchId } = Api.receipts;

export const addReceipt = settleInvoiceData => {
    const {url, method, headers} = add;
    const body = JSON.stringify(settleInvoiceData);

    return fetch(url(), {method, headers, body}, "settleInvoiceResponse")
}

export const getReceipts = () => {
    const {url, method, headers} = getAll;

    return fetch(url(), {method, headers}, "receipts")
}

export const getReceipt = receiptId => {
    const {url, method, headers} = get;

    return fetch(url(receiptId), {method, headers}, "receipt")
}

export const getReceiptsByCustomer = studentId => {
    const {url, method, headers} = getReceiptsByCustomerId;

    return fetch(url(studentId), {method, headers}, "receipts")
}

export const getPrintReceiptsById = receiptId => {
    const {url, method, headers} = getPrintReceiptById;

    return fetch(url(receiptId), {method, headers}, 'receipts');
}

export const receiptAdvanceSearch = query => {
    const {url, method, headers} = advanceSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'receipts');
}

export const deleteReceipts = receiptIds => {
    const {url, method, headers} = remove;
    const body = JSON.stringify(receiptIds);

    return fetch(url(), {method, headers, body}, 'deleteReceiptResponse');
}

export const getReceiptsByBranch = branchId => {
    const {url, method, headers} = getReceiptsByBranchId;

    return fetch(url(branchId), {method, headers}, "receipts")
}

export const getReceiptsByIdList = query => {
    const {url, method, headers} = getReceiptsByIds;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'receipts');
}

export const getNotBankedReceiptsByBranchId = branchId => {
    const {url, method, headers} = notBankedReceiptsByBranchId;
    
    return fetch(url(branchId), {method, headers}, 'receipts')
}