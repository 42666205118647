import { combineReducers } from 'redux';
import {ADD_ROLE, FETCH_ROLES, DELETE_ROLE, UPDATE_ROLE} from '../actions/roles'


export const byId = (state = {}, roleRes) => {
    const { role } = roleRes;
    switch (roleRes.type) {
	  case ADD_ROLE:
		return { ...state, [role._id]: role };
	  case FETCH_ROLES:
		const newState = {};
		roleRes.roles.forEach(role => newState[role._id] = role);
		return newState;
	  case DELETE_ROLE:
		delete state[role._id]
		return state
	  case UPDATE_ROLE:
		return { ...state, [role._id]: role };
	  default:
		return state;
    }
}

export const ids = (state = [], roleRes) => {
    switch (roleRes.type) {
	  case FETCH_ROLES:
		return roleRes.roles.map(role => role._id);
	  case DELETE_ROLE:
		return state.filter(role => role !== roleRes.role._id);
	  default:
		return state;
    }
}

const roles = combineReducers({
    byId,
    ids
});

export default roles;

export const getRoles = state => {
    return state.ids.map(id => state.byId[id]);
}

export const getRoleByDocId = (state, roleDocId) => state.byId[roleDocId];