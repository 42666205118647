import { combineReducers } from 'redux';
import { FETCH_MATERIAL_ORDERS, DELETE_MATERIAL_ORDER, TO_BE_ORDER_LIST, FETCH_MATERIAL_ORDER, ADD_MATERIAL_ORDER, FETCH_MATERIAL_ORDER_STUDENTS } from '../actions/materialOrders';

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_MATERIAL_ORDERS:
	      const newState = {};
	      action.materialOrders.forEach(materialOrder => newState[materialOrder._id] = { ...materialOrder, key: materialOrder._id});
		  return newState;
		case ADD_MATERIAL_ORDER:
			return { ...state, [action.materialOrder._id]: action.materialOrder };
		case FETCH_MATERIAL_ORDER:
			return { ...state, [action.materialOrder._id]: action.materialOrder };
		case DELETE_MATERIAL_ORDER:
		  const { materialOrder } = action.materialOrder;
		  delete state[materialOrder._id]
		return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_MATERIAL_ORDERS:
		return action.materialOrders.map(materialOrder => materialOrder._id);
	  case ADD_MATERIAL_ORDER:
		return [ ...state, action.materialOrder._id ];
	  case DELETE_MATERIAL_ORDER:
		const { materialOrder } = action.materialOrder;
		return state.filter(id => id != materialOrder._id)
	  default:
		return state;
    }
}

export const orderItemsByBranch = (state = {}, action) => {
    switch (action.type) {
	  case TO_BE_ORDER_LIST:
		return { ...state, [action.materialOrdersItemsByBranch.branchId] : [...action.materialOrdersItemsByBranch.materialOrdersItems]}
	  default:
		return state;
    }
}

export const studentListByOrderId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_MATERIAL_ORDER_STUDENTS:
		return { ...state, [action.materialOrderStudentList.orderId]: {...action.materialOrderStudentList} }
	  default:
		return state;
    }
}

const materialOrders = combineReducers({
	ids,
	byId,
	orderItemsByBranch,
	studentListByOrderId
});

export default materialOrders;

export const getMaterialOrders = state =>  sortListByOrderedDate(state.ids.map(id => state.byId[id]));

const sortListByOrderedDate = list => {
	const sortedList =  list && list.length > 0 ? list.sort(function (materialOrder1, materialOrder2) {
		let materialOrder1_materialorderdate = parseInt(materialOrder1.materialorderdate);
		let materialOrder2_materialorderdate = parseInt(materialOrder2.materialorderdate); 

    if (materialOrder1_materialorderdate < materialOrder2_materialorderdate) return 1;
    if (materialOrder1_materialorderdate > materialOrder2_materialorderdate) return -1
    return 0
  }) : []
  return sortedList;
}

export const getOrderByOrderId = (state, orderId) => {
	const { byId, ids } = state;
	
	return byId && ids && ids.map(id => byId[id]).find(order => order && order.materialorderid && order.materialorderid === orderId);
}

export const getMaterialOrdersByBranch = (state, branchId) => {
    const { orderItemsByBranch } = state;

    return orderItemsByBranch[branchId];
}

export const getStudentsByOrderId = (state, orderId) => {
	const { studentListByOrderId } = state;

	return studentListByOrderId[orderId];
}