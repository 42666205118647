import * as fromApi from '../api/actions';

export const ADD_ACTION = 'ADD_ACTION';
export const FETCH_ACTIONS = 'FETCH_ACTIONS';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const DELETE_ACTION = 'DELETE_ACTION';

export const addAction = action => dispatch => {
    return fromApi.addAction(action).then(response => {
	  dispatch({ type: ADD_ACTION, action: response.action || [] });
	  return response;
    }).catch(error => {
	  return error;
    })
}

export const updateAction = (docId, action) => dispatch => {
    return fromApi.updateAction(docId, action).then(response => {
	  dispatch({ type: UPDATE_ACTION, action: response.action || [] });
	  return response;
    }).catch(error => {
	  return error;
    })
}

export const fetchActions = () => dispatch => {
    return fromApi.fetchActions().then(response => {
	  dispatch({type: FETCH_ACTIONS, actions: response.actions || []});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const deleteAction = docId => dispatch => {
    return fromApi.deleteAction(docId).then(response => {
	  dispatch({type: DELETE_ACTION, action: response.action || []});
	  return response;
    }).catch(error => {
	  return {error};
    });
}