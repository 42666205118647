import { combineReducers } from 'redux';
import students, * as fromStudents from './students';
import branchCourseDetails, * as fromBranchCourseDetails from './branchCourseDetails'
import batches, * as fromBatches from './batches'
import appData, * as fromAppData from './appData'
import invoices, * as fromInvoices from './invoices'
import users, * as fromUsers from './users'
import receipts, * as fromReceipts from './receipts'
import configPaymentAmounts, * as fromConfigAmounts from './configPaymentAmounts'
import branches, * as fromBranches from './branches'
import companies, * as fromCompanies from './companies'
import waveoffList, * as fromWaveOff from './waveoff'
import dashboard, * as fromDashboard from './dashboard'
import payableItems, * as fromPayableItems from './payableItems'
import auth, * as fromAuth from './auth'
import actions, * as fromActions from './actions'
import roles, * as fromRoles from './roles'
import bankDeposits, * as frombankDeposits from './bankDeposits'
import bankAccounts, * as fromBankAccounts from './bankAccounts'
import banks, * as fromBanks from './banks'
import materialOrders, * as fromMaterialOrders from './materialOrders'
import bonusItems, * as fromBonusItems from './bonusItems'
import courseLevels, * as fromCourseLevels from './courseLevels'
import faculties, * as fromFaculties from './faculties'
import departments, * as fromDepartments from './departments'
import courses, * as fromCourses from './courses'
import courseTypes, * as fromCourseTypes from './courseTypes'
import partners, * as fromPartners from './partners'
import testimonials, * as fromTestimonials from './testimonials'
import achievements, * as fromAchievements from './achievements'
import announcements, * as fromAnnouncements from './announcements'
import inquiries, * as fromInquiries from './inquiries'

const incytuteReducers = combineReducers({
    students,
    branchCourseDetails,
    batches,
    appData,
    invoices,
    configPaymentAmounts,
    receipts,
    users,
    branches,
    companies,
    waveoffList,
    dashboard,
    auth,
    actions,
    roles,
    payableItems,
    bankDeposits,
    bankAccounts,
    banks,
    materialOrders,
    bonusItems,
    courseLevels,
    faculties,
    departments,
    courses,
    courseTypes,
    partners,
    testimonials,
    achievements,
    announcements,
    inquiries
})

export default incytuteReducers;

export const getStudents = state => fromStudents.getStudents(state.incytuteReducers.students);
export const getStudentsByBranchId = (state, branchId) => fromStudents.getStudentsByBranchId(state.incytuteReducers.students, branchId);
export const getBranchCourseDetails = (branchId, state) => fromBranchCourseDetails.getBranchCourseDetails(branchId, state.incytuteReducers.branchCourseDetails);
export const getAllBranchCourseDetails = state => fromBranchCourseDetails.getAllBranchCourseDetails(state.incytuteReducers.branchCourseDetails);
export const getBatches = state => fromBatches.getBatches(state.incytuteReducers.batches);
export const getBatchesByBranch = (state, branchId) => fromBatches.getBatchesByBranch(state.incytuteReducers.batches, branchId);
export const getBatchesByQuery = (state, key) => fromBatches.getBatchesByQuery(state.incytuteReducers.batches, key);
export const getBatch = (state, id) => fromBatches.getBatch(state.incytuteReducers.batches, id);
export const getDetailBatch = (state, id) => fromBatches.getDetailBatch(state.incytuteReducers.batches, id);
export const getStudentByDocumentId = (state, documentId) => fromStudents.getStudentByDocumentId(state.incytuteReducers.students, documentId);
export const getAppData = state => fromAppData.getAppData(state.incytuteReducers.appData)
export const getInvoices = state => fromInvoices.getInvoices(state.incytuteReducers.invoices)
export const getInvoicesByIds = (state, ids) => fromInvoices.getInvoicesByIds(state.incytuteReducers.invoices, ids)
export const getInvoicesByCustomer = (state, customerId)  => fromInvoices.getInvoicesByCustomer(state.incytuteReducers.invoices, customerId)
export const getInvoicesByCustomerDocId = (state, customerDocId)  => fromInvoices.getInvoicesByCustomerDocId(state.incytuteReducers.invoices, customerDocId)
export const getInvoicesByBranchId = (state, branchId)  => fromInvoices.getInvoicesByBranchId(state.incytuteReducers.invoices, branchId)
export const getReceiptsByCustomer = (state, customerId)  => fromReceipts.getReceiptsByCustomer(state.incytuteReducers.receipts, customerId)
export const getPrintReceiptsById = (state, receiptId)  => fromReceipts.getPrintReceiptsById(state.incytuteReducers.receipts, receiptId)
export const getUsers = state => fromUsers.getUsers(state.incytuteReducers.users)
export const getUserById = (state, userid) => fromUsers.getUserById(state.incytuteReducers.users, userid)
export const isAdmin = (state, userid) => fromAuth.isAdmin(state.incytuteReducers.auth, userid)
export const getBranchById = (state, branchid) => fromBranches.getBranchById(state.incytuteReducers.branches, branchid)
export const getBranchByDocId = (state, docId) => fromBranches.getBranchByDocId(state.incytuteReducers.branches, docId)
export const getBranches = (state) => fromBranches.getBranches(state.incytuteReducers.branches)
export const getCompanies = state => fromCompanies.getCompanies(state.incytuteReducers.companies)
export const getCompanyById = (state, docId) => fromCompanies.getCompanyById(state.incytuteReducers.companies, docId)
export const getReceipts = state => fromReceipts.getReceipts(state.incytuteReducers.receipts)
export const getReceiptsByIds = (state, receiptIds) => fromReceipts.getReceiptsByIds(state.incytuteReducers.receipts, receiptIds)
export const getReceiptsByStatus = (state, branchId, status) => fromReceipts.getReceiptsByStatus(state.incytuteReducers.receipts, branchId, status)
export const getReceiptsByBranch = (state, branchId) => fromReceipts.getByBranchId(state.incytuteReducers.receipts, branchId);
export const getConfigAmounts = state => fromConfigAmounts.getConfigAmounts(state.incytuteReducers.configPaymentAmounts)
export const getWaveOffList = state => fromWaveOff.getWaveOffList(state.incytuteReducers.waveoffList)
export const getWaveOffListByIds = (state, ids) => fromWaveOff.getWaveOffListByIds(state.incytuteReducers.waveoffList, ids)
export const getWaveOffListByCustomerId = (state, customerId) => fromWaveOff.getWaveOffListByCustomerId(state.incytuteReducers.waveoffList, customerId);
export const getTransactionSummery = state => fromDashboard.getTransactionSummery(state.incytuteReducers.dashboard)
export const getTransactionUserSummery = state => fromDashboard.getTransactionUserSummery(state.incytuteReducers.dashboard)
export const getTransactionUserSummeryByUserId = (state, userId) => fromDashboard.getTransactionUserSummeryByUserId(state.incytuteReducers.dashboard, userId)
export const getStudentCardsByStudentId = (state, studentid) => fromStudents.getStudentCardsByStudentId(state.incytuteReducers.students, studentid);

export const getAuthenticity = state => fromAuth.getAuthenticity(state.incytuteReducers.auth);
export const isLoggedIn = state => fromAuth.isLoggedIn(state.incytuteReducers.auth);

export const getActions = state => fromActions.getActions(state.incytuteReducers.actions);
export const getActionByDocId = (state, actionDocId) => fromActions.getActionByDocId(state.incytuteReducers.actions, actionDocId)

export const getRoles = state => fromRoles.getRoles(state.incytuteReducers.roles)
export const getRoleByDocId = (state, roleDocId) => fromRoles.getRoleByDocId(state.incytuteReducers.roles, roleDocId)
export const getPayableItems = state => fromPayableItems.getPayableItems(state.incytuteReducers.payableItems)
export const getPayableItemsByBranchId = (state, branchId) => fromPayableItems.getPayableItemsByBranchId(state.incytuteReducers.payableItems, branchId);
export const getPayableItemByDocumentId = (state, payableItemDocId) => fromPayableItems.getPayableItemByDocId(state.incytuteReducers.payableItems, payableItemDocId);

export const getBankDeposits = state => frombankDeposits.getBankDeposits(state.incytuteReducers.bankDeposits);
export const getBankDepositByDocId = (state, bankDepositDocId) => frombankDeposits.getBankDepositByDocId(state.incytuteReducers.bankDeposits, bankDepositDocId);

export const getBankAccountsByBranchId = (state, branchid) => fromBankAccounts.getBankAccountsByBranchId(state.incytuteReducers.bankAccounts, branchid)
export const getBankAccounts = (state) => fromBankAccounts.getBankAccounts(state.incytuteReducers.bankAccounts)
export const getBanks = (state) => fromBanks.getBanks(state.incytuteReducers.banks)

export const getMaterialOrders = state => fromMaterialOrders.getMaterialOrders(state.incytuteReducers.materialOrders)

export const getOrderByOrderId = (state, orderId) => fromMaterialOrders.getOrderByOrderId(state.incytuteReducers.materialOrders, orderId)
export const getMaterialOrdersByBranch = (state, branchId) => fromMaterialOrders.getMaterialOrdersByBranch(state.incytuteReducers.materialOrders, branchId)
export const isItemAvailableForCreateOrder = (state, branchId, itemType)  => fromInvoices.isItemAvailableForCreateOrder(state.incytuteReducers.invoices, branchId, itemType)
export const getStudentsByOrderId = (state, orderId) => fromMaterialOrders.getStudentsByOrderId(state.incytuteReducers.materialOrders, orderId)

export const getBonusItems = state => fromBonusItems.getBonusItems(state.incytuteReducers.bonusItems)
export const getBonusItemsByBranchId = (state, branchid) => fromBonusItems.getBonusItemsByBranchId(state.incytuteReducers.bonusItems, branchid)

export const getNotBankedReceiptsByStatus = (state, status) => fromReceipts.getNotBankedReceiptsByStatus(state.incytuteReducers.receipts, status)

export const getHeadOfficeBranch = state => fromBranches.getHeadOfficeBranch(state.incytuteReducers.branches);

export const getInvoicesByReceiptId = (state, receiptId)  => fromInvoices.getInvoicesByReceiptId(state.incytuteReducers.invoices, receiptId)

export const getBranchWiseCourseMaterialCardDataList = state  => fromInvoices.getBranchWiseCourseMaterialCardDataList(state.incytuteReducers.invoices)

export const getCourseLevels = state => fromCourseLevels.getCourseLevels(state.incytuteReducers.courseLevels)
export const getCourseLevelByDocId = (state, courseLevelDocId) => fromCourseLevels.getCourseLevelByDocId(state.incytuteReducers.courseLevels, courseLevelDocId);


export const getFaculties = state => fromFaculties.getFaculties(state.incytuteReducers.faculties)
export const getFacultyByDocumentId = (state, docId) => fromFaculties.getFacultyByDocumentId(state.incytuteReducers.faculties, docId);

export const getDepartments = state => fromDepartments.getDepartments(state.incytuteReducers.departments)
export const getDepartmentsByFacultyId = (state, facultyId) => fromDepartments.getDepartmentByFacultyId(state.incytuteReducers.departments, facultyId);
export const getDepartmentByDocId = (state, docId) => fromDepartments.getDepartmentByDocId(state.incytuteReducers.departments, docId);

export const getPublishCoursesByBranch = (state, branchId) => fromCourses.getPublishCoursesByBranch(state.incytuteReducers.courses, branchId);
export const getCourses = state => fromCourses.getCourses(state.incytuteReducers.courses)
export const getCourseByDocId = (state, docId) => fromCourses.getCourseByDocId(state.incytuteReducers.courses, docId);

export const getCourseTypes = state => fromCourseTypes.getcourseTypes(state.incytuteReducers.courseTypes);

export const getBranchesByCompanyDocId = (state, companyDocId) => fromBranches.getBranchesByCompanyDocId(state.incytuteReducers.branches, companyDocId);

export const getPartnerById = (state, partnerId) => fromPartners.getPartnerById(state.incytuteReducers.partners, partnerId)
export const getPartnerByDocId = (state, docId) => fromPartners.getPartnerByDocId(state.incytuteReducers.partners, docId)
export const getPartners = (state) => fromPartners.getPartners(state.incytuteReducers.partners)

export const getTestimonialById = (state, testimonialsId) => fromTestimonials.getTestimonialById(state.incytuteReducers.testimonials, testimonialsId)
export const getTestimonialByDocId = (state, docId) => fromTestimonials.getTestimonialByDocId(state.incytuteReducers.testimonials, docId)
export const getTestimonials = (state) => fromTestimonials.getTestimonials(state.incytuteReducers.testimonials)

export const getAchievementById = (state, achievementId) => fromAchievements.getAchievementById(state.incytuteReducers.achievements, achievementId)
export const getAchievementByDocId = (state, docId) => fromAchievements.getAchievementByDocId(state.incytuteReducers.achievements, docId)
export const getAchievements = (state) => fromAchievements.getAchievements(state.incytuteReducers.achievements)

export const getAnnouncementById = (state, announcementId) => fromAnnouncements.getAnnouncementById(state.incytuteReducers.announcements, announcementId)
export const getAnnouncementByDocId = (state, docId) => fromAnnouncements.getAnnouncementByDocId(state.incytuteReducers.announcements, docId)
export const getAnnouncements = (state) => fromAnnouncements.getAnnouncements(state.incytuteReducers.announcements)

export const getInquiries = (state) => fromInquiries.getInquiries(state.incytuteReducers.inquiries)