import { combineReducers } from 'redux';
import {
  FETCH_STUDENTS,
  FETCH_STUDENT,
  UPDATE_STUDENT_COMPLETE,
  UPDATE_STUDENT_TRIGGER,
  UPDATE_STUDENTS,
  ASSIGN_BATCH,
  REMOVE_STUDENT,
  REMOVE_BATCH,
  ADD_STUDENT_CARD,
  FETCH_STUDENT_CARDS_BY_STUDENT_ID,
  UPDATE_STUDENT_CARD,
  DELETE_STUDENT_CARD,
} from '../actions/students'

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STUDENTS: {
      const newState = {};
      action.students.forEach(student => newState[student._id] = student);
      return newState;
    }
    case FETCH_STUDENT: {
      const { student } = action;
      return { ...state, [student._id]: student };
    }
    case UPDATE_STUDENT_TRIGGER: {
      const student = state[action.id];
      if (student) student.updating = true;
      return { ...state };
    }
    case UPDATE_STUDENT_COMPLETE: {
      if (action.success) {
        const { student } = action;
        return { ...state, [student._id]: student };
      } else {
        const student = state[action.student._id];
        if (student) student.updating = false;
        return state;
      }
    }
    case UPDATE_STUDENTS: {
      const { students } = action;
      const updatedState = {};
      students.forEach(student => updatedState[student._id] = student);
      return { ...state, ...updatedState };
    }
    case REMOVE_STUDENT: {
      const { [action.id]: deleted, ...rest } = state;
      return rest;
    }
    case ASSIGN_BATCH: {
      const { course: courseData } = action;
      const updatedStudent = state[courseData.studentid]
      const { course } = courseData;
      const { branchid, courseid, batchid } = course;
      const key = branchid + courseid + batchid
      const { courses } = updatedStudent || {}
      return { ...state, [courseData.studentid]: { ...updatedStudent, courses: { ...courses, [key]: course } } };
    }
    case REMOVE_BATCH: {
      const { deleteCourseResponse } = action;
      const { course, studentDocumentId } = deleteCourseResponse;
      const updatedStudent = state[studentDocumentId]
      const { courses } = updatedStudent || {}
      const removeKey = course.branchid + course.courseid + course.batchid;
      const { [removeKey]: deleted, ...rest } = courses

      return { ...state, [studentDocumentId]: { ...updatedStudent, courses: rest } }
    }
    case 'FETCH_DEPOSIT_STUDENTS': {
      const newState = { ...state };
      action.students.forEach(student => newState[student._id] = student);
      return newState;
    }
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_STUDENTS:
      return action.students.map(student => student._id);
    case FETCH_STUDENT:
      if (state.includes(action.student._id)) {
        return state
      } else {
        return [...state, action.student._id];
      }
    case REMOVE_STUDENT: {
      return state.filter(id => id != action.id);
    }
    case 'FETCH_DEPOSIT_STUDENTS': {
      const newState = [...state]
      action.students.forEach(student => {
        if (!newState.includes(student._id)) newState.push(student._id)
      });
      return newState;
    }
    default:
      return state;
  }
}

export const cardsByStudentIds = (state = {}, action) => {
  switch (action.type) {
    case ADD_STUDENT_CARD: {
      const { card } = action;
      let cardArray = state[card.studentid] || []

      return { ...state, [card.studentid]: [...cardArray, card] };
    }
    case FETCH_STUDENT_CARDS_BY_STUDENT_ID: {
      const { cards = [] } = action;
      const newState = cards && cards.length > 0 ? { [cards[0].studentid]: [...cards] } : {}
      return { ...state, ...newState };
    }
    case UPDATE_STUDENT_CARD: {
      const { card } = action;
      let cardArray = state[card.studentid] || []
      const index = cardArray.findIndex(existingCard => existingCard.cardid === card.cardid)

      if (index != -1) cardArray[index] = card;
      return { ...state, [card.studentid]: [...cardArray] };
    }
    case DELETE_STUDENT_CARD: {
      const { card } = action;
      let cardArray = state[card.studentid] || []
      const index = cardArray.findIndex(existingCard => existingCard.cardid === card.cardid)

      if (index != -1) cardArray.splice(index, 1)
      return { ...state, [card.studentid]: [...cardArray] };
    }
    default:
      return state;
  }
}

export const studentIds = (state = [], action) => {
  switch (action.type) {
    case ADD_STUDENT_CARD:
      if (state.includes(action.card.studentid)) {
        return state
      } else {
        return [...state, action.card.studentid];
      }
    case FETCH_STUDENT_CARDS_BY_STUDENT_ID: {
      const { cards = [] } = action;
      cards.forEach(card => {
        if (state.includes(card.studentid)) {
          return state
        } else {
          return [...state, card.studentid];
        }
      })
    }
    default:
      return state;
  }
}

const students = combineReducers({
  byId,
  ids,
  cardsByStudentIds,
  studentIds
});

export default students;

export const getStudents = state => {
  const studentList = state.ids.map(id => state.byId[id]);
  //sort by created date
  const sortedStudentList = studentList.sort(function (s1, s2) {
    if (s1.createdAt < s2.createdAt) return 1;
    if (s1.createdAt > s2.createdAt) return -1
    return 0
  })

  //Sort by status
  return sortedStudentList.sort((s1, s2) => {
    if (statuses[s1.status] < statuses[s2.status]) return -1;
    if (statuses[s1.status] > statuses[s2.status]) return 1;
    return 0;
  });
}

export const getStudentByDocumentId = (state, documentId) =>
  state.byId[documentId]

export const getStudentCardsByStudentId = (state, studentId) => state.cardsByStudentIds[studentId]

export const getStudentsByBranchId = (state, branchId) => {
  const studentList = state.ids.map(id => state.byId[id])
  return studentList && studentList.length > 0 ? studentList.filter(student => student.registeredbranchid === branchId) : []
}

const statuses = {
  "PENDING": 1,
  "REGISTERED": 2
}

