import * as fromApi from '../api/courses';

export const FETCH_PUBLISH_COURSES = 'FETCH_PUBLISH_COURSES';
export const FETCH_COURSES = 'FETCH_COURSES';
export const FETCH_COURSE = 'FETCH_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';

export const fetchPublishCourses = branchId => dispatch => {
  return fromApi.getPublishCourseList(branchId).then(response => {
    dispatch({ type: FETCH_PUBLISH_COURSES, branchId, publishCourses: response.publishCourses || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchCourses = branchId => dispatch => {
  return fromApi.getCourses(branchId).then(response => {
    dispatch({ type: FETCH_COURSES, courses: response.courses || [] });
  }).catch(error => {
    return { error };
  });
}

export const publishCourses = publishCourseData => dispatch => {
  return fromApi.publish(publishCourseData).then(response => {
    // dispatch(fetchPublishCourses(publishCourseData.branchid));
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addCourse = course => dispatch => {
  return fromApi.addCourse(course).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateCourse = (docId, request) => dispatch => {
  return fromApi.updateCourse(docId, request).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || {} });

    return response;
  }).catch(error => {
    return { error };
  });
}

export const unpublishCourses = unpublishCourseData => dispatch => {
  return fromApi.unpublish(unpublishCourseData).then(response => {
    // dispatch(fetchPublishCourses(unpublishCourseData.branchid));
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteCourse = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteCourse(docId, deleteRequest).then(response => {
    const {error, course} = response;
    if (error) return {error};
    dispatch({type: DELETE_COURSE, course: course || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

export const updateCourseType = (docId, courseType) => dispatch => {
  return fromApi.updateCourseType(docId, courseType).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || [] });

    return response;
  }).catch(error => {
    return { error };
  });
}

export const unAssignCourseItem = (docId, courseItem) => dispatch => {
  return fromApi.unAssignCourseItem(docId, courseItem).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || [] });

    return response;
  }).catch(error => {
    return { error };
  });
}

export const assignCourseItem = (docId, courseItem) => dispatch => {
  return fromApi.assignCourseItem(docId, courseItem).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || [] });

    return response;
  }).catch(error => {
    return { error };
  });
}

export const updateCourseFeatured = (docId, request) => dispatch => {
  return fromApi.setCourseFeatured(docId, request).then(response => {
    const { error, course } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COURSE, course: course || {} });

    return response;
  }).catch(error => {
    return { error };
  });
}