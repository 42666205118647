import * as fromApi from '../api/inquiries';

export const FETCH_INQUIRIES = 'FETCH_INQUIRIES';

export const fetchInquiries = () => dispatch => {
  return fromApi.getInquiries().then(response => {
    dispatch({ type: FETCH_INQUIRIES, inquiries: response.inquiries || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}