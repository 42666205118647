import { combineReducers } from 'redux';
import { FETCH_COMPANIES, FETCH_COMPANY, DELETE_COMPANY } from '../actions/companies'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_COMPANIES:
			const newState = {};
			action.companies.forEach(company => newState[company._id] = company);
			return newState;
		case FETCH_COMPANY: {
			const { company } = action;
			return { ...state, [company._id]: company };
		}
		case DELETE_COMPANY: {
			const { company } = action;
			delete state[company._id]
			return state
		}
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_COMPANIES:
		return action.companies.map(company => company._id);
		case FETCH_COMPANY:
			if (state.includes(action.company._id)) {
				return state
			} else{
				return [ ...state, action.company._id ];
			}
			case DELETE_COMPANY: {
				const { company } = action
				return state.filter(stateCompany => stateCompany !== company._id);
			}
	  default:
		return state;
    }
}

const companies = combineReducers({
    byId,
    ids
});

export default companies;

export const getCompanies = state => state.ids.map(id => state.byId[id]);

export const getCompanyById = (state, docId) => state.byId[docId];