import * as fromApi from '../api/payableItems';

export const FETCH_PAYABLE_ITEMS = 'FETCH_PAYABLE_ITEMS';
export const FETCH_PAYABLE_ITEM = 'FETCH_PAYABLE_ITEM';
export const DELETE_PAYABLE_ITEM = 'DELETE_PAYABLE_ITEM';

export const fetchPayableItems = branchId => dispatch => {
    return fromApi.getPayableItems(branchId).then(response => {
      dispatch({ type: FETCH_PAYABLE_ITEMS, payableItems: response.payableItems || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const addPayableItem = payableItem => dispatch => {
  return fromApi.addPayableItem(payableItem).then(response => {
    const { error, payableItem } = response;
    if(error) return {error};

    dispatch({ type: FETCH_PAYABLE_ITEM, payableItem: payableItem || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const updatePayableItem = (docId, payableItem) => dispatch => {
  return fromApi.updatePayableItem(docId, payableItem).then(response => {
    const { error, payableItem } = response;
    if(error) return {error};

    dispatch({ type: FETCH_PAYABLE_ITEM, payableItem: payableItem || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deletePayableItem = (docId, deleteRequest) => dispatch => {
  return fromApi.deletePayableItem(docId, deleteRequest).then(response => {
    const {error, payableItem} = response;
    if (error) return {error};
    dispatch({type: DELETE_PAYABLE_ITEM, payableItem: payableItem || []});
    return response;
  }).catch(error => {
    return {error};
  });
}
