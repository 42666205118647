import { combineReducers } from 'redux';
import { FETCH_BANK_DEPOSITS, FETCH_BANK_DEPOSIT, COMPLETE_BANK_DEPOSIT, RECONCILE_BANK_DEPOSIT, DELETE_BANK_DEPOSIT } from '../actions/bankDeposits';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BANK_DEPOSITS:
      const newState = {};
      action.bankDeposits.forEach(bankDeposit => newState[bankDeposit._id] = { ...bankDeposit, key: bankDeposit._id });
      return newState;
    case FETCH_BANK_DEPOSIT:
      const { bankDeposit } = action;
      return { ...state, [bankDeposit._id]: { ...bankDeposit, key: bankDeposit._id } }
    case COMPLETE_BANK_DEPOSIT:
      const { completeResponse } = action;
      return { ...state, [completeResponse.bankDeposit._id]: { ...completeResponse.bankDeposit, key: completeResponse.bankDeposit._id } }
    case RECONCILE_BANK_DEPOSIT:
      const { reconcileResponse } = action;
      return { ...state, [reconcileResponse.bankDeposit._id]: { ...reconcileResponse.bankDeposit, key: reconcileResponse.bankDeposit._id } }
    case DELETE_BANK_DEPOSIT:
      delete state[action.deleteResponse.bankDeposit._id]
      return state
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_BANK_DEPOSITS:
      return action.bankDeposits.map(bankDeposit => bankDeposit._id);
    case FETCH_BANK_DEPOSIT: {
      const newState = [ ...state ]
      if (!newState.includes(action.bankDeposit._id)) {
        newState.push(action.bankDeposit._id);
        return newState;
      }

      return state
    }
    case COMPLETE_BANK_DEPOSIT: {
      const { completeResponse } = action;
      const newState = [ ...state ]
      if (!newState.includes(completeResponse.bankDeposit._id)) {
        newState.push(completeResponse.bankDeposit._id);
        return newState;
      }

      return state
    }
    case RECONCILE_BANK_DEPOSIT: {
      const newState = [ ...state ]
      if (!newState.includes(action.reconcileResponse.bankDeposit._id)) {
        newState.push(action.reconcileResponse.bankDeposit._id);
        return newState;
      }

      return state
    }
    case DELETE_BANK_DEPOSIT: {
      return state.filter(bankDepositDocId => bankDepositDocId !== action.deleteResponse.bankDeposit._id);
    }
    default:
      return state;

  }
}

const bankDeposits = combineReducers({
  byId,
  ids
});

export default bankDeposits;

export const getBankDeposits = state => {
  const depositList = state.ids.map(id => state.byId[id])
  return sortListByDepositDepositDate(depositList);
}
export const getBankDepositByDocId = (state, depositDocId) => {
  return state.byId[depositDocId];
}

const sortListByDepositDepositDate = list => {
  const sortedList = list && list.length > 0 ? list.sort(function (deposit1, deposit2) {
    let deposit1_depositDate = parseInt(deposit1.depositDate);
    let deposit2_depositDate = parseInt(deposit2.depositDate);

    if (deposit1_depositDate < deposit2_depositDate) return 1;
    if (deposit1_depositDate > deposit2_depositDate) return -1
    return 0
  }) : []
  return sortedList;
}
