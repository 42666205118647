import * as fromApi from '../api/configPaymentAmounts';

export const FETCH_CONFIG_AMOUNTS = 'FETCH_CONFIG_AMOUNTS';

export const fetchConfigPaymentAmounts = branchid => dispatch => {
    return fromApi.getConfigPaymentAmounts(branchid).then(response => {
        const { configAmounts, error } = response;
        if (error) return { error }

	  dispatch({ type: FETCH_CONFIG_AMOUNTS, configAmounts: configAmounts || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}