import { combineReducers } from 'redux';
import {FETCH_BRANCH_COURSE_DETAILS} from "../actions/branchCourseDetails";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_BRANCH_COURSE_DETAILS:
		const newState = {};
		action.branchCourseDetails.forEach(branchCourse => newState[branchCourse._id] = branchCourse);
		return newState;
	  default:
		return state;
    }
};

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BRANCH_COURSE_DETAILS:
		return action.branchCourseDetails.map(branchCourse => branchCourse._id);
	  default:
		return state;
    }
}

const branchCourseDetails = combineReducers({
    byId,
    ids
});

export default branchCourseDetails;

export const getBranchCourseDetails = (branchId, state) => {
	return state.ids.map(id => state.byId[id]).filter(courseDetail => courseDetail.branchid == branchId);
}

export const getAllBranchCourseDetails = state => state.ids.map(id => state.byId[id]);
    