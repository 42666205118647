import * as fromApi from '../api/invoices';
import { FETCH_STUDENTS } from './students'

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICES_BY_STUDENT_ID = 'FETCH_INVOICES_BY_STUDENT_ID';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const FETCH_UPDATED_INVOICES_BY_STUDENT_ID = 'FETCH_UPDATED_INVOICES_BY_STUDENT_ID';
export const DELETE_INVOICES_BY_INVOICE_ID = 'DELETE_INVOICES_BY_INVOICE_ID';
export const FETCH_DATA_FOR_CREATE_ORDER = 'FETCH_INVOICES_FOR_CREATE_ORDER';
export const FETCH_INVOICES_BY_STUDENT_DOC_ID = 'FETCH_INVOICES_BY_STUDENT_DOC_ID';
export const FETCH_INVOICES_BY_RECEIPT_ID = 'FETCH_INVOICES_BY_RECEIPT_ID';

export const fetchInvoices = query => dispatch => {
  return fromApi.invoicesAdvanceSearch(query).then(response => {
    dispatch({ type: FETCH_INVOICES, invoices: response.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addInvoice = invoices => dispatch => {
  return fromApi.addInvoice(invoices).then(response => {
    const { invoiceResponse, error } = response;
    if (error) return { error };

    dispatch({ type: FETCH_INVOICE, invoices: invoiceResponse.invoices || [] });
    dispatch({ type: FETCH_STUDENTS, students: [invoiceResponse.student] || [] });
    dispatch({ type: FETCH_INVOICES_BY_STUDENT_ID, invoices: invoiceResponse.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchInvoicesByCustomerId = studentid => dispatch => {
  return fromApi.getInvoicesByStudentId(studentid).then(response => {
    dispatch({ type: FETCH_INVOICES_BY_STUDENT_ID, invoices: response.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchInvoicesByCustomerDocId = studentDocId => dispatch => {
  return fromApi.getInvoicesByCustomerDocId(studentDocId).then(response => {
    dispatch({ type: FETCH_INVOICES_BY_STUDENT_DOC_ID, invoices: response.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchInvoice = invoiceId => dispatch => {
  return fromApi.getInvoice(invoiceId).then(response => {
    dispatch({ type: FETCH_INVOICES, invoices: response.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteInvoicesById = (invoiceIds, userId) => dispatch => {
  return fromApi.deleteInvoicesById(invoiceIds, userId).then(response => {
    const { deletedInvoiceIds, error } = response;
    if (error) return error

    if (deletedInvoiceIds.deletedInvoiceIds && deletedInvoiceIds.deletedInvoiceIds.length > 0) {
      dispatch({ type: DELETE_INVOICES_BY_INVOICE_ID, deletedInvoiceResponse: { deletedInvoiceIds } || [] });
    }

    return deletedInvoiceIds;
  }).catch(error => {
    return { error };
  })
}

export const updateInvoice = (invoice, invoiceid) => dispatch => {
  return fromApi.updateInvoice(invoice, invoiceid).then(response => {
    const { invoiceResponse, error } = response;
    if (error) return { error };

    dispatch({ type: UPDATE_INVOICES, invoices: invoiceResponse.invoices || [] });
    dispatch({ type: FETCH_STUDENTS, students: [invoiceResponse.student] || [] });
    dispatch({ type: FETCH_INVOICES_BY_STUDENT_ID, invoices: invoiceResponse.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchInvoicesForCreateOrder = (branchId) => dispatch => {
  return fromApi.getInvoicesForCreateOrder(branchId).then(response => {

    dispatch({ type: FETCH_DATA_FOR_CREATE_ORDER, data: response.data || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const fetchInvoicesByReceiptId = receiptId => dispatch => {
  return fromApi.getInvoicesForReceiptId(receiptId).then(response => {
    dispatch({ type: FETCH_INVOICES_BY_RECEIPT_ID, receiptId, invoices: response.invoices || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}