import Api from './const';
import fetch from './commonFetch'

const { getAll, get, add, update, remove, setFeaturedBranchCourse } = Api.branchCourseDetails;

export const getBranchCourseDetails = (page, pageSize) => {
    const { url, method, headers } = getAll;

    return fetch(url(page, pageSize), { method, headers }, "branchCourseDetails")
}
export const setBranchCourseFeatured = (request) => {
    const { url, method, headers } = setFeaturedBranchCourse;
    const body = JSON.stringify(request);

    return fetch(url(), { method, headers, body }, "course")
}