import { loadState } from '../localStorage';

const isActionPermitted = (action) => {
    const authenticity = loadState('authenticity')
    return authenticity && authenticity.roleActions && authenticity.roleActions.includes(action);
}

const Permission = {
    NAVIGATION: {
        DASHBOARD: {
            DASHBOARD: "navigation.dashboards",
            USERDASHBOARD: "navigation.dashboards.userdashboard",
        },
        ADMINISTRATION: {
            ADMINISTRATION: "navigation.administration",
            USERS: "navigation.administration.users",
            ACTIONS: "navigation.administration.actions",
            ROLES: "navigation.administration.roles",
        },
        CONFIGURATION: {
            CONFIGURATION: "navigation.configuration",
            SYSTEM_PARAMETERES: "navigation.configuration.systemparameters",
            BATCHES: "navigation.configuration.batches",
            PAYABLE_ITEM_LIST: "navigation.configuration.payableitemlist",
            COURSE_LEVELS: "navigation.configuration.courselevels",
            FACULTY: "navigation.configuration.faculty",
            COURSES: "navigation.configuration.courses",
            PUBLISH_COURSES: "navigation.configuration.publishcourses",
            COMPANY: "navigation.configuration.company"
        },
        STUDENTS: {
            STUDENTS: "navigation.students",
            STUDENT_REGISTRATION: "navigation.students.studentregistration",
        },
        PAYMENTS: {
            PAYMENTS: "navigation.payments",
            INVOICES: "navigation.payments.invoicesummary",
            RECEIPTS: "navigation.payments.receiptsummary",
            WAVEOFF: "navigation.payments.waveoffsummary",
            BANK_DEPOSITS: "navigation.payments.depositsummary"
        },
        USER_PROFILE: {
            USER_PROFILE: "navigation.userprofile",
            RESET_PASSWORD: "navigation.userprofile.resetpassword",
        },
        COURSE_MATERIALS: {
            COURSE_MATERIALS: "navigation.coursematerials",
            MATERIAL_ORDER: "navigation.coursematerials.coursematerialordersummary",
            MATERIAL_ASSIGN: "navigation.coursematerials.coursematerialassign",
        },
        PARTNERS: {
            PARTNERS: "navigation.partners"
        },
        TESTIMONIALS: {
            TESTIMONIALS: "navigation.testimonials"
        },
        ACHIEVEMENTS: {
            ACHIEVEMENTS: "navigation.achievements"
        },
        ANNOUNCEMENTS: {
            ANNOUNCEMENTS: "navigation.announcements"
        },
          INQUIRIES: {
              INQUIRIES: "navigation.inquiries"
            },
    },
    TEMPLATE: {
        DASHBOARD: {
            USERDASHBOARD: {
                USERDASHBOARD: "template.userdashboard",
                TRANSACTION_SUMMARY: "template.userdashboard.transactionsummary",
                USER_ACTIVITY_SUMMARY: "template.userdashboard.useractivitysummary",
                BATCH_SUMMARY: "template.userdashboard.batchsummary",
                WEB_INQUIRIES: "template.userdashboard.webinquiries",
            }
        },
        ADMINISTRATION: {
            USERS: {
                USERS: "template.users",
                CREATE: "template.users.create",
                EDIT: "template.users.edit",
                ACTIVATE: "template.users.activate",
                BRANCH_FILTER: "template.users.branchfilter"
            },
            ACTIONS: {
                ACTIONS: "template.action",
                CREATE: "template.action.create",
                EDIT: "template.action.edit",
                DELETE: "template.action.delete",
            },
            ROLES: {
                ROLES: "template.roles",
                CREATE: "template.roles.create",
                EDIT: "template.roles.edit",
                DELETE: "template.roles.delete"
            },
        },
        CONFIGURATIONS: {
            SYSTEM_PARAMETERES: {
                SYSTEM_PARAMETERES: "template.systemparameters",
                BANK_ACOUNT: {
                    BANK_ACOUNT: "template.systemparameters.bankaccount",
                    BANK_ACOUNT_LIST: {
                        BANK_ACOUNT_LIST: "template.systemparameters.bankaccount.bankaccountlist",
                        ACTIONS: {
                            ACTIONS: "template.systemparameters.bankaccount.bankaccountlist.actions",
                            DELETE_ACCOUNT: "template.systemparameters.bankaccount.bankaccountlist.actions.deletebankaccount",
                            ACTIVATE_ACCOUNT: "template.systemparameters.bankaccount.bankaccountlist.actions.activatebankaccount",
                        }
                    },
                    FILTER_FORM: {
                        FILTER_FORM: "template.systemparameters.bankaccount.filterform",
                        BRANCH_FILTER: "template.systemparameters.bankaccount.filterform.branchfilter"
                    },
                    CREATE_ACCOUNT: {
                        CREATE_ACCOUNT: "template.systemparameters.bankaccount.createbankaccount",
                        BRANCH_FILTER: "template.systemparameters.bankaccount.createbankaccount.branchfilter"
                    }
                }
            },
            BATCHES: {
                BATCHES: "template.batches",
                ACTIONS: {
                    ACTIONS: "template.batches.actions",
                    CREATE: "template.batches.actions.create",
                    DELETE: "template.batches.actions.delete",
                    EDIT: "template.batches.actions.edit",
                    VIEW: "template.batches.actions.view",
                    BRANCH_FILTER: "template.batches.actions.branchfilter",
                    DOWNLOAD_CLASS_REGISTERS: "template.batches.actions.view.downloadclassregisters",
                    DOWNLOAD_STUDENT_INFO: "template.batches.actions.view.downloadstudentinformation",
                    CLOSE_BATCH: "template.batches.actions.view.closebatch",
                },
                ROW_SELECTION: {
                    ROW_SELECTION: "template.batches.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.batches.rowselection.bulkactions",
                        DOWNLOAD_ALL_CLASS_REGISTERS: "template.batches.rowselection.bulkactions.downloadallclassregisters",
                        DELETE: "template.batches.rowselection.bulkactions.delete"
                    }
                }
            },
            PAYABLEITEMLIST: {
                PAYABLEITEMLIST: "template.payableItemList"
            },
            COURSES: {
                COURSES: "template.courses",
                ACTIONS: {
                    ACTIONS: "template.courses.actions",
                    CREATE: "template.courses.actions.create",
                    DELETE: "template.courses.actions.delete",
                    EDIT: "template.courses.actions.edit",
                    VIEW: "template.courses.actions.view",
                    FEATURED: "template.courses.actions.featured",
                },
                COURSE_TYPES: {
                    COURSE_TYPES: "template.courses.coursetypes",
                    ACTIONS: {
                        ACTIONS: "template.courses.coursetypes.actions",
                        CREATE: "template.courses.coursetypes.actions.create",
                        EDIT: "template.courses.coursetypes.actions.edit",
                        DELETE: "template.courses.coursetypes.actions.delete",
                        ACTIVATE: "template.courses.coursetypes.actions.activate",
                    },
                    PAYABLE_ITEMS: {
                        PAYABLE_ITEMS: "template.courses.coursetypes.payableitems",
                        ACTIONS: {
                            ACTIONS: "template.courses.coursetypes.payableitems.actions",
                            CREATE: "template.courses.coursetypes.payableitems.actions.create",
                            DELETE: "template.courses.coursetypes.payableitems.actions.delete",
                        }
                    },
                },
            },
            PUBLISH_COURSES: {
                PUBLISH_COURSES: "template.publishcourses",
                ACTIONS: {
                    ACTIONS: "template.publishcourses.actions",
                    PUBLISH: "template.publishcourses.actions.publish",
                    BRANCH_FILTER: "template.publishcourses.actions.branchfilter",
                },
                ROW_SELECTION: {
                    ROW_SELECTION: "template.publishcourses.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.publishcourses.rowselection.bulkactions",
                        PUBLISH: "template.publishcourses.rowselection.bulkactions.publish"
                    }
                }
            }
        },
        STUDENTS: {
            STUDENT_REGISTRATION: {
                STUDENT_REGISTRATION: "template.studentregistration",
                ACTIONS: {
                    ACTIONS: "template.studentregistration.actions",
                    CREATE: "template.studentregistration.actions.create",
                    DELETE: "template.studentregistration.actions.delete",
                    EDIT: "template.studentregistration.actions.edit",
                    VIEW: "template.studentregistration.actions.view",
                    BRANCH_FILTER: "template.studentregistration.actions.branchfilter",
                },
                STUDENT_INFORMATION: {
                    ACTIONS: {
                        UPDATE: "template.studentregistration.studentinformation.update",
                        CREATE_REGISTRATION_INVOICE: "template.studentregistration.studentinformation.update.createregistrationinvoice",
                    }
                }
            },
            IMAGE_UPLOAD: {
                IMAGE_UPLOAD: "template.imageupload",
                CAPTURE_IMAGE: "template.captureimage",
            },
            STUDENT_CARDS: {
                STUDENT_CARDS: "template.studentcards",
                CARD_LIST: {
                    CARD_LIST: "template.studentcards.cardlist",
                    ACTIONS: {
                        ACTIONS: "template.studentcards.cardlist.actions",
                        ACTIVATE: "template.studentcards.cardlist.actions.activate",
                        DELETE: "template.studentcards.cardlist.actions.delete",
                    }
                },
                CARD_FORM: "template.studentcards.cardform",
            },
            COURSE_ENROLLMENT: {
                COURSE_ENROLLMENT: "template.courseenrollment",
                ENROLLED_LIST: {
                    ENROLLED_LIST: "template.courseenrollment.enrolledlist",
                    ACTIONS: {
                        ACTIONS: "template.courseenrollment.enrolledlist.actions",
                        CREATE_INVOICE: "template.courseenrollment.enrolledlist.actions.createinvoice",
                        DELETE: "template.courseenrollment.enrolledlist.actions.delete",
                        BATCH_TRANSFER: "template.courseenrollment.enrolledlist.actions.batchtransfer",
                    }
                },
                ENROLLMENT_FORM: "template.courseenrollment.enrollmentform",
            },
        },
        PAYMENTS: {
            INVOICES: {
                INVOICES: "template.invoicesummary",
                BRANCH_FILTER: "template.invoicesummary.branchfilter",
                ROW_SELECTION: {
                    ROW_SELECTION: "template.invoicesummary.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.invoicesummary.rowselection.bulkactions",
                        WAVEOFF: "template.invoicesummary.rowselection.bulkactions.waveoff",
                        REPORT_EXPORT: "template.invoicesummary.rowselection.bulkactions.reportexport",
                        PRINT: "template.invoicesummary.rowselection.bulkactions.print",
                        DELETE: "template.invoicesummary.rowselection.bulkactions.delete"
                    }
                },
                ACTIONS: {
                    ACTIONS: "template.invoicesummary.actions",
                    CREATE: "template.invoicesummary.actions.create",
                    DELETE: "template.invoicesummary.actions.delete",
                    EDIT: "template.invoicesummary.actions.edit",
                    WAVEOFF: "template.invoicesummary.actions.waveoff",
                    ACCEPT_PAYMENTS: "template.invoicesummary.actions.acceptpayment",
                    SEPARATE_INVOICE_FOR_ALL_ITEMS: "template.invoicesummary.actions.separateinvoiceforallitems",
                    SEPARATE_INVOICE_FOR_ITEMS: "template.invoicesummary.actions.separateinvoiceforitems",
                    SEPARATE_INVOICE_FOR_ITEM_GROUP: "template.invoicesummary.actions.separateinvoiceforitemgroup",
                    ADD_INVOICE_ITEM: "template.invoicesummary.actions.addinvoiceitem",
                    DELETE_INVOICE_ITEM: "template.invoicesummary.actions.deleteinvoiceitem",
                    SELECT_INVOICE_ITEM: "template.invoicesummary.actions.selectinvoiceitem",
                    INVOICE_DISCOUNT: "template.invoicesummary.actions.invoicediscount",
                }
            },
            RECEIPTS: {
                RECEIPTS: "template.receiptsummary",
                BRANCH_FILTER: "template.receiptsummary.branchfilter",
                ROW_SELECTION: {
                    ROW_SELECTION: "template.receiptsummary.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.receiptsummary.rowselection.bulkactions",
                        REPORT_EXPORT: "template.receiptsummary.rowselection.bulkactions.reportexport",
                        PRINT: "template.receiptsummary.rowselection.bulkactions.print",
                        DELETE: "template.receiptsummary.rowselection.bulkactions.delete"
                    }
                },
                ACTIONS: {
                    ACTIONS: "template.receiptsummary.actions",
                    PRINT: "template.receiptsummary.actions.print",
                    DELETE: "template.receiptsummary.actions.delete",
                }
            },
            WAVEOFF: {
                WAVEOFF: "template.waveoffsummary",
                BRANCH_FILTER: "template.waveoffsummary.branchfilter",
                ROW_SELECTION: {
                    ROW_SELECTION: "template.waveoffsummary.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.waveoffsummary.rowselection.bulkactions",
                        REPORT_EXPORT: "template.waveoffsummary.rowselection.bulkactions.reportexport",
                        PRINT: "template.waveoffsummary.rowselection.bulkactions.print",
                        DELETE: "template.waveoffsummary.rowselection.bulkactions.delete",
                        WAVEOFF: "template.waveoffsummary.rowselection.bulkactions.waveoff",
                    }
                },
                ACTIONS: {
                    ACTIONS: "template.waveoffsummary.actions",
                    CREATE: "template.waveoffsummary.actions.create",
                    WAVEOFF: "template.waveoffsummary.actions.waveoff",
                    PRINT: "template.waveoffsummary.actions.print",
                    DELETE: "template.waveoffsummary.actions.delete",
                }
            },
            BANK_DEPOSITS: {
                BANK_DEPOSITS: "template.bankdepositsummary",
                ADVANCED_SEARCH: {
                    BRANCH_FILTER: "template.bankdepositsummary.advancesearch.branchfilter",
                    RECONCILED_STATUS_FILTER: "template.bankdepositsummary.advancesearch.reconciledstatus",
                    CONFIRMED_STATUS_FILTER: "template.bankdepositsummary.advancesearch.confirmedstatus",
                    COMPLETED_STATUS_FILTER: "template.bankdepositsummary.advancesearch.completedstatus",
                    DRAFT_STATUS_FILTER: "template.bankdepositsummary.advancesearch.draftstatus",
                },
                ACTIONS: {
                    ACTIONS: "template.bankdepositsummary.actions",
                    PRINT: "template.bankdepositsummary.actions.print",
                    CREATE: "template.bankdepositsummary.actions.create",
                    EDIT: "template.bankdepositsummary.actions.edit",
                    CONFIRM: "template.bankdepositsummary.actions.confirm",
                    COMPLETE: "template.bankdepositsummary.actions.complete",
                    RECONCILE: "template.bankdepositsummary.actions.reconcile",
                    DELETE: "template.bankdepositsummary.actions.delete",
                    VIEW: "template.bankdepositsummary.actions.view",
                    REPORT_EXPORT: "template.bankdepositsummary.actions.reportexport",
                    DRAFT: "template.bankdepositsummary.actions.draft",
                }
            },
        },
        USER_PROFILE: {
            USER_PROFILE: "template.userprofile",
            RESET_PASSWORD: "template.resetpassword",
        },
        COURSE_MATERIALS: {
            MATERIAL_ORDER: {
                MATERIAL_ORDER: "template.coursematerials.coursematerialordersummary",
                BRANCH_FILTER: "template.coursematerials.coursematerialordersummary.branchfilter",
                ACTIONS: {
                    ACTIONS: "template.coursematerials.coursematerialordersummary.actions",
                    CREATE: "template.coursematerials.coursematerialordersummary.actions.create",
                    EDIT: "template.coursematerials.coursematerialordersummary.actions.edit",
                    DELETE: "template.coursematerials.coursematerialordersummary.actions.delete",
                },
                COLUMNS: {
                    BRANCH_NAME: "template.coursematerials.coursematerialordersummary.columns.branchname",
                    BRANCH_CITY: "template.coursematerials.coursematerialordersummary.columns.branchcity",
                },
                ORDER: {
                    ORDER: "template.coursematerials.order.view",
                    ACTIONS: {
                        ACTIONS: "template.coursematerials.order.actions",
                        CREATE: "template.coursematerials.order.create",
                        UPDATE: "template.coursematerials.order.update",
                        CONFIRM: "template.coursematerials.order.confirm",
                    }
                },
                SEND_ORDER: {
                    SEND_ORDER: "template.coursematerials.sendorder.view",
                    ACTION: "template.coursematerials.sendorder.send"
                },
                DELIVER_ORDER: {
                    DELIVER_ORDER: "template.coursematerials.deliverorder.view",
                    ACTION: "template.coursematerials.deliverorder.deliver"
                },
                COMPLETE_ORDER: {
                    COMPLETE_ORDER: "template.coursematerials.completeorder.view",
                    ACTION: "template.coursematerials.completeorder.complete"
                }
            },
            MATERIAL_ASSIGN: {
                MATERIAL_ASSIGN: "template.coursematerials.coursematerialassign",
                BRANCH_FILTER: "template.coursematerials.coursematerialassign.branchfilter",
                ROW_SELECTION: {
                    ROW_SELECTION: "template.coursematerials.coursematerialassign.rowselection",
                    BULK_ACTIONS: {
                        BULK_ACTIONS: "template.coursematerials.coursematerialassign.bulkactions",
                        CONFIRM: "template.coursematerials.coursematerialassign.rowselection.bulkactions.confirm",
                        COLLECT: "template.coursematerials.coursematerialassign.rowselection.bulkactions.collect",
                    }
                },
                ACTIONS: {
                    ACTIONS: "template.coursematerials.coursematerialassign.actions",
                    CONFIRM: "template.coursematerials.coursematerialassign.actions.confirm",
                    COLLECT: "template.coursematerials.coursematerialassign.actions.collect",
                }
            },            
        },
    }
}

export {
    isActionPermitted,
    Permission
}