import * as fromApi from '../api/dashboard';

export const FETCH_TRANSACTION_SUMMERY = 'FETCH_TRANSACTION_SUMMERY';
export const FETCH_TRANSACTION_USER_SUMMERY = 'FETCH_TRANSACTION_USER_SUMMERY';

export const fetchTransactionSummery = query => dispatch => {
  return fromApi.getDashboardTransactionSummery(query).then(response => {
    if (response.error) return response;
    
    const { transactionSummery } = response;
    dispatch({ type: FETCH_TRANSACTION_SUMMERY, transactionSummeryData: transactionSummery || [] });
    return response;
  }).catch(error => {
    return { error };
  });
  }

  export const fetchTransactionUserSummery = query => dispatch => {
    return fromApi.getDashboardTransactionUserSummery(query).then(response => {
      if (response.error) return response;
      
      const { transactionUserSummery } = response;
      dispatch({ type: FETCH_TRANSACTION_USER_SUMMERY, transactionUserSummeryData: transactionUserSummery || [] });
      return response;
    }).catch(error => {
      return { error };
    });
    }