import { combineReducers } from 'redux';
import { FETCH_PARTNERS, DELETE_PARTNER, FETCH_PARTNER } from '../actions/partners';
import {FETCH_PAYABLE_ITEM} from "../actions/payableItems";

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_PARTNERS:
			const newState = {};
			action.partners.forEach(partner => newState[partner._id] = partner);
			return newState;
		case FETCH_PARTNER:
			return { ...state, [action.partner._id]: action.partner };
		case DELETE_PARTNER:
			const { partner } = action;
			delete state[partner._id]
			return state
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_PARTNERS:
			return action.partners.map(partner => partner._id);
		case FETCH_PARTNER:
			if (state.includes(action.partner._id)) {
				return state
			} else{
				return [ ...state, action.partner._id ];
			}
		case DELETE_PARTNER:
			const { partner } = action
			return state.filter(statePartner => statePartner !== partner._id);
	  default:
		return state;
    }
}

const partners = combineReducers({
    byId,
    ids
});

export default partners;

export const getPartners = state => state.ids.map(id => state.byId[id]);

export const getPartnerById = (state, _id) => state.byId[_id];

export const getPartnerByDocId = (state, docId) => state.ids.map(id => state.byId[id]).find(partner => partner._id === docId);