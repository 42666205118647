import { combineReducers } from 'redux';
import { saveState, loadState } from '../localStorage';

export const authenticity = (state = null, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      saveState('authenticity', action.authenticity);
      return action.authenticity;

    case 'SIGN_OUT':
      saveState('authenticity', null);
      return null;
    
    default:
      return state;
  }
};

const auth = combineReducers({
  authenticity
});

export default auth;

export const getAuthenticity = state => loadState('authenticity');
export const isLoggedIn = state => {
  const authenticity = loadState('authenticity');
  return authenticity && authenticity.expiration && authenticity.expiration > new Date().getTime();
}
export const isAdmin = state => loadState('authenticity').roleCode === 'root';