import Api from './const';
import fetch from './commonFetch'

const { 
    add, 
    getAll, 
    get, 
    getInvoicesByCustomerId, 
    advanceSearch, 
    deleteByIds, 
    update, 
    getInvoicesForCreateMaterialOrder, 
    getInvoicesByCustomerDocumentId,
    getInvoicesByReceiptId } = Api.invoices;


export const getInvoices = () => {
    const {url, method, headers} = getAll;

    return fetch(url(), {method, headers}, "invoices")
}

export const getInvoice = invoiceId => {
    const { url, method, headers } = get;

    return fetch(url(invoiceId), { method, headers }, "invoices")
}

export const addInvoice = invoices => {
    const { url, method, headers } = add;
    const body = JSON.stringify(invoices);

    return fetch(url(), { method, headers, body }, "invoiceResponse")
}

export const invoicesAdvanceSearch = query => {
    const {url, method, headers} = advanceSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'invoices')
}

export const getInvoicesByStudentId = studentId => {
    const {url, method, headers} = getInvoicesByCustomerId;
    return fetch(url(studentId), {method, headers}, 'invoices');
}

export const getInvoicesByCustomerDocId = studentDocId => {
    const {url, method, headers} = getInvoicesByCustomerDocumentId;
    return fetch(url(studentDocId), {method, headers}, 'invoices');
}

export const deleteInvoicesById = (invoiceIds, userId) => {
    const {url, method, headers} = deleteByIds;
    const request = {invoiceIdsToDelete: invoiceIds, deletedBy: userId};
    const body = JSON.stringify(request);
    return fetch(url(), {method, headers, body}, 'deletedInvoiceIds')
}

export const updateInvoice = (invoice, invoiceid) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(invoice);

    return fetch(url(invoiceid), { method, headers, body }, 'invoiceResponse');
}

export const getInvoicesForCreateOrder = (branchId) => {
    const {url, method, headers} = getInvoicesForCreateMaterialOrder;
    return fetch(url(branchId), {method, headers}, 'data')
}

export const getInvoicesForReceiptId = receiptId => {
    const { url, method, headers } = getInvoicesByReceiptId;
    return fetch(url(receiptId), { method, headers }, 'invoices');
}