import * as fromApi from '../api/bankDeposits';

export const FETCH_BANK_DEPOSITS = 'FETCH_BANK_DEPOSITS';
export const FETCH_BANK_DEPOSIT = 'FETCH_BANK_DEPOSIT';
export const COMPLETE_BANK_DEPOSIT = 'COMPLETE_BANK_DEPOSIT';
export const RECONCILE_BANK_DEPOSIT = 'RECONCILE_BANK_DEPOSIT';
export const DELETE_BANK_DEPOSIT = 'DELETE_BANK_DEPOSIT';
export const FETCH_DEPOSIT_STUDENTS = 'FETCH_DEPOSIT_STUDENTS';
export const FETCH_DEPOSIT_RECEIPTS = 'FETCH_DEPOSIT_RECEIPTS';

export const fetchBankDeposits = query => dispatch => {
    return fromApi.bankDepositAdvanceSearch(query).then(response => {
	  dispatch({ type: FETCH_BANK_DEPOSITS, bankDeposits: response.bankDeposits || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchBankDeposit = depositDocId => dispatch => {
    return fromApi.getBankDeposit(depositDocId).then(response => {
		const { bankDepositData, error } = response;
		
		if (error) return { error }
		
		dispatch({ type: FETCH_BANK_DEPOSIT, bankDeposit: bankDepositData.bankDeposit || [] });
		dispatch({ type: FETCH_DEPOSIT_RECEIPTS, receipts: bankDepositData.receipts || [] });
		dispatch({ type: FETCH_DEPOSIT_STUDENTS, students: bankDepositData.students || [] });
		return response;
    }).catch(error => {
	  	return { error };
    });
}

export const addBankDeposit = bankDeposit => dispatch => {
	return fromApi.addBankDeposit(bankDeposit).then(response => {
	  if(response.error) return { error: response.error };
  
	  dispatch({ type: FETCH_BANK_DEPOSIT, bankDeposit: response.bankDeposit || [] });
	  return response;
	}).catch(error => {
	  return { error };
	});
  }

  export const updateBankDeposit = (depositId, bankDeposit) => dispatch => {
	return fromApi.updateBankDeposit(depositId, bankDeposit).then(response => {
	  if(response.error) return { error: response.error };
  
	  dispatch({ type: FETCH_BANK_DEPOSIT, bankDeposit: response.bankDeposit || [] });
	  return response;
	}).catch(error => {
	  return { error };
	});
  }

export const completeBankDeposit = (depositDocId, request) => dispatch => {
    return fromApi.completeBankDeposit(depositDocId, request).then(response => {
		if(response.error) return { error: response.error };

	  dispatch({ type: COMPLETE_BANK_DEPOSIT, completeResponse: response.completeResponse || [] });
	  return response;
	}).catch(error => {
	  return { error };
	});
}

export const reconcileBankDeposit = (depositDocId, request) => dispatch => {
    return fromApi.reconcileBankDeposit(depositDocId, request).then(response => {
		if(response.error) return { error: response.error };

	  dispatch({ type: RECONCILE_BANK_DEPOSIT, reconcileResponse: response.reconcileResponse || [] });
	  return response;
    }).catch(error => {
	  return { error };
	});
}

export const deleteBankDeposit = (depositDocId, deleteRequest) => dispatch => {
    return fromApi.deleteBankDeposit(depositDocId, deleteRequest).then(response => {
		if(response.error) return { error: response.error };
		
	  dispatch({ type: DELETE_BANK_DEPOSIT, deleteResponse: response.deleteResponse || [] });
	  return response;
    }).catch(error => {
	  return { error };
	});
}