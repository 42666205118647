import * as fromApi from '../api/faculties';

export const FETCH_FACULTIES = 'FETCH_FACULTIES';
export const FETCH_FACULTY = 'FETCH_FACULTY';
export const DELETE_FACULTY = 'DELETE_FACULTY';

export const fetchFaculties = () => dispatch => {
  return fromApi.getFaculties().then(response => {
    dispatch({ type: FETCH_FACULTIES, faculties: response.faculties || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const addFaculty = faculty => dispatch => {
  return fromApi.addFaculty(faculty).then(response => {
    const {error, faculty} = response;
    if (error) return {error};

    dispatch({type: FETCH_FACULTY, faculty: faculty || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateFaculty = (docId, faculty) => dispatch => {
  return fromApi.updateFaculty(docId, faculty).then(response => {
    const { error, faculty } = response;
    if(error) return {error};

    dispatch({ type: FETCH_FACULTY, faculty: faculty || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteFaculty = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteFaculty(docId, deleteRequest).then(response => {
    const {error, faculty} = response;
    if (error) return {error};
    dispatch({type: DELETE_FACULTY, faculty: faculty});
    return response;
  }).catch(error => {
    return {error};
  });
}