import { combineReducers } from 'redux';
import { FETCH_DEPARTMENTS, FETCH_DEPARTMENT, DELETE_DEPARTMENT } from '../actions/departments'

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS:
      const newState = {};
      action.departments.forEach(department => newState[department._id] = department);
      return newState;
    case FETCH_DEPARTMENT: {
      const { department } = action;
      return { ...state, [department._id]: department };
    }
    case DELETE_DEPARTMENT:
      const { department } = action;
      delete state[department._id]
      return state
    default:
      return state;
  }
}

export const byFacultyId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS:
      const newState = {};
      action.departments.forEach(department => {
        let array = newState[department.facultyid] || [];
        newState[department.facultyid] = [...array, department._id]
      });
      return newState;
    case DELETE_DEPARTMENT:
      const faculties = { ...state };
      faculties[action.department.facultyid] = faculties[action.department.facultyid].filter(departmentId => departmentId !== action.department._id);
      return faculties;
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS:
      return action.departments.map(department => department._id);
    case FETCH_DEPARTMENT:
      if (state.includes(action.department._id)) {
        return state
      } else{
        return [ ...state, action.department._id ];
      }
    case DELETE_DEPARTMENT:
      const { department } = action
      return state.filter(stateDepartment => stateDepartment !== department._id);
    default:
      return state;
  }
}

const department = combineReducers({
  byId,
  byFacultyId,
  ids
});

export default department;

export const getDepartments = state => state.ids.map(id => state.byId[id]);

export const getDepartmentByDocId = (state, docId) => state.byId[docId];

export const getDepartmentByFacultyId = (state, facultyId) => {
  return (state.byFacultyId[facultyId] || []).map( id => state.byId[id]);
};