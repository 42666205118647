import Api from './const';
import fetch from './commonFetch'


const { getAll, add, update, remove } = Api.branches;

export const getBranches = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'branches');
}

export const addBranch = branch => {
    const { url, method, headers } = add;
    const body = JSON.stringify(branch);

    return fetch(url(), { method, headers, body }, "branch")
}

export const updateBranch = (docId, branch) => {
    const { url, method, headers } = update;
    const body = JSON.stringify(branch);

    return fetch(url(docId), { method, headers, body }, "branch")
}

export const deleteBranch = (docId, deleteRequest) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(deleteRequest);
    return fetch(url(docId), {method, headers, body}, 'branch')
}