import Api from './const';
import fetch from './commonFetch'

const { getAll, get, add, update, updatePassword } = Api.users;

export const getUsers = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, "users")
}

export const getUser = userId => {
    const { url, method, headers } = get;
    return fetch(url(userId), { method, headers }, "user");
}

export const addUser = user => {
    const { url, method, headers } = add;
    const body = JSON.stringify(user);
    return fetch(url(), { method, headers, body }, "user")
}

export const updateUser = user => {
    const { url, method, headers } = update;
    const body = JSON.stringify(user);
    return fetch(url(user._id), { method, headers, body }, "user")
}

export const changePassword = (userId, changePasswordRequest) => {
    const { url, method, headers } = updatePassword;
    const body = JSON.stringify(changePasswordRequest);

    return fetch(url(userId), { method, headers, body })
}
