import Api from './const';
import fetch from './commonFetch'

const { getAll, add, update, remove } = Api.faculties;

export const getFaculties = () => {
  const { url, method, headers } = getAll;
  return fetch(url(), { method, headers }, "faculties")
}

export const addFaculty = faculty => {
  const { url, method, headers } = add;
  const body = JSON.stringify(faculty);

  return fetch(url(), { method, headers, body }, "faculty")
}

export const updateFaculty = (docId, faculty) => {
  const { url, method, headers } = update;
  const body = JSON.stringify(faculty);

  return fetch(url(docId), { method, headers, body }, "faculty")
}

export const deleteFaculty = (docId, deleteRequest) => {
  const { url, method, headers } = remove;
  const body = JSON.stringify(deleteRequest);
  return fetch(url(docId), {method, headers, body}, 'faculty')
}