import { combineReducers } from 'redux';
import { FETCH_BANKS } from '../actions/banks'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_BANKS:
		const newState = {};
		action.banks.forEach(bank => newState[bank._id] = bank);
		return newState;
	  default:
	      return state;
    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BANKS:
		return action.banks.map(bank => bank._id);
	  default:
		return state;
    }
}

const bankAccounts = combineReducers({
    byId,
    ids
});

export default bankAccounts;

export const getBanks = state => state.ids.map(id => state.byId[id]);

export const getBanksByBranchId = (state, branchid) => {
	const accounts = state.ids.map(id => state.byId[id]);
	return accounts && accounts.length > 0 && accounts.filter(account => account.branchid === branchid)
}