import * as fromApi from '../api/bonusItems';

export const FETCH_BONUS_ITEMS = 'FETCH_BONUS_ITEMS';

export const fetchBonusItems = branchId => dispatch => {
    return fromApi.getBonusItems(branchId).then(response => {
      if (response.error) return { error: response.error }
      
      dispatch({ type: FETCH_BONUS_ITEMS, bonusItems: response.bonusItems || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}