import { combineReducers } from 'redux';
import { FETCH_TRANSACTION_SUMMERY, FETCH_TRANSACTION_USER_SUMMERY } from '../actions/dashboard';

export const transactionSummery = (state = [], action) => {
    switch (action.type) {
	  case FETCH_TRANSACTION_SUMMERY:
	      return [action.transactionSummeryData];
	  default:
	      return state;
    }
}

export const transactionUserSummeryById = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_TRANSACTION_USER_SUMMERY:
			const newState = {};
			action.transactionUserSummeryData.forEach(data => newState[data.id] = data);
			return newState;
	  default:
	      return state;
    }
}

export const transactionUserSummeryIds = (state = [], action) => {
    switch (action.type) {
	  case FETCH_TRANSACTION_USER_SUMMERY:
		return action.transactionUserSummeryData.map(data => data.id);
	  default:
	    return state;
    }
}

const dashboard = combineReducers({
	transactionSummery,
	transactionUserSummeryById,
	transactionUserSummeryIds
});

export default dashboard;

export const getTransactionSummery = state => {
	const { transactionSummery = [] } = state;
	return transactionSummery;
}

export const getTransactionUserSummery = state => {
	const { transactionUserSummeryById, transactionUserSummeryIds } = state;

	return transactionUserSummeryIds.map(id => transactionUserSummeryById[id]).filter(summery => !!summery);
}

export const getTransactionUserSummeryByUserId = (state, userId) => {
	const { transactionUserSummeryById } = state;

	return userId && [transactionUserSummeryById[userId]].filter(summery => !!summery);
}

