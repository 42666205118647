import { combineReducers } from 'redux';
import { FETCH_PUBLISH_COURSES, FETCH_COURSES, FETCH_COURSE, DELETE_COURSE } from '../actions/courses';

export const publishCoursesByBranch = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_PUBLISH_COURSES:
		return { ...state, [action.branchId]: [...action.publishCourses]}
	  default:
		return state;
    }
}

export const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_COURSES:
			const newState = {};
			action.courses.forEach(course => newState[course._id] = course);
			return newState;
		case FETCH_COURSE: {
			const { course } = action;
			return { ...state, [course._id]: course };
		}
		case DELETE_COURSE:
			const { course } = action;
			delete state[course._id]
			return state
		default:
			return state;
	}
}

export const ids = (state = [], action) => {
	switch (action.type) {
		case FETCH_COURSES:
			return action.courses.map(course => course._id);
		case FETCH_COURSE:
			if (state.includes(action.course._id)) {
				return state
			} else{
				return [ ...state, action.course._id ];
			}
		case DELETE_COURSE:
			const { course } = action
			return state.filter(stateCourse => stateCourse !== course._id);
		default:
			return state;
	}
}

const courses = combineReducers({
	byId,
	ids,
	publishCoursesByBranch
});

export default courses;

export const getPublishCoursesByBranch = (state, branchId) => {
    const { publishCoursesByBranch } = state;

    return publishCoursesByBranch[branchId];
}

export const getCourseByDocId = (state, docId) => state.byId[docId];

export const getCourses = state => {
	const courses = state.ids.map(id => state.byId[id]);
	const sortedCourses = courses.sort(function(a, b){
		if(a.coursename < b.coursename) { return -1; }
		if(a.coursename > b.coursename) { return 1; }
		return 0;
	})
	return sortedCourses;
};