import * as fromApi from '../api/roles';

export const ADD_ROLE = 'ADD_ROLE';
export const FETCH_ROLES = 'FETCH_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

export const addRole = role => dispatch => {
    return fromApi.addRole(role).then(response => {
	  dispatch({ type: ADD_ROLE, role: response.role || [] });
	  return response;
    }).catch(error => {
	  return error;
    })
}

export const fetchRoles = () => dispatch => {
    return fromApi.fetchRoles().then(response => {
	  dispatch({type: FETCH_ROLES, roles: response.roles || []});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const deleteRole = docId => dispatch => {
    return fromApi.deleteRole(docId).then(response => {
	  dispatch({type: DELETE_ROLE, role: response.role || []});
	  return response;
    }).catch(error => {
	  return {error};
    });
}

export const updateRole = (docId, role) => dispatch => {
    return fromApi.updateRole(docId, role).then(response => {
	  dispatch({ type: UPDATE_ROLE, role: response.role || [] });
	  return response;
    }).catch(error => {
	  return error;
    })
}