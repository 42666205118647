const options = [
  {
    key: 'githubSearch',
    label: 'sidebar.githubSearch',
    leftIcon: 'ion-social-github'
  },
  {
    key: 'blank_page',
    label: 'sidebar.blankPage',
    leftIcon: 'ion-document'
  }
];
export default options;
