import { combineReducers } from 'redux';
import {ADD_ACTION, FETCH_ACTIONS, UPDATE_ACTION, DELETE_ACTION} from '../actions/actions'

export const byId = (state = {}, actionRes) => {
    const { action } = actionRes;
    switch (actionRes.type) {
	  case ADD_ACTION:
		return { ...state, [action._id]: action };
	  case UPDATE_ACTION:
		return { ...state, [action._id]: action };
	  case FETCH_ACTIONS:
		const newState = {};
		actionRes.actions.forEach(action => newState[action._id] = action);
		return newState;
	  case DELETE_ACTION:
	      delete state[action._id]
		return state
	  default:
		return state;

    }
}

export const ids = (state = [], actionRes) => {
    switch (actionRes.type) {
	  case FETCH_ACTIONS:
		return actionRes.actions.map(action => action._id);
	  case DELETE_ACTION:
		return state.filter(action => action != actionRes.action._id);
	  default:
		return state;
    }
}

const actions = combineReducers({
    byId,
    ids
});

export default actions;

export const getActions = state => {
    const actionList = state.ids.map(id => state.byId[id])
    return actionList;
}

export const getActionByDocId = (state, actionDocId) => state.byId[actionDocId];
