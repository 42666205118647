import { combineReducers } from 'redux';
import { FETCH_CONFIG_AMOUNTS } from '../actions/configPaymentAmounts'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_CONFIG_AMOUNTS:
		const newState = {};
		action.configAmounts.forEach(configAmountData => newState[configAmountData._id] = configAmountData);
		return newState;
	  default:
	      return state;

    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_CONFIG_AMOUNTS:
		return action.configAmounts.map(configAmountData => configAmountData._id);
	  default:
		return state;
    }
}

const configAmounts = combineReducers({
    byId,
    ids
});

export default configAmounts;

export const getConfigAmounts = state => state.ids.map(id => state.byId[id]);