import * as fromApi from '../api/testimonials';

export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';
export const FETCH_TESTIMONIAL = 'FETCH_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';

export const fetchTestimonials = () => dispatch => {
    return fromApi.getTestimonials().then(response => {
      dispatch({ type: FETCH_TESTIMONIALS, testimonials: response.testimonials || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const fetchTestimonial = (id) => dispatch => {
  return fromApi.getTestimonial(id).then(response => {
    dispatch({ type: FETCH_TESTIMONIAL, testimonial: response.testimonial || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const addTestimonial = testimonial => dispatch => {
  return fromApi.addTestimonial(testimonial).then(response => {
    const {error, testimonial} = response;
    if (error) return {error};

    dispatch({type: FETCH_TESTIMONIAL, testimonial: testimonial || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateTestimonial = (docId, testimonial) => dispatch => {
  return fromApi.updateTestimonial(docId, testimonial).then(response => {
    const { error, testimonial } = response;
    if(error) return {error};

    dispatch({ type: FETCH_TESTIMONIAL, testimonial: testimonial || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteTestimonial = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteTestimonial(docId, deleteRequest).then(response => {
    const {error, testimonial} = response;
    if (error) return {error};
    dispatch({type: DELETE_TESTIMONIAL, testimonial: testimonial || []});
    return response;
  }).catch(error => {
    return {error};
  });
}

export const activateTestimonial = (id) => dispatch => {
  return fromApi.activateTestimonial(id).then(response => {
    dispatch({ type: FETCH_TESTIMONIAL, testimonial: response.testimonial || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}

export const deactivateTestimonial = (id) => dispatch => {
  return fromApi.deactivateTestimonial(id).then(response => {
    dispatch({ type: FETCH_TESTIMONIAL, testimonial: response.testimonial || [] });
  return response;
  }).catch(error => {
  return { error };
  });
}