import getDevSidebar from '../../customApp/sidebar';
import { Permission } from '../../helpers/Permission'

const options = [
  {
    key: 'dashboard',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-arrow-graph-up-right',
    action: Permission.NAVIGATION.DASHBOARD.DASHBOARD,
    children: [
      {
        key: 'userDashboard',
        label: 'sidebar.userDashboard',
        action: Permission.NAVIGATION.DASHBOARD.USERDASHBOARD,
      }
    ],
  },
  {
    key: 'configurations',
    label: 'sidebar.configurations',
    leftIcon: 'ion-ios-cog',
    action: Permission.NAVIGATION.CONFIGURATION.CONFIGURATION,
    children: [
      {
        key: 'institute',
        label: 'sidebar.institute',
        action: Permission.NAVIGATION.CONFIGURATION.COMPANY,
      },
      {
        key: 'faculty',
        label: 'sidebar.faculty',
        action: Permission.NAVIGATION.CONFIGURATION.FACULTY,
      },
      {
        key: 'courseLevelList',
        label: 'sidebar.courseLevelList',
        action: Permission.NAVIGATION.CONFIGURATION.COURSE_LEVELS,
      },
      {
        key: 'courses',
        label: 'sidebar.courseList',
        action: Permission.NAVIGATION.CONFIGURATION.COURSES,
      },{
        key: 'systemParameters',
        label: 'sidebar.systemParameters',
        action: Permission.NAVIGATION.CONFIGURATION.SYSTEM_PARAMETERES,
      },
      {
        key: 'batches',
        label: 'sidebar.batches',
        action: Permission.NAVIGATION.CONFIGURATION.BATCHES,
      },
      {
        key: 'payableItemList',
        label: 'sidebar.payableItemList',
        action: Permission.NAVIGATION.CONFIGURATION.PAYABLE_ITEM_LIST,
      },
      {
        key: 'publishCourses',
        label: 'sidebar.publishCourses',
        action: Permission.NAVIGATION.CONFIGURATION.PUBLISH_COURSES,
      }
    ],
  },
  {
    key: 'students',
    label: 'sidebar.students',
    leftIcon: 'ion-ios-people',
    action: Permission.NAVIGATION.STUDENTS.STUDENT_REGISTRATION,
  },
  {
    key: 'administration',
    label: 'sidebar.administration',
    leftIcon: 'ion-key',
    action: Permission.NAVIGATION.ADMINISTRATION.ADMINISTRATION,
    children: [
      {
        key: 'users',
        label: 'sidebar.users',
        action: Permission.NAVIGATION.ADMINISTRATION.USERS,
      },{
        key: 'actions',
        label: 'sidebar.actions',
        action: Permission.NAVIGATION.ADMINISTRATION.ACTIONS,
      },{
        key: 'roles',
        label: 'sidebar.roles',
        action: Permission.NAVIGATION.ADMINISTRATION.ROLES,
      }],
  },
  {
    key: 'userProfile',
    label: 'sidebar.userProfile',
    leftIcon: 'ion-man',
    action: Permission.NAVIGATION.USER_PROFILE.USER_PROFILE,
    children: [
      {
        key: 'resetPassword',
        label: 'sidebar.resetPassword',
        action: Permission.NAVIGATION.USER_PROFILE.RESET_PASSWORD,
      }],
  },
  {
    key: 'payments',
    label: 'sidebar.payments',
    leftIcon: 'ion-clipboard',
    action: Permission.NAVIGATION.PAYMENTS.PAYMENTS,
    children: [
      {
        key: 'invoices',
        label: 'sidebar.invoice',
        action: Permission.NAVIGATION.PAYMENTS.INVOICES,
      },{
        key: 'receiptSummary',
        label: 'sidebar.receiptSummary',
        action: Permission.NAVIGATION.PAYMENTS.RECEIPTS,
      },
      {
        key: 'waveOffSummary',
        label: 'sidebar.waveOffList',
        action: Permission.NAVIGATION.PAYMENTS.WAVEOFF,
      },
      {
        key: 'bankDepositSummary',
        label: 'sidebar.bankDepositSummary',
        action: Permission.NAVIGATION.PAYMENTS.BANK_DEPOSITS,
      }
    ],
  },
  {
    key: 'studentMaterials',
    label: 'sidebar.studentMaterials',
    leftIcon: 'ion-cube',
    action: Permission.NAVIGATION.COURSE_MATERIALS.COURSE_MATERIALS,
    children: [
      {
        key: 'studentMaterialOrderList',
        label: 'sidebar.studentMaterialOrder',
        action: Permission.NAVIGATION.COURSE_MATERIALS.MATERIAL_ORDER,
      },
      {
        key: 'studentMaterialOrderAssignList',
        label: 'sidebar.studentMaterialOrderAssignList',
        action: Permission.NAVIGATION.COURSE_MATERIALS.MATERIAL_ASSIGN,
      }
    ],
  },
  {
    key: 'partners',
    label: 'sidebar.partners',
    leftIcon: 'ion-ios-people',
    action: Permission.NAVIGATION.PARTNERS.PARTNERS
  },
  {
    key: 'testimonials',
    label: 'sidebar.testimonials',
    leftIcon: 'ion-quote',
    action: Permission.NAVIGATION.TESTIMONIALS.TESTIMONIALS
  },
  {
    key: 'achievements',
    label: 'sidebar.achievements',
    leftIcon: 'ion-trophy',
    action: Permission.NAVIGATION.ACHIEVEMENTS.ACHIEVEMENTS
  },
  {
    key: 'announcements',
    label: 'sidebar.announcements',
    leftIcon: 'ion-ios-calendar',
    action: Permission.NAVIGATION.ANNOUNCEMENTS.ANNOUNCEMENTS
  },
  {
    key: 'inquiries',
    label: 'sidebar.inquiries',
    leftIcon: 'ion-ios-chatboxes',
    action: Permission.NAVIGATION.INQUIRIES.INQUIRIES
  }
];
export default options;
