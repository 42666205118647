import { combineReducers } from 'redux';
import { FETCH_FACULTIES, FETCH_FACULTY, DELETE_FACULTY } from '../actions/faculties';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FACULTIES:
      const newState = {};
      action.faculties.forEach(faculty => newState[faculty._id] = faculty);
      return newState;
    case FETCH_FACULTY: {
      const { faculty } = action;
      return { ...state, [faculty._id]: faculty };
    }
    case DELETE_FACULTY:
      const { faculty } = action;
      delete state[faculty._id]
      return {...state}
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_FACULTIES:
      return action.faculties.map(faculty => faculty._id);
    case FETCH_FACULTY:
      if (state.includes(action.faculty._id)) {
        return state
      } else{
        return [ ...state, action.faculty._id ];
      }
    case DELETE_FACULTY:
      const { faculty } = action
      return state.filter(stateFacultyId => stateFacultyId !== faculty._id);
    default:
      return state;
  }
}

const faculties = combineReducers({
  byId,
  ids
});

export const getFaculties = state => state.ids.map(id => state.byId[id]);

export const getFacultyByDocumentId = (state, docId) => {
  const faculty = state.byId[docId];
  return faculty || {};
}

export default faculties;