import Api from './const';
import fetch from './commonFetch'

const { advanceSearch, remove, getByOrderId, getToBeOrderMaterialItemList, add, update, sendOrder, deliverOrder, completeOrder, materialAssignListSearch, materialAssignUpdateStatus, materialAssignConfirmStatus, } = Api.materialOrder

export const ordersAdvanceSearch = query => {
    const {url, method, headers} = advanceSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'materialOrders');
}

export const deleteMaterialOrder = (docId, request) => {
    const { url, method, headers } = remove;
    const body = JSON.stringify(request);
    return fetch(url(docId), {method, headers, body}, 'materialOrder')
}

export const getOrderByOrderId = orderId => {
    const {url, method, headers} = getByOrderId;

    return fetch(url(orderId), {method, headers}, "materialOrderResponse")
}

export const getToBeOrderMaterialOrders = request => {
    const {url, method, headers} = getToBeOrderMaterialItemList;
    const body = JSON.stringify(request)

    return fetch(url(), {method, headers, body}, "materialOrdersItems")
}

export const addMaterialOrder = orderData => {
    const {url, method, headers} = add;
    const body = JSON.stringify(orderData);

    return fetch(url(), {method, headers, body}, "materialOrderResponse")
}

export const updateMaterialOrder = (docId, orderData) => {
    const {url, method, headers} = update;
    const body = JSON.stringify(orderData);

    return fetch(url(docId), {method, headers, body}, "materialOrderResponse")
}

export const sendMaterialOrder = (docId, orderData) => {
    const {url, method, headers} = sendOrder;
    const body = JSON.stringify(orderData);

    return fetch(url(docId), {method, headers, body}, "materialOrderResponse")
}

export const deliverMaterialOrder = (docId, orderData) => {
    const {url, method, headers} = deliverOrder;
    const body = JSON.stringify(orderData);

    return fetch(url(docId), {method, headers, body}, "materialOrderResponse")
}

export const completeMaterialOrder = (docId, orderData) => {
    const {url, method, headers} = completeOrder;
    const body = JSON.stringify(orderData);

    return fetch(url(docId), {method, headers, body}, "materialOrderResponse")
}

export const orderAssignListSearch = query => {
    const {url, method, headers} = materialAssignListSearch;
    const body = JSON.stringify(query);
    return fetch(url(), {method, headers, body}, 'materialOrders');
}

export const assignOrderItems = data => {
    const {url, method, headers} = materialAssignUpdateStatus;
    const body = JSON.stringify(data);
    return fetch(url(), {method, headers, body}, 'materialOrders');
}

export const confirmOrderAssignItemsStatus = data => {
    const {url, method, headers} = materialAssignConfirmStatus;
    const body = JSON.stringify(data);
    return fetch(url(), {method, headers, body}, 'materialOrders');
}

