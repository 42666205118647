import { combineReducers } from 'redux';
import {FETCH_BATCHES, FETCH_BATCHES_BY_QUERY, FETCH_BATCH, FETCH_DETAIL_BATCH, DELETE_BATCHES_BY_BATCH_ID} from "../actions/batches";

export const byId = (state = {}, action) => {
	  let newState = {};
    switch (action.type) {
	  case FETCH_BATCHES:
		action.batches.forEach(batch => newState[batch._id] = batch);
		return newState;
	  case FETCH_BATCHES_BY_QUERY:
		  newState = { ...state }
		  action.result.batches.forEach(batch => newState[batch._id] = batch)
		  return newState;
	  case FETCH_BATCH: {
			const { batch } = action;
			return { ...state, [batch._id]: batch };
		  }
	  default:
		return state;
    }
};

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_BATCHES:
		return action.batches.map(batch => batch._id);
	  case FETCH_BATCH:
		if (state.includes(action.batch._id)) {
			return state
		} else{
			return [ ...state, action.batch._id ];
		}
	  case DELETE_BATCHES_BY_BATCH_ID:
		const {deletedBatchIds} = action.deletedBatchesResponse;
		const currentBatchIds = state;
		const filteredBatchIds = currentBatchIds.filter(id => !deletedBatchIds.includes(id));
		return filteredBatchIds;
	  default:
		return state;
    }
}

export const byKeys = (state = {}, action) => {
	let newState = {};
  switch (action.type) {
	case FETCH_BATCHES_BY_QUERY:
		newState = { ...state }
		const key = action.result.key
		const ids = newState[key] || []
		const newIds = []
		action.result.batches.forEach(batch => {
			if (!ids.includes(batch._id)) newIds.push(batch._id)
		})
		newState[key] = ids.concat(newIds)
		return newState;
	case DELETE_BATCHES_BY_BATCH_ID:
		const {deletedBatchIds} = action.deletedBatchesResponse;
		const keys = Object.keys(state);
		const updatedState = {}
		keys.forEach(key => {
			const idList = state[key]
			const filteredIdList = idList.filter(id => !deletedBatchIds.includes(id));
			updatedState[key] = filteredIdList
		})
		return updatedState;
	default:
	  return state;
  }
};

export const detailBatchData = (state = {}, action) => {
	let newState = {};
  switch (action.type) {
	case FETCH_DETAIL_BATCH:
	  const batch = action.detailBatchData.batch
	  if (batch && batch._id) newState[batch._id] = action.detailBatchData
	  return newState;
	case DELETE_BATCHES_BY_BATCH_ID:
		const {deletedBatchIds} = action.deletedBatchesResponse;
		deletedBatchIds.forEach(id => delete state[id]);
		return state;
	default:
	  return state;
  }
};

const batches = combineReducers({
    byId,
	ids,
	byKeys,
	detailBatchData
});

export default batches;

export const getBatches = state =>
	state.ids.map(id => state.byId[id]);

export const getBatchesByQuery = (state, key) => {
	return state.byKeys[key] ? state.byKeys[key].map(id => state.byId[id]) : []
}

export const getBatch = (state, id) => {
	return state.byId[id];
}

export const getDetailBatch = (state, id) => {
	return state.detailBatchData[id];
}

export const getBatchesByBranch = (state, branchId) =>
	state.ids.map(id => state.byId[id]).filter(batch => batch.branchid === branchId);
	
