import * as fromApi from '../api/companies';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

export const fetchCompanies = () => dispatch => {
    return fromApi.getCompanies().then(response => {
      dispatch({ type: FETCH_COMPANIES, companies: response.companies || [] });
	  return response;
    }).catch(error => {
	  return { error };
    });
}

export const addCompany = company => dispatch => {
  return fromApi.addCompany(company).then(response => {
    const {error, company} = response;
    if (error) return {error};

    dispatch({type: FETCH_COMPANY, company: company || []});
    return response;
  }).catch(error => {
    return {error};
  })
}

export const updateCompany = (docId, company) => dispatch => {
  return fromApi.updateCompany(docId, company).then(response => {
    const { error, company } = response;
    if(error) return {error};

    dispatch({ type: FETCH_COMPANY, company: company || [] });
    return response;
  }).catch(error => {
    return { error };
  });
}

export const deleteCompany = (docId, deleteRequest) => dispatch => {
  return fromApi.deleteCompany(docId, deleteRequest).then(response => {
    const {error, company} = response;
    if (error) return {error};
    dispatch({type: DELETE_COMPANY, company: company || []});
    return response;
  }).catch(error => {
    return {error};
  });
}