import { combineReducers } from 'redux';
import { FETCH_USERS, FETCH_USER, ADD_USER, UPDATE_USER } from '../actions/users'

export const byId = (state = {}, action) => {
    switch (action.type) {
	  case FETCH_USERS:
		const newState = {};
		action.users.forEach(user => newState[user._id] = user);
		return newState;
	  case FETCH_USER: {
		const { user } = action;
		return { ...state, [user._id]: user };
	  }
	  case UPDATE_USER:{
	      const { user } = action;
		return { ...state, [user._id]: user };
	  }
	  default:
	      return state;

    }
}

export const ids = (state = [], action) => {
    switch (action.type) {
	  case FETCH_USERS:
		return action.users.map(user => user._id);
	  case FETCH_USER:
		return [ ...state, action.user._id ];
	  default:
		return state;
    }
}

const users = combineReducers({
    byId,
    ids
});

export default users;

export const getUsers = state => state.ids.map(id => state.byId[id]);

export const getUserById = (state, userid) => state.byId[userid];

export const isAdmin = (state, userid) => {
	const user = state.byId[userid];

	if(user) return user.profile && user.profile.roles && user.profile.roles.length > 0 && user.profile.roles.includes("root");

	return true;
}