import Api from './const';
import fetch from './commonFetch'

const { add, getAll, remove, update } = Api.roles;

export const addRole = role => {
    const { url, method, headers } = add;
    const body = JSON.stringify(role);
    return fetch(url(), {method, headers, body}, 'role')
}

export const fetchRoles = () => {
    const { url, method, headers } = getAll;
    return fetch(url(), { method, headers }, 'roles')
}

export const deleteRole = docId => {
    const { url, method, headers } = remove;
    return fetch(url(docId), {method, headers}, 'role')
}

export const updateRole = (docId, role) => {
    const { url, method, headers} = update;
    const body = JSON.stringify(role);
    return fetch(url(docId), {method, headers, body}, 'role')
}