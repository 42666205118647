import { combineReducers } from 'redux';
import {FETCH_COURSE_TYPES} from '../actions/courseTypes';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COURSE_TYPES:
      const newState = {};
      action.courseTypes.forEach(courseType => newState[courseType._id] = courseType);
      return newState;
    default:
      return state;
  }
}

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_COURSE_TYPES:
      return action.courseTypes.map(courseType => courseType._id);
    default:
      return state;
  }
}

const courseTypes = combineReducers({
  byId,
  ids
});

export default courseTypes;

export const getcourseTypes = state => state.ids.map(id => state.byId[id]);